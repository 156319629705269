import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import emailjs from 'emailjs-com';
import { NavLink, Link } from 'react-router-dom';
import moment from 'moment';
import { Grid, Card, Icon, Image, Divider, Input, Table, Header, Placeholder, Button, Popup } from 'semantic-ui-react';
import RenderLives from '../table_helpers/RenderLives';
import AuthModal from '../AuthModal';
import JoinPoolModal from '../game/JoinPoolModal';
import {requestLeagueAccess, setJoinModalVis} from '../../actions';
import { formatDollars, getNumberOrdinal } from '../../utils';
import '../styles/leagues.scss';

function LeagueHome ({ auth, league, seasonParamsIndex, requestLeagueAccess, joinModalVis, setJoinModalVis }) {
  const [userEmail, setUserEmail] = useState('');
  const [userInLg, setUserInLg] = useState(false);
  const [activePool, setActivePool] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [emailResult, setEmailResult] = useState(null);
  const baseUrl = window.location.href.slice(0, window.location.href.indexOf('/league/'));

  const {
    abb: league_abb,
    admins,
    id: league_id,
    name: league_name,
    invite_code,
    joinable
  } = league.info;
  
  const { members, userSeasons } = league;

  useEffect(() => {
    if (auth && members.map(m => m.user_id).includes(auth[0].id)) setUserInLg(true);
  }, [auth, league.info.id])

  useEffect(() => {
    if (emailResult) {
      setUserEmail('');
    }
  }, [emailResult])

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const emailUser = async () => {
    // maybe also add email counter in email state to prevent spamming?
    if (validateEmail(userEmail)) {
      const templateParams = {
        user_email: userEmail,
        user_full_name: `${auth[0].nameFirst} ${auth[0].nameLast}`,
        league_name,
        league_invite_url: `https://www.alasurvivorseries.com/invite/league/${invite_code}`
      }

      try {
        await emailjs.send('service_gx544jx', 'template_heqnpe7', templateParams, 'user_zrkVTjTi90AAyuQPoQHr0')
        setEmailResult(true);
        setTimeout(() => setEmailResult(null), 4000);
      } catch {
        setEmailResult(false);
        setTimeout(() => setEmailResult(null), 4000);
      }
    } else {
      setEmailError(true);
      setTimeout(() => setEmailError(false), 5000);
    }
  }

  function joinLeagueReq () {
    requestLeagueAccess({
      league_id,
      league_abb,
      league_name,
      joinable,
      user_id: auth[0].id,
      username: auth[0].username,
      full_name: `${auth[0].nameFirst} ${auth[0].nameLast}`,
      email: auth[0].email,
      source: 'home'
    });
  }

  function showJoinModal (activePool) {
    setActivePool(activePool);
    setJoinModalVis(true);
  }

  function renderGames () {
    let lgSeasonIds = league.seasons.map(ls => ls.season_id) || [];
    let games = [];
    seasonParamsIndex
      .filter(sp => moment(league.created_at).isBefore(moment(sp.end)))
      .forEach(s => {
        const isLeagueAdmin = auth && JSON.parse(admins).includes(auth[0].username);

        if (!lgSeasonIds.includes(s.id)) {
          games.push(
            <Table.Row key={s.id} className={s.game}>
              <Table.Cell> {s.game === 'nba' ? <Icon name='basketball ball' className='nba-color'/>: <Icon name='baseball ball' className='mlb-color'/>}{s.game_name} </Table.Cell>
              <Table.Cell> {moment().isBefore(moment(s.start)) ? `Begins ${moment(s.start).format("M/D/YY")}` : `Open for Late Reg` } </Table.Cell>
              {isLeagueAdmin &&
                <Table.Cell colSpan={4} textAlign='center'>
                  <b><u><Link to={`/league/${league_abb}/createPool`} style={{fontSize: 16}}className='purple-text'> ACTIVATE POOL </Link></u></b>
                  <Popup
                    trigger={<Icon name='question circle' style={{marginLeft: 3, marginBottom: 5}} />}
                    content={<span>This pool is available for your league, but first needs to be activated by your league's Commissioner through the <b>Create Pool</b> tab.</span>}>
                  </Popup>
                </Table.Cell>}
                <Table.Cell colSpan={4} textAlign='center'>
                  PENDING COMMISSIONER ACTIVATION
                  <Popup
                    trigger={<Icon name='question circle' style={{marginLeft: 3, marginBottom: 5}} />}
                    content={<span>This pool is available for your league, but first needs to be activated by your league's Commissioner through the <b>Create Pool</b> tab.</span>}>
                  </Popup>
                </Table.Cell>
            </Table.Row>
          )
        }
      });

    league.seasons.forEach(ls => {
      const numPools = league.seasons.filter(lsx => lsx.season_id === ls.season_id).length;
      const userSeason = auth[0] ? auth[0].userSeasons.find(us => us.league_season === ls.id): null;
      function userStatus () {
        if (!userInLg) {
          return <i> n/a </i>
        } else if (!userSeason) {
          if (moment().isAfter(moment(ls.start))) {
            return <i> DNP </i>
          } else {
            return <Button size='tiny' className="lg-btn" onClick={() => showJoinModal(ls)}>SIGN UP!</Button>
          }
        } else {
          if (!ls.final) {
            if (userSeason.lives > 0) {
              return <RenderLives lives={userSeason.lives} />
            } else {
              return 'ELIMINATED';
            }
          } else {
            return <>
            {getNumberOrdinal(userSeason.final_rank)} {userSeason.prizes > 0 ? `(${formatDollars(userSeason.prizes, 0)})` : null}
            </>
          }
        }
      }

      games.push(
        <Table.Row key={`${ls.id}_${ls.pool_idx}`} className={ls.game}>
          <Table.Cell>
            {ls.game === 'nba' ? <Icon name='basketball ball' className='nba-color'/>: <Icon name='baseball ball' className='mlb-color'/>}
            <a className='pool-link' href={`/${ls.game}/${ls.league_abb}/${ls.season}/${ls.season_type}/${ls.pool_idx}`}>{ls.game_name} {numPools > 1 ? `#${ls.pool_idx+1}` : null}</a>
          </Table.Cell>
          <Table.Cell> {ls.final ? 'Completed' : <> {
            moment().isAfter(moment(ls.start))
              ? 'In Progress'
              : <a className={`${ls.game.toLowerCase()}-color`} href={`/${ls.game}/${ls.league_abb}/${ls.season}/${ls.season_type}/${ls.pool_idx}`}><b><u>OPEN FOR ENTRY</u></b></a>
            }</>}</Table.Cell>
          <Table.Cell> {userStatus()}</Table.Cell>
          <Table.Cell> {league.userSeasons.filter(us => (us.season_id === ls.season_id && us.pool_idx === ls.pool_idx)).length}</Table.Cell>
          <Table.Cell> {ls.rebuys + 1}</Table.Cell>
          <Table.Cell> {ls.rebuys ? `${formatDollars(ls.buyin, 0, true)}/${formatDollars(ls.rebuy_price, 0, true)}` : formatDollars(ls.buyin, 0, true)}</Table.Cell>
        </Table.Row>
      )
    })

    return games;
  }

  function renderMembers () {
    return [...members]
      .sort((a, b) => b.prizes - a.prizes)
      .map(m => {
        return (
          <Table.Row key={m.id}>
            <Table.Cell>
              <Header as='h4' image>
                {m.photo_url ?
                  <Image src={m.photo_url} rounded size='mini' />
                : <Icon rounded='true' size='mini' name='user circle' />}
                <Header.Content>
                  {m.username}
                  <Header.Subheader>{m.prizes > 0 ? <i>{formatDollars(m.prizes, 0)} in prizes</i> : null}</Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
          </Table.Row>
        )
      })
  }

  function renderTrophys () {
    if (league.seasons.length && league.seasons[league.seasons.length-1].champions) {
      return league.seasons.filter(ls => ls.final)
        .map(ls => {
          if (ls.champions) {
            const champLinks = JSON.parse(ls.champions).map((c, idx) => {
              return <Link key={c} className={`${ls.game} champ`}> {c} </Link>
            });
            return (
              <Grid.Row key={ls.id}>
                <Grid.Column width={6} className={`pool-trophy ${ls.game}`}>
                  <Link to={`/${ls.game}/${league_abb}/${ls.season}/${ls.season_type}/${ls.pool_idx}`}>
                    <div><Icon name='trophy' /></div>
                    <div className="trophy-text">{ls.game.toUpperCase()} {ls.season}</div>
                  </Link>
                </Grid.Column>
                <Grid.Column width={10} verticalAlign='middle' textAlign='center'> {champLinks} </Grid.Column>
              </Grid.Row>
            )
          }
        })
    } else {
      return (
        <Grid.Row>
          <Grid.Column width={16} verticalAlign='middle'> Your league's trophies will appear here once a pool has concluded.</Grid.Column>
        </Grid.Row>
      )
    }
  }

  return (
    <>
      <Grid style={{marginBottom: 20, marginTop: 5}}>
        <Grid.Row className='page-header'>
          <Grid.Column width={13}>
            <Header as='h2'>
              <div style={{display: 'inline-block'}}>
                <Icon name='home' circular size='large'/>
              </div>
              <Header.Content>
                League Home
                <Header.Subheader>
                  - This is <b>{league.info.name}</b>'s league homepage. <br />
                  - Join this league to be notified when new <b>{league.info.name}</b> pools open up for entry. <br />
                  - League members can use this page to sign up for pools, invite friends to the league, and keep tabs on their competition.
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column style={{width: '23%'}}>
            <Card color={'purple'} className="event-feed-card league-card">
              <Card.Content>
                <Card.Header className="card-header member-header"><Icon name='group'/>League Members</Card.Header>
                <Card.Description className='member-desc'>
                  <Table style={{display: 'inline-block'}}>
                    <Table.Body>
                      {renderMembers()}
                    </Table.Body>
                  </Table>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column style={{width: '52%'}}>
            <Card color={'purple'} className="event-feed-card league-card">
              <Card.Content>
                <div className='league-detail-header'>
                  <div className='league-name-cont'>
                  <Card.Header className="card-header">{league.info.name}</Card.Header>
                  <Card.Meta>
                    <i>{league.info.desc || `Welcome to ${league.info.name}!`}</i>
                  </Card.Meta>
                  </div>
                  <div className='league-logo-cont'>
                    <div className='logo-img-cont'>
                      <Image src={league.info.logo || 'https://alasurvivor.s3-us-west-2.amazonaws.com/surv-logo.png'}/>
                    </div>
                  </div>
                </div>
                <Card.Description>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell className='purple-text-dim'> FOUNDED</Table.Cell>
                        <Table.Cell> {moment(league.info.created_at).format('M.D.YY')}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell className='purple-text'> COMMISH</Table.Cell>
                        <Table.Cell> <b>{league.info.founder}</b></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell className='purple-text'> MEMBERSHIP</Table.Cell>
                        <Table.Cell> {league.info.joinable ?
                          <><b>Public</b>. Anyone can join this league.</> :
                          <><b>Private</b>. Members must be approved to join.</>
                          }
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell className='purple-text'> VISIBILITY</Table.Cell>
                        <Table.Cell> {league.info.hidden ?
                          <><b>Private</b>. This league is hidden from those who are not members and is excluded from global ALA leaderboards.</>:
                          <><b>Public</b>. This league is visible to the outside world and is included in global ALA leaderboards.</>
                          }
                          </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <NavLink to={`/league/${league.info.abb}/members`}>
                  <Icon name='user' />
                  {members.length} {members.length !== 1 ? 'members' : 'member'}
                </NavLink>
              </Card.Content>
            </Card>
            <Card color={'purple'} className="event-feed-card league-card">
              <Card.Content>
                <Card.Header className="card-header"><Icon name='list alternate'/>Pools</Card.Header>
                <Card.Meta>
                </Card.Meta>
                <Card.Description>
                  <Table attached='top'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell> Pool </Table.HeaderCell>
                        <Table.HeaderCell> Status </Table.HeaderCell>
                        <Table.HeaderCell> Your Status </Table.HeaderCell>
                        <Table.HeaderCell> Entries </Table.HeaderCell>
                        <Table.HeaderCell> Lives </Table.HeaderCell>
                        <Table.HeaderCell> Buyin/Rebuy </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {renderGames()}
                    </Table.Body>
                  </Table>
                  {activePool ? <JoinPoolModal visible={joinModalVis} auth={auth} activePool={activePool} league={league}/> : null}
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column style={{width: '25%'}}>
            {!userInLg ?
              <Card color={'purple'} className="event-feed-card">
                <Card.Content>
                  <Card.Header className="card-header"><Icon name="beer" /> Join League? </Card.Header>
                </Card.Content>
                <Card.Content extra style={{textAlign: 'center'}}>
                  {auth[0] ?
                    <>
                      {league.info.joinable ?
                        <> This league is public! <Button className='lg-btn' onClick={() => joinLeagueReq()}>JOIN THIS LEAGUE</Button></>
                      :
                        <> This is a <b>private league</b>.
                          {league.requests.map(member => member.user_id).includes(auth[0].id) ?
                            <>
                              {
                                league.requests.find(member => member.user_id).status === 2 ?
                                <> Your request to join this league is <span className='purple-text'><b>pending Commissioner approval</b></span>.</>
                                :
                                <> Your request to join this league was denied. This action will have to be manually reversed by the League Commissioner in order for you to join. </>
                              }
                            </>
                          :
                            <> To join this league, ask the league Commissioner for an invite, or: <Button className='lg-btn' onClick={() => joinLeagueReq()}>REQUEST TO JOIN</Button> </>
                          }
                        </>
                      }
                    </>
                  :
                    <div> You are not logged in! <AuthModal /> to access or request to join this league. </div>
                  }
                </Card.Content>
              </Card>
            :
            <Card color={'purple'} className="event-feed-card">
              <Card.Content extra className='inverted-card' style={{textAlign: 'center'}}>
                <Icon name='star' /> <b>You are a member of this league.</b>  <Icon name='star' />
              </Card.Content>
            </Card>
            }
            <Card color={'purple'} className="event-feed-card">
              <Card.Content>
                <Card.Header className="card-header"><Icon name="add user" /> Invite Your Friends! </Card.Header>
                <Card.Meta>
                </Card.Meta>
                <Card.Description>
                  Use this link below to invite friends to your league -- or type in their email address, and we'll send the invite out for you.
                </Card.Description>
              </Card.Content>
              { userInLg ?
                <Card.Content extra style={{textAlign: 'center'}}>
                  <div className="space">
                    <b>LEAGUE INVITE URL</b>:
                  </div>
                  <div className='purple-text invite-url'>
                    <b>{`${baseUrl}/invite/league/${league.info.invite_code}`}</b>
                  </div>
                  <Divider horizontal>Or</Divider>
                  <div className="space">
                    <b>ENTER YOUR FRIEND'S EMAIL</b>:
                  </div>
                  <div className='email-button'>
                    <Input
                      style={{width: '100%'}}
                      onChange={e => setUserEmail(e.target.value)}
                      value={userEmail}
                      placeholder='enter email'
                    />
                    {emailResult !== null ?
                      <>
                        {emailResult ?
                          <Button positive disabled>
                            <Icon name='mail square' />
                            INVITATION SENT!
                          </Button>
                        :
                          <Button disabled negative>
                            <Icon name='mail square' />
                            ERROR SENDING INVITE
                          </Button>
                        }
                      </>
                      :
                        <Button className='lg-btn' icon labelPosition='left' onClick={() => emailUser()}>
                          <Icon name='mail square' />
                          INVITE
                        </Button>
                    }
                    {emailError ?
                      <div className='error-color'> Please enter a valid email address. </div>
                    :null}
                  </div>
                </Card.Content>
                :
                <Card.Content>
                  <Placeholder fluid>
                    <Placeholder.Header image>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line />
                        <div className="placeholder-msg">This feature is hidden to users outside the league.</div>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Paragraph>
                  </Placeholder>
                </Card.Content>
              }
            </Card>
            <Card color={'purple'} className="event-feed-card">
              <Card.Content>
                <Card.Header className="card-header"><Icon name="trophy" /> Trophy Case </Card.Header>
              </Card.Content>
              <Card.Content extra style={{textAlign: 'center'}}>
                <Grid className="trophy-grid">
                  <Grid.Row className="label-row">
                    <Grid.Column width={6}>Pool</Grid.Column>
                    <Grid.Column width={10}>Champion(s)</Grid.Column>
                  </Grid.Row>
                  {renderTrophys()}
                </Grid>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

function mapStateToProps ({ auth, league, seasonParamsIndex, joinModalVis }) {
  return ({ auth, league, seasonParamsIndex, joinModalVis })
}

export default connect (mapStateToProps, {requestLeagueAccess, setJoinModalVis}) (LeagueHome);
