import { GAME } from "../../../constants";

export const BONUS_POOL_OPEN = {
 MONTHLY: 'once per month',
 BEG_OF_SEASON: 'only at the beginning of the season',
 ALWAYS: 'at any time',
};

export const GAME_ICONS = {
  [GAME.NBA]: 'basketball ball',
  [GAME.MLB]: 'baseball ball'
}

export const INSURANCE_OPTION_TEXT = [
  '',
  'the most popular',
  'a random'
]

