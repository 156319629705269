import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {fetchPoolSelections} from '../../actions';
import logos from '../../modules/logos';
import { toastError, toastSuccess } from '../Toast';
import { Button, Grid, Header, Dropdown, Image, Icon, Table } from 'semantic-ui-react';

function EditPicks ({ activePool, poolSelections, gameEntrants, teamDetails, fetchPoolSelections }) {
  const [activeUser, setActiveUser]  = useState(null);
  const [activePicks, setActivePicks] = useState(null);
  const [originalPicks, setOriginalPicks] = useState(null);
  const [needToSave, setNeedToSave] = useState(false);

  useEffect(() => {
    if (!activeUser) {
      setActivePicks(blankWeeks);
    } else {
      setActivePicks(activeUserPicks(activeUser))
      setOriginalPicks(activeUserPicks(activeUser))
    }
  }, [activeUser])

  useEffect(() => {
    if (activeUser && JSON.stringify(activePicks) !== JSON.stringify(originalPicks)) {
      setNeedToSave(true);
    } else {
      setNeedToSave(false);
    }
  }, [activePicks, originalPicks])

  const blank = {
    key: 'blank',
    value: null,
    text: '- no pick -',
    image: null
  };

  const blankWeeks = JSON.parse(activePool.deadlines).map(w => {
    return blank;
  })

  const entrants = [...gameEntrants]
    .sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()))
    .map(e => {
      return {
        key: e.user_entry_concat,
        value: e.id,
        text: e.user_entry_concat,
        user_season_id: e.id
      }
    })

  function activeUserPicks (activeUser) {
    if (activeUser) {
      return poolSelections
      .filter(u => u.user_entry_concat === activeUser.user_entry_concat)
      [0].selections[0]
    }
  }

  function editUserPicks (weekIdx, team) {
    let newPicks = [...activePicks];
    newPicks[weekIdx] = typeof team === 'string' ? team : [""];
    setActivePicks(newPicks);
  }

  async function updateUserPicks () {
    const {game, season, league_abb, pool_idx, season_type} = activePool;
    const data = {
      user_season_id: activeUser.user_season_id,
      oldSelections: originalPicks,
      newSelections: activePicks
    }
    try {
      await axios.post('/admin/updateUserPicks', data);
      setOriginalPicks(activePicks);
      toastSuccess('Picks updated', `You have successfully updated picks for ${activeUser.user_entry_concat}`);  
      fetchPoolSelections(game, season, league_abb, pool_idx, season_type);
    } catch (e) {
      toastError('Error updating picks', `An error was encountered updating this user's picks: ${e}. Please contact ALA support for help with this error.`);
      console.log('error updating user settings was ', e);
    } 
  }

  const teams = Object.keys(teamDetails).map(team => {
    return {
      key: teamDetails[team].a,
      value: teamDetails[team].a,
      text: <span><Image className='edit-pick-logo' src={logos[`${activePool.game}_${team}`]} />{teamDetails[team].c}</span>
    }
  })

  function renderPicks (activePicks) {
    if (activeUser && activePicks) {
      return activePicks.map((team, idx) => {
          return (
            <Table.Row key={idx} className='edit-user-pick-row'>
              <Table.Cell width={8} floated='right' className='edit-pick-wk-col'>
                <span >Week {idx + 1}</span>
              </Table.Cell>
              <Table.Cell width={8} floated='left'>
                <Dropdown
                  className='edit-user-pick-sel'
                  scrolling
                  inline
                  options={[blank, ...teams]}
                  value={typeof team === 'string' ? team : null}
                  onChange={(e, {value}) => editUserPicks(idx, value)}
                />
              </Table.Cell>
            </Table.Row>
          )
        })
    } else {
      return JSON.parse(activePool.deadlines).map((d, idx) => {
        return (
          <Table.Row key={idx} className='edit-user-pick-row'>
            <Table.Cell width={8} floated='right' className='edit-pick-wk-col'>
              <span >Week {idx + 1}</span>
            </Table.Cell>
            <Table.Cell width={8} floated='left'></Table.Cell>
          </Table.Row>
        )
      })
    }
  }

  if (teamDetails !== null) {
    return (
      <Grid className='commish-tools-cont'>
        <Grid.Row column={2}>
          <Grid.Column>
            <div className="user-pg-header">Edit Picks</div>
            <Header size='medium'>Use this page to edit entrants' picks.</Header>
            <div>Please note that <b>any adjustments you make through this page will not be subject to the normal rules and restrictions of the game</b>. Any pick can be modified to be any team, regardless of whether an entrant has already used that team, how many lives they have, whether they have been eleminated, etc.</div>
            <br />
            <div>Along similar lines: aside from any modified picks, a user's game status will not in any way be affected by any changes you make on this page. If you need to do adjust an entrant's number of lives or otherwise modify their game status, please use the <b>Manage Entrants</b> tab. </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row column={2}>
          <Grid.Column>
            <Button size='large'>
              <Dropdown
                button
                className='large'
                floating
                labeled
                options={entrants}
                onChange={(e, {value}) => {
                  setActiveUser({user_entry_concat: e.target.textContent, user_season_id: value});}
                }
                search
                text={activeUser ? activeUser.user_entry_concat : 'Select or Search For an Entrant'}
                value={activeUser?.user_season_id}
              />
            </Button>
            <Button className='lg-btn' disabled={!needToSave} onClick={() => updateUserPicks()}>Save User Picks</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Week</Table.HeaderCell>
                <Table.HeaderCell>Selection</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {renderPicks(activePicks)}
            </Table.Body>
          </Table>
        </Grid.Row>
      </Grid>
    )
  } else {
    return <div> Loading ... </div>
  }
}

function mapStateToProps ({ teamDetails, fetchPoolSelections }) {
  return { teamDetails, fetchPoolSelections }
}

export default connect (mapStateToProps, {fetchPoolSelections}) (EditPicks)
