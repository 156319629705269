import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Message, Header, Icon, Segment, Grid, Image, Checkbox, Input, Loader, Dropdown, List, Popup, Transition, Button, Modal, Menu, Table, Label } from 'semantic-ui-react';
import NBABonusExample from '../howtoplay/nbaBonusExample';
import MLBBonusExample from '../howtoplay/mlbBonusExample';
import BonusPoolFaq from '../howtoplay/bonusPoolFaq';
import ContextPopup from '../other_helpers/ContextPopup';
import {activateLeaguePool, getBonusOptions} from '../../actions';
import { 
  BONUS_POOL_ACTIVE,
  BONUS_POOL_DISABLED,
  BONUS_POOL_SUBHEADER,
  BONUS_POOL_CADENCE_CONTEXT_BODY,
  BONUS_POOL_SHUTDOWN_CONTEXT_BODY,
  BONUS_POOL_CONTEXT_HEADER,
  INSURANCE_CONTEXT_BODY,
  INSURANCE_CONTEXT_HEADER,
  INSURANCE_COST_CONTEXT_BODY,
  INSURANCE_COST_CONTEXT_HEADER,
  INSURANCE_OPTIONS,
  OPT_IN_OPTIONS,
  SHOOT_MOON_ACTIVE,
  SHOOT_MOON_DISABLED,
} from './CreatePool.constants';
import '../styles/leagues.scss';
import '../styles/global.scss';
import { max } from 'lodash';

function CreatePool ({ auth, league, pendingSeasonParams, bonusOptions, activateLeaguePool, getBonusOptions }) {
  const [startDate, setStartDate] = useState(null);
  const [startWeek, setStartWeek] = useState(null);
  const [startText, setStartText] = useState(null);
  const [prevPools, setPrevPools] = useState(null);
  const [canActivate, setCanActivate] = useState(true);
  const [activateMsg, setActivateMsg] = useState(null);
  const [bonusPoolActive, setBonusPoolActive] = useState(false);
  const [shootMoonActive, setShootMoonActive] = useState(false);
  const [bonusPoolStatus, setBonusPoolStatus] = useState(BONUS_POOL_DISABLED);
  const [shootMoonStatus, setShootMoonStatus] = useState(SHOOT_MOON_DISABLED);
  const [bonusPoolOptions, setBonusPoolOptions] = useState(null);
  const [activePool, setActivePool] = useState(pendingSeasonParams[0]);
  const [confirmPool, setConfirmPool] = useState(false);
  const [buyin, setBuyin] = useState(50);
  const [rebuys, setRebuys] = useState(2);
  const [rebuyPrice, setRebuyPrice] = useState(25);
  const [maxEntries, setMaxEntries] = useState(1);
  const [insurance, setInsurance] = useState({value: 0, cost: 0});
  const [bonusPoolCadence, setBonusPoolCadence] = useState(2);
  const [bonusPoolShutdown, setBonusPoolShutdown] = useState(5);
  const square = { width: 100, height: 100 };

  useEffect(() => {
    if (activePool) {
      if (moment(activePool.start).isBefore(moment())) {
        let deadlines = JSON.parse(activePool.deadlines);
        for (var i = 0; i < deadlines.length; i++) {
          if (moment().isBefore(deadlines[i])) {
            setStartDate(deadlines[i]);
            setStartWeek(i+1);
            setStartText(`Your pool will start in Week ${i+1} of the ${activePool.game.toUpperCase()} ${activePool.type === 'reg' ? 'season' : 'preseason'}.`);
            break;
          }
        }
      } else {
        setStartDate(activePool.start);
        setStartWeek(1);
        setStartText(`Your pool will start in Week 1 of the ${activePool.game.toUpperCase()} ${activePool.type === 'reg' ? 'season' : 'preseason'}.`)
      }
      setPrevPools(league.seasons.filter(s => (s.game === activePool.game && s.season === activePool.season && s.season_type === activePool.type)).length);
      getBonusOptions(activePool.game);
    }

  }, [activePool])

  useEffect(() => {
    if (!auth) {
      setCanActivate(false);
      setActivateMsg("You must be logged in to activate this pool.");
    } else if (!JSON.parse(league.info.admins).includes(auth[0].username)) {
      setCanActivate(false);
      setActivateMsg("Only your league's administrators can activate a pool.");
    } else if (prevPools > 2) {
      setCanActivate(false);
      setActivateMsg("Each league is permitted a maximum of three pools for a given sport season.");
    } else {
      setCanActivate(true);
      setActivateMsg(null);
    }
  }, [prevPools, auth])

  useEffect(() => {
    if (bonusOptions) {
      const options = {};
      bonusOptions.forEach(bonus => {
        options[bonus.bonus_code] = {
          type: bonus.type,
          active: true,
          prize: bonus.prize_default,
          bonus_name: bonus.bonus_name,
          bonus_long_name: bonus.bonus_long_name
        }
      })
      setBonusPoolOptions(options);
    }
  }, [bonusOptions])

  function renderBonusOptions () {
    return Object.keys(bonusPoolOptions).map(b => {
      return (
        <Table.Row key={b} className={bonusPoolOptions[b].active ? null : 'inactive-bonus'}>
          <Table.Cell> <Checkbox toggle checked={bonusPoolOptions[b].active ? true : false} onChange={() => setBonusActive(b)} /> </Table.Cell>
          <Table.Cell> <Input disabled={!bonusPoolOptions[b].active} label='$' value={bonusPoolOptions[b].prize} onChange={(e, {value}) => setBonusPrize(b, value)} className='bonus-option'/> </Table.Cell>
          <Table.Cell> {bonusPoolOptions[b].bonus_long_name} </Table.Cell>
        </Table.Row>
      )
    })
  }

  const updateInsurance = (value) => setInsurance((prevState) => {
    return { ...prevState, value }
  })

  function setBonusActive (code) {
    let prevOptions = { ...bonusPoolOptions};
    prevOptions[code].active = !prevOptions[code].active;
    setBonusPoolOptions(prevOptions);
  }

  function setBonusPrize (code, value) {
    let prevOptions = { ...bonusPoolOptions};
    prevOptions[code].prize = value;
    setBonusPoolOptions(prevOptions);
  }

  function changeActivePool (id) {
    setActivePool(pendingSeasonParams.find(pool => pool.id === id));
  }

  function activatePool () {
    if (canActivate) {
      activateLeaguePool({
        activePool: {
          id: activePool.id,
          dashed_date_arrays: activePool.dashed_date_arrays,
          game: activePool.game,
          season: activePool.season,
          type: activePool.type,
          game_name: activePool.game_name,
          deadlines: activePool.deadlines,
        },
        buyin,
        bonusPoolActive,
        bonusPoolCadence,
        bonusPoolOptions,
        bonusPoolShutdown,
        insurance,
        league: league.info,
        maxEntries,
        prevPools,
        rebuys,
        rebuyPrice,
        shootMoonActive,
        startDate,
        startWeek,
        user: auth[0]
      });
    } else {
      alert('Only a league admin can activate a pool.')
    }
  }

  function toggleBonusPool () {
    if (!bonusPoolActive) {
      setBonusPoolActive(true);
      setBonusPoolStatus(BONUS_POOL_ACTIVE);
    } else {
      setBonusPoolActive(false);
      setBonusPoolStatus(BONUS_POOL_DISABLED);
    }
  }

  function toggleShootMoon () {
    if (!shootMoonActive) {
      setShootMoonActive(true);
      setShootMoonStatus(SHOOT_MOON_ACTIVE);
    } else {
      setShootMoonActive(false);
      setShootMoonStatus(SHOOT_MOON_DISABLED);
    }
  }

  function renderGameMenu () {
    return pendingSeasonParams.map((pool, idx) => {
      return (
        <Menu.Item
            key={idx}
            name={pool.game_name}
            active={pool.id === activePool.id}
            onClick={() => changeActivePool(pool.id)}
          />
      )
    })
  }

  function displayBonuses () {
    let bonuses = [];
    Object.keys(bonusPoolOptions).forEach(b => {
      if (bonusPoolOptions[b].active === true) {
        bonuses.push(`${bonusPoolOptions[b].bonus_name} ($${bonusPoolOptions[b].prize})`);
      }
    })
    return bonuses.join(', ');
  }

  function renderGame (sp) {
      return (
        <>
          <Grid key={sp.id} className="upcEventGrid">
            <Grid.Row>
              <Grid.Column width={16} textAlign='center'>
                <Image centered circular inline bordered size='small' src={sp.image_url} />
                <div style={{display: 'inline', marginLeft: 20}}>
                  <div className='game-mgmt-header' style={{fontSize: 48, display: 'inline', position: 'relative'}}>
                    <b>{sp.game_name.toUpperCase()}</b>
                    <div className='game-mgmt-subtext'>
                      <div> <b>{sp.game === 'nba' ? 'TIP-OFF' : 'FIRST PITCH'}</b>: {moment(startDate).format('M/D/YY, h:mm A zz')} </div>
                      <div className='game-mgmt-start'> <i> {startText} </i> </div>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            {prevPools > 0 ?
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Message compact negative={prevPools > 2} warning={prevPools === 2 || prevPools === 1}> <Icon name='exclamation'/>
                  This league currently has {prevPools} active {activePool.game_name} Pools. Leagues are permitted a maximum of three pools per sport, per season.</Message>
                </Grid.Column>
              </Grid.Row>
            : null }
            <Grid.Row style={{marginTop: 45}} centered>
              <Grid.Column width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step'>1</div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h2'>
                        Set Initial Buy-in
                        <Header.Subheader>
                          This is the standard entry fee into your league's pool.
                          <Input label='$' value={buyin} onChange={(e, {value}) => setBuyin(value)} />
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step'>2</div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h2'>
                        Set Extra Lives/Rebuys
                        <ContextPopup
                        header="Rebuys and Extra Lives"
                        content="The number of lives an entrant will have before being eliminated from the pool is their initial life + the permitted number of rebuys -- so if you allow two rebuys, an entrant will have three lives over the course of the season (provided they utilize those rebuys). If you'd prefer to allow entrants multiple lives without having any cost associated with their extra lives, set a number of rebuys/lives here, but then set the rebuy cost to $0."
                        trigger={<Icon style={{fontSize: 18, top: -10, left: 260}} className="context-icon purple-text" name='question circle' />}
                        />
                        <Header.Subheader>
                        Set the number of extra lives/rebuys permitted. If you'd prefer to allow an entrant only one life, set to 0.
                        <Input type="number" value={rebuys} onChange={(e, {value}) => setRebuys(value)} label="rebuys" labelPosition="right"/>
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{marginTop: 45}} centered>
              <Grid.Column width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step'>3</div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h2'>
                        Set Rebuy Cost
                        <ContextPopup
                          header="Rebuy Cost"
                          content="Set this value to $0 if you would prefer to allow your pool's entrants multiple lives without having to pay for their extra lives."
                          trigger={<Icon style={{fontSize: 18, top: -10, left: 179}} className="context-icon purple-text" name='question circle' />}
                        />
                        <Header.Subheader>
                          Set the cost of each rebuy/extra life. If you are not allowing rebuys, this can be ignored.
                          <Input disabled={rebuys<1} label='$' value={rebuyPrice} onChange={(e, {value}) => setRebuyPrice(value)} />
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step'>4</div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h2'>
                        Set Max Entries
                        <Header.Subheader>
                          Set the maximum number of entries each entrant can have in your pool.
                          <Input type="number" value={maxEntries} onChange={(e, {value}) => setMaxEntries(value)} label="max entries" labelPosition="right"/>
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{marginTop: 45}} centered>
              <Grid.Column width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step'>5</div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h2'>
                        Offer Insurance?
                        <ContextPopup
                          header={INSURANCE_CONTEXT_HEADER}
                          content={INSURANCE_CONTEXT_BODY}
                          trigger={<Icon style={{fontSize: 18, top: -10, left: 196}} className="context-icon purple-text" name='question circle' />}
                        />
                        <Header.Subheader>
                        Permit entrants to pay a one-time fee that protects them if they forget to make a pick.
                        <Dropdown
                          style={{marginTop: 10}}
                          fluid
                          selection
                          options={INSURANCE_OPTIONS}
                          value={insurance.value}
                          onChange={(e, {value}) => updateInsurance(value)}
                        />
                        {insurance.value > 0 && 
                          <div style={{marginTop: 20, marginBottom: 20}}>
                            <Input 
                              labelPosition='right'
                              type='number'
                              value={insurance.cost}
                              onChange={(e, {value}) => setInsurance((prevState) => {
                                return { ...prevState, cost: value }
                              })}
                            >
                              <Label>$</Label>
                              <input />
                              <Label color='red'>Insurance Cost</Label>
                            </Input>
                            <ContextPopup
                              header={INSURANCE_COST_CONTEXT_HEADER}
                              content={INSURANCE_COST_CONTEXT_BODY}
                              trigger={<Icon style={{fontSize: 18, top: 125, left: 300}} className="context-icon purple-text" name='question circle' />}
                            />
                          </div>
                        }
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              {activePool.game === 'mlb'
                ? <>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={6}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <Segment circular inverted style={square} floated='right'>
                              <div className='create-pool-step'>6</div>
                            </Segment>
                          </Grid.Column>
                          <Grid.Column width={11}>
                            <Header as='h2'>
                              Shoot the Moon?
                              <ContextPopup
                              header="Activate SHOOT THE MOON?"
                              content="For MLB Pools only. If Shoot the Moon is active and an entrant's selection loses *every game* they play that week (0-6 or worse), not only will they not lose a life, but they will GAIN a life."
                              trigger={<Icon style={{fontSize: 18, top: -10, left: 198}} className="context-icon purple-text" name='question circle' />}
                              />
                              <Header.Subheader>
                                If active, an entrant will *gain* a life if their weekly selection <b><i>loses</i></b> every game they play that week (0-6 or worse).
                                <div style={{marginTop: 5}}>
                                  <Checkbox className="lg-toggle" toggle label={shootMoonStatus} checked={shootMoonActive} onChange={() => toggleShootMoon()} />
                                </div>
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </> 
                : <Grid.Column width={7}></Grid.Column>
              }
            </Grid.Row>
            <Grid.Row style={{marginTop: insurance.value > 0 ? 45 : 10}}>
              <Grid.Column width={16} style={{marginLeft: '2.2vw'}}>
                <Grid>
                  <Grid.Row> 
                    <Grid.Column width={3}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step' style={{marginLeft: -8, marginRight: 19}}><Icon name='rocket' /></div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h1'>
                        Activate Performance Bonus Pool?
                        <div style={{marginBottom: 10}}>
                          <Checkbox className="lg-toggle" toggle label={bonusPoolStatus} checked={bonusPoolActive} onChange={() => toggleBonusPool()} />
                        </div>
                        <Header.Subheader>
                         {BONUS_POOL_SUBHEADER(true)}
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={3}></Grid.Column>
            </Grid.Row>
        </Grid>
        <Transition visible={bonusPoolActive} animation='fade' duration={600}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={10}>
                <List size='large' bulleted>
                  <List.Item>
                    The Performance Bonus Pool awards prizes to participating entrants for outstanding team or player achievements from their weekly Survivor selection, which are collectively paid out by the other entrants who have opted-in to the Peformance Bonus Pool. If activated, participation in the Bonus Pool will be optional for your league's entrants.
                  </List.Item>
                  <List.Item>
                    View the Performance Bonuses for {sp.game.toUpperCase()} Survivor in the table to the right.
                  </List.Item>
                  <List.Item>
                    {sp.game === 'nba' ? <NBABonusExample /> : <MLBBonusExample />}
                  </List.Item>
                  <List.Item>
                    Bonus Pool prizes and penalties are automatically calculated and tracked by the Survivor software, but it will be up to each league's Commissioner to follow up with each entrant and settle everyone's balances at the end of the season.
                  </List.Item>
                  <List.Item>
                    There are additional components to consider and options to configure if your pool is utilizing the Bonus Pool. Both of these options can be changed after your pool is set up while your season is ongoing.
                      <List.List>
                        <List.Item> <b>Determining when (or whether) entrants can opt-in and out-of the pool.</b> Some leagues may prefer to allow their entrants the strategic flexibility of coming and going from the Bonus Pool at their leisure; others may prefer to lock entrants in for a certain amount of time. We give you three options there. </List.Item>
                        <List.Item> <b>Determining when the bonus pool concludes.</b> We recommend shutting down the Bonus Pool when it is reduced to around five entrants, as at that point penalties may become unexpectedly expensive. But that is up to you. </List.Item>
                      </List.List>
                  </List.Item>
                  <List.Item>
                    For more information, take a look at the <Popup content={<BonusPoolFaq />} wide='very' trigger={<b><u>Bonus Pool FAQ</u></b>} />.
                  </List.Item>
                  <List.Item className='purple-text'>
                    <b>Your Bonus Pool settings can be modified after you set up your pool and before the season begins.</b>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={6} textAlign='center'>
                <Header size='large' className='purple-text' style={{marginBottom: 0}}>{sp.game.toUpperCase()} Survivor: Bonus Pool Options </Header>
                <i> Select which bonuses will be active in your pool and modify the prize values to fit your pool's preferences. (These options can be changed later) </i>
    
                  <div>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Active</Table.HeaderCell>
                          <Table.HeaderCell>Payout</Table.HeaderCell>
                          <Table.HeaderCell>Achievement</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {renderBonusOptions()}
                      </Table.Body>
                    </Table>
                  </div>

              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid columns={4}>
                <Grid.Column></Grid.Column>
                <Grid.Column>
                  <Header as='h2' style={{position: 'relative'}}>
                    Set Opt-in/Opt-out Cadence
                    <ContextPopup
                    header={BONUS_POOL_CONTEXT_HEADER}
                    content={BONUS_POOL_CADENCE_CONTEXT_BODY}
                    trigger={<Icon style={{fontSize: 18, top: 23, left: 95}} className="context-icon purple-text" name='question circle' />}
                    />
                    <Header.Subheader>
                      When can your pool's entrants come and go from the Bonus Pool?
                      <Dropdown
                        onChange={(e, {value}) => setBonusPoolCadence(value)}
                        options={OPT_IN_OPTIONS}
                        selection
                        value={bonusPoolCadence}
                        style={{marginTop: 10}}
                      />
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h2'>
                    Set Shutdown Number
                    <ContextPopup
                    header={BONUS_POOL_CONTEXT_HEADER}
                    content={BONUS_POOL_SHUTDOWN_CONTEXT_BODY}
                    trigger={<Icon style={{fontSize: 18, top: 5, left: 258}} className="context-icon purple-text" name='question circle' />}
                    />
                    <Header.Subheader>
                      Decide when your Bonus Pool should shut down. If you would like the pool to run all season regardless of how many entrants remain in the Bonus Pool, set to 0.
                      <Input style={{marginTop: 15}} type="number" value={bonusPoolShutdown} label="entrants remaining" labelPosition="right" onChange={(e, {value}) => setBonusPoolShutdown(value)}/>
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column></Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid>
        </Transition>
      </>
    )
  }

  if (pendingSeasonParams !== null && bonusOptions !== null && bonusPoolOptions !== null) {
    return (
      <div>
        <Grid style={{marginBottom: 20, marginTop: 5}}>
          <Grid.Row className='page-header create-pool'>
            <Grid.Column width={16}>
              <Header as='h2'>
                <div style={{display: 'inline-block'}}>
                  <Icon name='birthday cake' circular size='large'/>
                </div>
                <Header.Content>
                  Configure & Activate Pools for Your League
                  <Header.Subheader>- <b>Your league's Commissioner</b> can use this page to activate upcoming games for your league. <br/>
                  - Once a pool is activated, league members can sign up and begin making selections. <br/>
                  - ALA's default settings have been loaded to get you started; modify those to fit your league's preferences.
                  </Header.Subheader>
                </Header.Content>
                {activePool ?
                  <div style={{position: 'absolute', top: -7, right: 10}}>
                    <div className='league-label'>Select Game:</div>
                      <Menu vertical inverted>
                        {renderGameMenu()}
                      </Menu>
                    </div>
                  : null }
              </Header>
            </Grid.Column>
          </Grid.Row>
          {activePool ?
            renderGame(activePool)
            :
            <Grid.Row>
              <Grid.Column textAlign='center' style={{fontSize: 24, marginTop: 60, marginBottom: 80}}>
                There are no upcoming pools available to activate. Check back here as the next season draws closer.
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
        {activePool ?
          <Grid centered style={{marginBottom: 20}} className="confirm-row">
            <Button 
              style={{marginTop: 25}}
              size='massive'
              onClick={() => {
                if (maxEntries < 1) {
                  setMaxEntries(1);
                }
                if (rebuys < 1) {
                  setRebuyPrice(0);
                }
                setConfirmPool(true);
              }} 
              disabled={!canActivate}> 
                CONFIRM SETTINGS & ACTIVATE POOL 
            </Button>
            {activateMsg ?
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Message compact negative> <Icon name='exclamation'/>
                  {activateMsg}</Message>
                </Grid.Column>
              </Grid.Row>
            : null }
            <Modal size="small" centered open={confirmPool} onClose={() => setConfirmPool(false)} className="confirm-pick-modal">
            <Header as='h2' style={{backgroundColor: '#6638f0', color: 'white'}}>
              <div className='league-header-cont'>
                <span className='league-header'>{league.info.name}</span>
                Review Settings & Activate Pool
              </div>
            </Header>
              <Modal.Content style={{fontSize: 20}}>
                <p>Your <b>{`${activePool.game.toUpperCase()} ${activePool.season}`}</b> Survivor Pool will be configured with the following settings:</p>

                <Table compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell><b>Initial Buy-in</b></Table.Cell>
                      <Table.Cell>${buyin || 0}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Lives per Entrant</b></Table.Cell>
                      <Table.Cell>{rebuys > 0 ? `${1+parseInt(rebuys)} Lives` : '1 Life'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Rebuy/Extra Life Cost</b></Table.Cell>
                      <Table.Cell>{rebuys > 0 ? `$${rebuyPrice || 0}` : 'No Rebuys'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Max Entries</b></Table.Cell>
                      <Table.Cell>{maxEntries} per user</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Insurance</b></Table.Cell>
                      <Table.Cell>{insurance.value === 0
                        ? 'Not Offered'
                        : insurance.value === 1
                        ? `Most Popular Pick ($${insurance.cost || 0} cost)`
                        : `Random Pick ($${insurance.cost || 0} cost)`}
                      </Table.Cell>
                    </Table.Row>
                    {activePool.game === 'mlb' && 
                      <Table.Row>
                        <Table.Cell><b>Shoot the Moon</b></Table.Cell>
                        <Table.Cell>{shootMoonActive ? 'Activated' : 'Disabled'}</Table.Cell>
                      </Table.Row>
                    }
                    <Table.Row>
                      <Table.Cell><b>Bonus Pool</b></Table.Cell>
                      <Table.Cell>{bonusPoolActive ? 'Activated' : 'Disabled'}</Table.Cell>
                    </Table.Row>
                    {bonusPoolActive &&
                      <>
                        <Table.Row>
                          <Table.Cell><b>Bonus Pool Cadence</b></Table.Cell>
                          <Table.Cell>{bonusPoolActive && 
                            `Entrants can enter/leave Bonus Pool ${bonusPoolCadence === 2 
                              ? 'once per month'
                              : bonusPoolCadence === 1
                              ? 'at any time'
                              : 'at beginning of season only'}`
                            }
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell><b>Bonus Pool Shutdown</b></Table.Cell>
                          <Table.Cell>{bonusPoolShutdown} or Less Entrants Remaining</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell><b>Active Bonuses</b></Table.Cell>
                          <Table.Cell>{displayBonuses()}</Table.Cell>
                        </Table.Row>
                      </>
                    }
                  </Table.Body>
                </Table>
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={() => setConfirmPool(false)}>
                  Back
                </Button>
                <Button onClick={activatePool} style={{backgroundColor: '#6638f0', color: 'white'}}>
                  <Icon name='check circle' />
                  ACTIVATE POOL
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid>
        : null}
      </div>
    )
  } else {
    return <Loader className="loader-down" active inline='centered' size='massive'> LOADING POOL OPTIONS ... </Loader>;
  }
}

function mapStateToProps ({ auth, league, pendingSeasonParams, bonusOptions }) {
  return ({ auth, league, pendingSeasonParams, bonusOptions })
}

export default connect (mapStateToProps, {activateLeaguePool, getBonusOptions}) (CreatePool);
