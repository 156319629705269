import React from 'react';
import { Icon, Table, Image, Popup } from 'semantic-ui-react';
import moment from 'moment';
import TableHeaders from './TableHeaders';
import RenderLives from './RenderLives';
import { GAME } from '../../constants';
import { formatDollars, getUserStyle } from '../../utils';
import { STANDINGS_VIEW_VALUES } from '../pool/Standings/Standings.constants';
import logos from '../../modules/logos';
import "../styles/usage-grid.scss";
import "../styles/selections.scss";

const ScrollableEntrantTable = ({ 
  activeGame,
  activeUser,
  activeUserSeason,
  activeWeek,
  alphTeams,
  bonusActive,
  bonusTotals,
  entrants,
  liveWeek,
  schedule,
  seasonParams,
  teamResults,
  teams,
  view,
  weeklyBonusTotals,
  weeks,
}) => {
  const renderEntrants = entrants => {
    return entrants.map(entrant => {
      return (
        <Table.Row key={entrant.id}>
          <Table.Cell className={`${getUserStyle(entrant, activeUser, activeUserSeason)}`} style={{position: 'relative'}}>
            <span>{entrant.user_entry_concat}</span>
            {entrant.bonus && <Popup
              content="In Bonus Pool"
              trigger={<div style={{display: 'inline-block', position: 'absolute', top: '3px'}}><Icon className={'bonus-pool-icon'} name='rocket' /></div>}
            />}
          </Table.Cell>
          <Table.Cell className={`lives-td ${getUserStyle(entrant, activeUser, activeUserSeason)}`} textAlign='center'> 
              <RenderLives lives={entrant.lives} /> 
          </Table.Cell>
          {bonusActive && 
          <Table.Cell className={`lives-td ${getUserStyle(entrant, activeUser, activeUserSeason)}`} textAlign='center'>
            {formatDollars(bonusTotals[`${entrant.username}_${entrant.id}`], 2)}
          </Table.Cell>}
        </Table.Row>
      )
    })
  }

  const renderRows = entrants => {
    return entrants.map(entrant => {
      return (
        <Table.Row key={entrant.id}>
          {renderSelections(entrant)}
        </Table.Row>
      )
    })
  }

  const renderSelections = (entrant) => {
    const selections = entrant?.selections[0];

    if (view === STANDINGS_VIEW_VALUES.WEEK) {
      return selections.map((selection, idx) => {
        if (selections.indexOf(selection) <= (liveWeek-1) && alphTeams.indexOf(selection) !== -1) {
          return (
            <Table.Cell key={idx} className={`logo-cell standing-cell-${activeGame}
              ${(idx < (activeWeek-1) || moment().isAfter(moment(seasonParams.end).add(1, 'days')))
                ?
                ((activeGame === GAME.MLB && teamResults[selection][idx][0] === 0 && teamResults[selection][idx][1]) > 5) ? 
                  'shot-moon' : 
                  (
                    ((teamResults[selection][idx][0] < teamResults[selection][idx][1]) ||
                    (teamResults[selection][idx][1] === 0 && teamResults[selection][idx][0] === 0)) ? 
                    'losing' : 'winning'
                  )
                :
                'null'
                }`}
            > <Image className={`${activeGame}-standing-box ${activeGame}-${selection}-logo`} src={logos[`${activeGame}_${selection}`]} /> </Table.Cell>
          )
        } else if (selection === 'xxx') {
          return (
            <Table.Cell key={idx} className='losing'> - </Table.Cell>
          )
        } else {
          return (
            <Table.Cell key={idx} className={`${getUserStyle(entrant, activeUser, activeUserSeason)}}`}> - </Table.Cell>
          )
        }
      })
    } else if (view === STANDINGS_VIEW_VALUES.TEAM) {
      return teams.map(team => {
        if (selections.indexOf(team) !== -1 && selections.indexOf(team) <= (liveWeek-1)) {
          return (
            <Table.Cell key={team} className='week-cell'> {selections.indexOf(team) + 1} </Table.Cell>
          )
        } else {
          return (
            <Table.Cell className={`${getUserStyle(entrant, activeUser, activeUserSeason)}}`}> - </Table.Cell>
          )
        }
      })
    } else if (view === STANDINGS_VIEW_VALUES.BONUS) {
      return weeklyBonusTotals[`${entrant.username}_${entrant.id}`].map((week, idx) => {
        if (idx < liveWeek) {
          return (
            <Table.Cell className={`${getUserStyle(entrant, activeUser, activeUserSeason)}}`}> {formatDollars(week, 2)} </Table.Cell>
          )
        } else {
          return (
            <Table.Cell className={`${getUserStyle(entrant, activeUser, activeUserSeason)}}`}> - </Table.Cell>
          )
        }
      })
    }
  }

  return (
    <div className="master-table-container">
      <Table className="usage-entrant-table" attached="right">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell> Entry </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'> Lives </Table.HeaderCell>
            {bonusActive && <Table.HeaderCell textAlign='center'> <span className={`${activeGame}-color`}><Icon name='rocket'/></span> Bonus $ </Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderEntrants(entrants)}
        </Table.Body>
      </Table>
      <div className="usage-table-container">
      <Table className="usage-data-table" attached="left">
        <Table.Header>
          <Table.Row>
            <TableHeaders
              view={view}
              weeks={weeks}
              schedule={schedule}
              teams={teams}
            />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderRows(entrants)}
        </Table.Body>
      </Table>
      </div>
    </div>
  )
}

export default ScrollableEntrantTable;
