import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import { STANDINGS_VIEW_VALUES } from '../pool/Standings/Standings.constants';

const TableHeaders = ({ view, weeks, teams, days }) => {
  if (view === STANDINGS_VIEW_VALUES.WEEK || view === STANDINGS_VIEW_VALUES.BONUS) {
    let headers = [];
    for (let i=0; i<weeks; i++) {
      headers.push(
        <Table.HeaderCell style={{textAlign: 'center'}} key={i}> {i + 1} </Table.HeaderCell>
      );
    };
    return headers;
  } else if (view === STANDINGS_VIEW_VALUES.TEAM) {
    return teams.map(team =>
      <Table.HeaderCell style={{textAlign: 'center'}} key={team}> {team} </Table.HeaderCell>
    )
  } else if (view === STANDINGS_VIEW_VALUES.DAY) {
    return days.map(day =>
      <Table.HeaderCell style={{textAlign: 'center'}} key={day}> {moment(day).format("ddd M/D").toUpperCase()} </Table.HeaderCell>
    )
  } else {
    console.log('Invalid view variable supplied to headers!');
    return
  }
}

export default TableHeaders;
