export const BONUS_POOL_ACTIVE = 'Bonus Pool is ACTIVE';
export const BONUS_POOL_DISABLED = 'Bonus Pool is DISABLED (toggle switch to set to ACTIVE and View Configuration Options)';

export const BONUS_POOL_ENTER_ANYTIME = 'Entrants can change their Bonus Pool status at any time.'
export const BONUS_POOL_ENTER_MONTHLY = 'Entrants can change their Bonus Pool staus once per month.'
export const BONUS_POOL_ENTER_PRESEASON = 'Entrants can only change their Bonus Pool status before the season begins.'

export const SHOOT_MOON_DISABLED = 'Shoot the Moon is DISABLED';
export const SHOOT_MOON_ACTIVE = 'Shoot the Moon is ACTIVE';

export const INSURANCE_NOT_OFFERED = 'Do Not Offer Insurance';
export const INSURANCE_MOST_POPULAR = 'Offer Insurance (Most Popular Pick)';
export const INSURANCE_RANDOM = 'Offer Insurance (Random Pick)';

export const INSURANCE_CONTEXT_HEADER = "What's Insurance?";
export const INSURANCE_CONTEXT_BODY = "An insurance policy protects an entrant if they forget to make a pick. If 'Most Popular Pick' is selected, the entrant will be given the most popular pick in your pool that week that the entrant still has available. If 'Random Pick' is selected, the entrant will be given a random pick from the remaining teams that they have available."

export const INSURANCE_COST_CONTEXT_HEADER = "Insurance Cost";
export const INSURANCE_COST_CONTEXT_BODY = "Optionally set a cost for your pool's entrants to purchase insurance. Entrants will have to opt-in to Insurance purchases, which will then be included in your pool's prize distribution and Finance tracking.";

export const BONUS_POOL_CONTEXT_HEADER = "Why does this matter?";
export const BONUS_POOL_CADENCE_CONTEXT_BODY = "A Bonus Pool's Opt-in/Opt-out Cadence controls how liberal entrants can be in 'strategically' participating in the Bonus Pool. Some leagues may prefer to give their entrants free reign in choosing to gamble on bonuses whenever they feel like it; others may find it dispiriting to allow entrants to only participate (and risk paying out penalties) during weeks in which they may be utilizing teams more likely to earn bonuses. Note that an entrant is never allowed to enter or leave a bonus pool DURING a week; any change to an entrant's Bonus Pool status does not become effective until the following week.";

export const BONUS_POOL_SHUTDOWN_CONTEXT_BODY = "As Bonus Pools shrink in size, bonuses become more rare on account of fewer teams and entrants being eligible to earn them -- but they are also more proportionally expensive for the non-prize-winning entrants, since any bonus earned is paid out collectively by the other entrants in the Bonus Pool. If your Bonus Pool prizes are for any meaningful amount of money, ALA recommends shutting down the pool with no less than five entrants remaining, lest one of the last remaining entrants end up getting particularly unlucky and owing a hefty bill all on their own. But it's up to you and how much you and your leaguemates want to gamble!";

export const OPT_IN_OPTIONS = [
  {key: 1, value: 1, text: BONUS_POOL_ENTER_ANYTIME},
  {key: 2, value: 2, text: BONUS_POOL_ENTER_MONTHLY},
  {key: 3, value: 3, text: BONUS_POOL_ENTER_PRESEASON}
]

export const INSURANCE_OPTIONS = [
  { key: 0, text: INSURANCE_NOT_OFFERED, value: 0 },
  { key: 1, text: INSURANCE_MOST_POPULAR, value: 1 },
  { key: 2, text: INSURANCE_RANDOM, value: 2 },
]

export const BONUS_POOL_SUBHEADER = (explainSwitch = false) => `The Performance Bonus Pool is an advanced game component available to leagues that would like to raise the stakes and have additional rooting interest in their Survivor Pool beyond whether their chosen team will win their games.${explainSwitch ? ` Use the switch above to view Bonus Pool configuration options and additional details.` : ''}`