import { SET_ACTIVE_ENTRY } from "../actions/types";

export default function (state = [], action) {
  switch (action.type) {
    case SET_ACTIVE_ENTRY:
      const { leagueSeasonId, entryNum } = action.payload;
      return [
        ...state.filter(e => e.leagueSeasonId !== leagueSeasonId),
        {leagueSeasonId, entryNum}
      ];
    default:
      return state;
  }
}