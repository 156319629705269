import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Header, Icon, Table, Loader, Grid, Radio, Dropdown } from 'semantic-ui-react';
import BonusRows from './table_helpers/BonusRows';
import { formatDollars } from '../utils';
import './styles/perf-bonus.scss';

const nbaBonusTypeOptions = [
  { key: '50pts', text: '50 PTS', value: 1 },
  { key: '20-20', text: '20-20', value: 2 },
  { key: '140tmPts', text: '140 TM PTS', value: 3 },
  { key: '20run', text: '20-0 RUN', value: 4 },
  { key: 'perfWeek', text: 'PERFECT WEEK', value: 5 },
  { key: '30ptWin', text: '30+ PT WIN', value: 6 },
  { key: '2otWin', text: '2-OT+ WIN', value: 7 },
  { key: 'buzzer', text: 'BUZZER-BEATER', value: 8 },
  { key: 'tripDub', text: 'TRIPLE-DOUBLE', value: 9 }
]

const mlbBonusTypeOptions = [
  { key: 'perfGm', text: 'PERFECT GAME', value: 10 },
  { key: 'nono', text: 'NO HITTER', value: 11},
  { key: 'perfWk', text: 'PERFECT WEEK', value: 5},
  { key: 'cycle', text: 'CYCLE', value: 12},
  { key: 'grandSlam', text: 'GRAND SLAM', value: 13},
  { key: '15runs', text: '15+ RUNS', value: 14},
  { key: 'astroBean', text: 'ASTRO BEANED', value: 15}
]

const dollarOptions = [
  { key: 'all', text: 'All', value: 'All' },
  { key: 'prize', text: 'Prizes', value: 'Prizes' },
  { key: 'payout', text: 'Payouts', value: 'Payouts' }
]

const nbaTeamOptions = [
  { key: 'ATL', text: 'ATL', value: 'ATL' },
  { key: 'BKN', text: 'BKN', value: 'BKN' },
  { key: 'BOS', text: 'BOS', value: 'BOS' },
  { key: 'CHA', text: 'CHA', value: 'CHA' },
  { key: 'CHI', text: 'CHI', value: 'CHI' },
  { key: 'CLE', text: 'CLE', value: 'CLE' },
  { key: 'DAL', text: 'DAL', value: 'DAL' },
  { key: 'DEN', text: 'DEN', value: 'DEN' },
  { key: 'DET', text: 'DET', value: 'DET' },
  { key: 'GSW', text: 'GSW', value: 'GSW' },
  { key: 'HOU', text: 'HOU', value: 'HOU' },
  { key: 'IND', text: 'IND', value: 'IND' },
  { key: 'LAC', text: 'LAC', value: 'LAC' },
  { key: 'LAL', text: 'LAL', value: 'LAL' },
  { key: 'MEM', text: 'MEM', value: 'MEM' },
  { key: 'MIA', text: 'MIA', value: 'MIA' },
  { key: 'MIL', text: 'MIL', value: 'MIL' },
  { key: 'MIN', text: 'MIN', value: 'MIN' },
  { key: 'NOP', text: 'NOP', value: 'NOP' },
  { key: 'NYK', text: 'NYK', value: 'NYK' },
  { key: 'OKC', text: 'OKC', value: 'OKC' },
  { key: 'ORL', text: 'ORL', value: 'ORL' },
  { key: 'PHI', text: 'PHI', value: 'PHI' },
  { key: 'PHX', text: 'PHX', value: 'PHX' },
  { key: 'POR', text: 'POR', value: 'POR' },
  { key: 'SAC', text: 'SAC', value: 'SAC' },
  { key: 'SAS', text: 'SAS', value: 'SAS' },
  { key: 'TOR', text: 'TOR', value: 'TOR' },
  { key: 'UTA', text: 'UTA', value: 'UTA' },
  { key: 'WAS', text: 'WAS', value: 'WAS' }
]

const mlbTeamOptions = [
  { key: 'ARI', text: 'ARI', value: 'ARI' },
  { key: 'ATL', text: 'ATL', value: 'ATL' },
  { key: 'BAL', text: 'BAL', value: 'BAL' },
  { key: 'BOS', text: 'BOS', value: 'BOS' },
  { key: 'CHC', text: 'CHC', value: 'CHC' },
  { key: 'CIN', text: 'CIN', value: 'CIN' },
  { key: 'CLE', text: 'CLE', value: 'CLE' },
  { key: 'COL', text: 'COL', value: 'COL' },
  { key: 'CWS', text: 'CWS', value: 'CWS' },
  { key: 'DET', text: 'DET', value: 'DET' },
  { key: 'HOU', text: 'HOU', value: 'HOU' },
  { key: 'KC', text: 'KC', value: 'KC' },
  { key: 'LAA', text: 'LAA', value: 'LAA' },
  { key: 'LAD', text: 'LAD', value: 'LAD' },
  { key: 'MIA', text: 'MIA', value: 'MIA' },
  { key: 'MIL', text: 'MIL', value: 'MIL' },
  { key: 'MIN', text: 'MIN', value: 'MIN' },
  { key: 'NYM', text: 'NYM', value: 'NYM' },
  { key: 'NYY', text: 'NYY', value: 'NYY' },
  { key: 'OAK', text: 'OAK', value: 'OAK' },
  { key: 'PHI', text: 'PHI', value: 'PHI' },
  { key: 'PIT', text: 'PIT', value: 'PIT' },
  { key: 'SD', text: 'SD', value: 'SD' },
  { key: 'SEA', text: 'SEA', value: 'SEA' },
  { key: 'SF', text: 'SF', value: 'SF' },
  { key: 'STL', text: 'STL', value: 'STL' },
  { key: 'TB', text: 'TB', value: 'TB' },
  { key: 'TEX', text: 'TEX', value: 'TEX' },
  { key: 'TOR', text: 'TOR', value: 'TOR' },
  { key: 'WSH', text: 'WSH', value: 'WSH' }
]

const icons = {
  'nba': 'basketball ball',
  'mlb': 'baseball ball'
}

const nbaBonusTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const mlbBonusTypes = [5, 10, 11, 12, 13, 14, 15];

class PerfBonus extends Component {
  state = {
    allUsersOn: true,
    allWeeksOn: true,
    allTeamsOn: true,
    allTypesOn: true,
    allDollarsOn: false,
    allUsers: [], // Set on mount
    users: [], // Set on mount
    usersSelected: [],
    usersTouched: false,
    allWeeks: [], // Set on mount
    weeks: [], // Set on mount
    weeksSelected: [],
    weeksTouched: false,
    allTeams: this.props.alphTeams || null, // Set from props
    teams: this.props.alphTeams || null, // Set from props
    teamsSelected: [],
    teamsTouched: false,
    allTypes: this.props.activePool.game === 'nba' ? nbaBonusTypes : mlbBonusTypes,
    types: this.props.activePool.game === 'nba' ? nbaBonusTypes : mlbBonusTypes,
    typesSelected: [],
    typesTouched: false,
    allBonusEvents: this.props.bonusEvents || null, // Set from props
    bonusEvents: this.props.bonusEvents || null, // Set from props
    bonusProcessed: false,
    bonusTotal: 0,
    dollars: 'Prizes',
    weekOptions: [], // Set on mount
    userOptions: [] // Set on mount
  }

  componentDidMount = async () => {
    let weeks = [];
    let weekOptions = [];
    for (var i = 1; i < this.props.activeGameWeek + 1; i++) {
      weeks.push(i);
      weekOptions.push({key: i, text: `Week ${i}`, value: i})
    };
    this.setState({allWeeks: weeks, weeks: weeks});
    this.setState({weekOptions: weekOptions});

    let userKeys = [];
    let users = [];
    for (var j = 0; j < this.props.gameEntrants.length; j++) {
      users.push(this.props.gameEntrants[j].username);
      userKeys.push({ key: j, text: this.props.gameEntrants[j].username, value: this.props.gameEntrants[j].username});
    }

    // console.log('users in componentDidMount are ', users);

    let sortedKeys = _.sortBy(userKeys, [user => user.text.toString().toLowerCase()]);
    this.setState({allUsers: users, users: users});
    this.setState({userOptions: sortedKeys});
    if (this.state.allBonusEvents) {
      this.filterBonusTable();
    }
  }

  filterBonusTable = async () => {
    // console.log('this.state.allBonusEvents', this.state.allBonusEvents);
    // console.log('this.state.users', this.state.users);
    let bonuses = this.state.allBonusEvents.filter(bonus => {
      return (
        // this.state.users.indexOf(bonus.username) !== -1 &&
        // this.state.weeks.indexOf(bonus.week) !== -1 &&
        // this.state.types.indexOf(bonus.bonus_type) !== -1 &&
        this.state.teams.indexOf(bonus.team_abb) !== -1
      )
    });
    if (this.state.dollars === 'Prizes') {
      bonuses = bonuses.filter(bonus => bonus.dollars_total > 0)
    };
    if (this.state.dollars === 'Payouts') {
      bonuses = bonuses.filter(bonus => bonus.dollars_total < 0)
    }
    // let sum = 0;
    let total = bonuses.reduce((sum, bonus) => {return sum + bonus.dollars_total}, 0);

    // console.log('bonuses at end of filter are ', bonuses);

    this.setState({bonusEvents: bonuses, bonusTotal: total});
    this.setState({bonusProcessed: true});
  }

  toggleAllUsers = async () => {
    if (this.state.allUsersOn) {
      this.setState({allUsersOn: false});
      this.state.usersTouched ?
        this.setState({users: this.state.usersSelected}) :
        this.setState({users: [], usersTouched: true});
      this.filterBonusTable();
    } else {
      this.setState({
        allUsersOn: true,
        users: this.state.allUsers
      });
      this.filterBonusTable();
    }
  }

  toggleAllWeeks = async () => {
    if (this.state.allWeeksOn) {
      this.setState({allWeeksOn: false});
      this.state.weeksTouched ?
        this.setState({weeks: this.state.weeksSelected}) :
        this.setState({weeks: [], weeksTouched: true});
      this.filterBonusTable();
    } else {
      this.setState({
        allWeeksOn: true,
        weeks: this.state.allWeeks
      });
      this.filterBonusTable();
    }
  }

  toggleAllTypes = async () => {
    if (this.state.allTypesOn) {
      this.setState({allTypesOn: false});
      this.state.typesTouched ?
        this.setState({types: this.state.typesSelected}) :
        this.setState({types: [], typesTouched: true});
      this.filterBonusTable();
    } else {
      this.setState({
        allTypesOn: true,
        types: this.state.allTypes
      });
      this.filterBonusTable();
    }
  }

  toggleAllTeams = async () => {
    if (this.state.allTeamsOn) {
      this.setState({allTeamsOn: false});
      this.state.teamsTouched ?
        this.setState({teams: this.state.teamsSelected}) :
        this.setState({teams: [], teamsTouched: true});
      this.filterBonusTable();
    } else {
      this.setState({
        allTeamsOn: true,
        teams: this.state.allTeams
      });
      this.filterBonusTable();
    }
  }

  changeUsers = async (e, { value }) => {
    this.setState({ usersSelected: value, users: value });
    this.filterBonusTable();
  };

  changeWeeks = async (e, { value }) => {
    this.setState({ weeksSelected: value, weeks: value });
    this.filterBonusTable();
  };

  changeTypes = async (e, { value }) => {
    this.setState({ typesSelected: value, types: value });
    this.filterBonusTable();
  };

  changeTeams = async (e, { value }) => {
    this.setState({ teamsSelected: value, teams: value });
    this.filterBonusTable();
  };

  changeDollars = async (e, { value }) => {
    this.setState({ dollars: value });
    this.filterBonusTable();
  };

  render () {
    const { 
      allTeamsOn,
      allTypesOn,
      allUsersOn,
      allWeeksOn,
      bonusEvents,
      bonusProcessed,
      bonusTotal,
      dollars,
      teams,
      teamsSelected,
      teamsTouched,
      types,
      typesSelected,
      typesTouched,
      userOptions,
      users,
      usersSelected,
      usersTouched,
      weekOptions,
      weeks,
      weeksSelected,
      weeksTouched
    } = this.state;
    const { activeGameWeek, gameEntrants, activePool, alphTeams } = this.props;

    console.log('bonusEvents', bonusEvents);

    if (bonusEvents !== null && bonusProcessed && weekOptions.length === activeGameWeek && userOptions.length === gameEntrants.length && alphTeams.length > 0) {
      return (
        <div>
          <Grid style={{marginBottom: 20, marginTop: 5}}>
            <Grid.Row className={`page-header ${this.props.activePool.game} bonuses`}>
              <Grid.Column width={16}>
                <Header as='h2'>
                  <div style={{display: 'inline-block'}}>
                    <Icon name='rocket' circular inverted size='large'/>
                  </div>
                  <Header.Content>
                    Performance Bonuses
                    <Header.Subheader>
                      - Use the filters to view bonuses by Entrant, Week, Bonus Type, and/or {activePool.game.toUpperCase()} team -- or just browse the full list. <br />
                      - By default, Prizes are shown. To also view Payouts (penalties), use the <b>View</b> filter.
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns={5} divided>
            <Grid.Column>
              <Radio toggle
                className="nba-bonus-toggle"
                checked={allUsersOn}
                label='Show All Users'
                onClick={this.toggleAllUsers}
              />
              {allUsersOn ? null :
                <Dropdown placeholder='Select Users' fluid multiple selection options={userOptions}
                  className="perf-bonus-sel"
                  value={usersTouched ? usersSelected : users}
                  onChange={this.changeUsers}
                />
              }
            </Grid.Column>
            <Grid.Column>
              <Radio toggle
                className="nba-bonus-toggle"
                checked={allWeeksOn}
                label='Show All Weeks'
                onClick={this.toggleAllWeeks}
              />
              {allWeeksOn ? null :
                <Dropdown placeholder='Select Weeks' fluid multiple selection options={weekOptions}
                  className="perf-bonus-sel"
                  value={weeksTouched ? weeksSelected : weeks}
                  onChange={this.changeWeeks}
                />
              }
            </Grid.Column>
            <Grid.Column>
              <Radio toggle
                className="nba-bonus-toggle"
                checked={allTypesOn}
                label='Show All Types'
                onClick={this.toggleAllTypes}
              />
              {allTypesOn ? null :
                <Dropdown placeholder='Select Types' fluid multiple selection options={activePool.game === 'nba' ? nbaBonusTypeOptions : mlbBonusTypeOptions}
                  className="perf-bonus-sel"
                  value={typesTouched ? typesSelected : types}
                  onChange={this.changeTypes}
                />
              }
            </Grid.Column>
            <Grid.Column>
              <Radio toggle
                className="nba-bonus-toggle"
                checked={allTeamsOn}
                label='Show All Teams'
                onClick={this.toggleAllTeams}
              />
              {allTeamsOn ? null :
                <Dropdown placeholder='Select Teams' fluid multiple selection options={activePool.game === 'nba' ? nbaTeamOptions : mlbTeamOptions}
                  className="perf-bonus-sel"
                  value={teamsTouched ? teamsSelected : teams}
                  onChange={this.changeTeams}
                />
              }
            </Grid.Column>
            <Grid.Column>
              <Dropdown fluid selection options={dollarOptions}
                text={`View:  ${dollars.toUpperCase()}`}
                value={dollars}
                onChange={this.changeDollars}
                icon={icons[activePool.game]}
                floating
                labeled
                button
                className='icon view-filter'
              />
            </Grid.Column>
          </Grid>
          <Table compact className="perf-bonus">
            <Table.Header>
              <Table.Row>
                {bonusEvents.length && 
                  <Table.HeaderCell colSpan='9'>
                  <div >
                  <Header as='h3' textAlign='center' style={{color: 'red'}}>
                    NOTE: Bonus Filtering is currently in a buggy state. This is being worked on. Stay tuned. 
                  </Header>
                  </div>
                </Table.HeaderCell>
                }
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Entry</Table.HeaderCell>
                <Table.HeaderCell>Week</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Game</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Team</Table.HeaderCell>
                <Table.HeaderCell>Details</Table.HeaderCell>
                {
                  dollars !== 'Prizes' ? <Table.HeaderCell>Winners</Table.HeaderCell> : null
                }
                {usersSelected.length === 1 && !allUsersOn ?
                  <Table.HeaderCell style={{paddingTop: 5, paddingBottom: 0, fontSize: 18, fontWeight: 600}}>
                    <span style={bonusTotal<0 ? {color: '#CF2E2E'} : null}>
                      {formatDollars(bonusTotal, 2)}
                    </span>
                    <span style={{fontSize: 8, paddingLeft: 5}}>TOTAL</span>
                  </Table.HeaderCell>
                  :
                  <Table.HeaderCell>$</Table.HeaderCell>
                }
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <BonusRows bonusEvents={bonusEvents} dollars={dollars} game={activePool.game}/>
            </Table.Body>
          </Table>
          {!bonusEvents.length ?
            <div className="picks-hidden">
              <Icon name='rocket' size='massive' />
              {!this.state.allUsersOn ||
              !this.state.allWeeksOn ||
              !this.state.allTeamsOn ||
              !this.state.allTypesOn ?
                <div className="picks-hidden">Use the filters and dropdowns to view this pool's Performance Bonuses.</div>
              :
                <div className="picks-hidden">Bonuses will show up here once they have been earned.</div>
              }
            </div>
          : null}
        </div>
      )
    } else {
      return <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>
    }
  }
}

function mapStateToProps ({ bonusEvents, alphTeams, activeGameWeek, gameEntrants, activePool }) {
  return ({ bonusEvents, alphTeams, activeGameWeek, gameEntrants, activePool })
}

export default connect(mapStateToProps)(PerfBonus);
