import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { requestLeagueAccess } from '../../actions';
import { Grid, Header, Icon, Table, Image, Button } from 'semantic-ui-react';
import moment from 'moment';
import AuthModal from '../AuthModal';

function FindLeague ({ allLeagues, auth, requestLeagueAccess }) {
  function joinLeagueReq (league) {
    requestLeagueAccess({
      league_id: league.id,
      league_abb: league.abb,
      league_name: league.name,
      joinable: league.joinable,
      user_id: auth[0].id,
      username: auth[0].username,
      full_name: `${auth[0].nameFirst} ${auth[0].nameLast}`,
      email: auth[0].email,
      source: 'joinleague'
    });
  }

  function renderLeagues () {
    return allLeagues.map(league => {
      const userInLg = auth ? (auth[0].leagues.find(l => l.league_id === league.id) || false) : false;
      return (
        <Table.Row key={league.id}>
          <Table.Cell>
            <Header as='h4' image>
              <Image src={league.logo} />
              <Header.Content>
                <a href={`/league/${league.abb}`}>{league.name}</a>
                <Header.Subheader><i>founded {moment(league.created_at).format('YYYY.MM.DD')}</i></Header.Subheader>
              </Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell> {league.founder} </Table.Cell>
          <Table.Cell textAlign='center'>  {league.members.length} </Table.Cell>
          <Table.Cell textAlign='center'>  {league.seasons.length} </Table.Cell>
          <Table.Cell> {league.joinable ? 'Public' : 'Private' }  </Table.Cell>
          <Table.Cell> {league.hidden ? 'Hidden' : 'Public' }  </Table.Cell>
          <Table.Cell> {userInLg ?
              <i> You're a member of this league. </i>
            :
            <>
              {league.hidden ?
                <>
                  {!auth ?
                    <> <AuthModal /> to request access. </>
                  :
                    <>
                      {league.requests.map(member => member.user_id).includes(auth[0].id) ?
                        <Button className='disabled'>Request Pending</Button>
                        :
                        <Button className='lg-btn' onClick={() => joinLeagueReq(league)}>REQUEST TO JOIN</Button>
                      }
                    </>
                  }
                </>
              :
                <Link className='purple-text' to={`/league/${league.abb}`}> <b>League Homepage</b> </Link>
              }
            </>
          }
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  if (auth !== null && allLeagues.length) {
    return (
      <div style={{minHeight: 600}}>
        <Grid style={{marginBottom: 20, marginTop: 5}}>
          <Grid.Row className='page-header'>
            <Grid.Column width={12}>
              <Header as='h2'>
                <Icon name='find' />
                <Header.Content>
                  Find a League
                  <Header.Subheader>
                    - Here you'll find a list of Survivor Leagues currently operating on the ALA platform. <br />
                    - Public leagues can be joined by visiting a league's homepage. Private leagues require approval from a League Commissioner. <br />
                    - Requests to join leagues can be viewed on this page and managed through your <Link to='/profile'>User Profile</Link> page.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign={'right'} className='purple-text' style={{fontSize: 20}}>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell> League Name </Table.HeaderCell>
              <Table.HeaderCell> Commissioner </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'> Members </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'> Pools Hosted </Table.HeaderCell>
              <Table.HeaderCell> Membership </Table.HeaderCell>
              <Table.HeaderCell> Visibility </Table.HeaderCell>
              <Table.HeaderCell> Want to Join? </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {renderLeagues()}
          </Table.Body>
        </Table>
      </div>
    )
  }

}

function mapStateToProps ({ allLeagues, auth }) {
  return { allLeagues, auth }
}

export default connect (mapStateToProps, {requestLeagueAccess}) (FindLeague)
