import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, useParams } from 'react-router-dom';
import { Dropdown, Tab, Icon, Loader } from 'semantic-ui-react';
import Header from './Header';
import { MenuPanes } from './pool/Home/MenuPanes';
import lillard from '../images/lillard_game_winner.jpg';
import harper from '../images/harper_hr.jpg';
import lillardWp from '../images/lillard-wp.jpg';
import yankeeWp from '../images/yankee-wp.jpg';
import {
  clearLeague,
  clearPool,
  fetchLeague,
  fetchPayments,
  fetchSeasonParams,
  fetchTeamDetails,
  fetchTeamResults,
  fetchTeamSchedules,
  getBonusOptions,
  loadActivePool,
  setActiveEntry
} from '../actions';
import { ACTIVE_ENTRIES } from '../constants/localStorageKeys';
import './styles/nba-landing.scss';
import './styles/game-home.scss';

const gameTheme = {
  'nba': {
    "--color-main": "#D06C35",
    '--color-light': '#C77F5D'
  },
  'mlb': {
    '--color-main': '#042F70',
    '--color-light': '#355FAC'
  },
  'default': {
    '--color-main': '#184271'
  }
}

const Pool = ({ 
  activeGameWeek,
  activeEntries,
  activePool, 
  allLeagues,
  auth, 
  deadlines,
  fetchLeague,
  fetchPayments,
  fetchSeasonParams,
  fetchTeamDetails,
  fetchTeamResults,
  fetchTeamSchedules,
  gameEntrants,
  getBonusOptions,
  league,
  loadActivePool,
  prizes,
  setActiveEntry
}) => {
  const { game, leagueAbb, season, poolidx, type } = useParams();
  const [activeSeason, setActiveSeason] = useState(season);
  const [activeLeague, setActiveLeague] = useState(leagueAbb);
  const [activeType, setActiveType] = useState(type);
  const [activePoolIdx, setActivePoolIdx] = useState(poolidx);

  function zoomOutMobile() {
    var viewport = document.querySelector('meta[name="viewport"]');

    if ( viewport ) {
      viewport.content = "initial-scale=0.5";
    }
  }
  zoomOutMobile();

  useEffect(() => {
    Object.keys(gameTheme[game]).forEach(key => {
      const value = gameTheme[game][key];
      document.documentElement.style.setProperty(key, value);
    });

    if (!leagueAbb) {setActiveLeague('alevelabove')}
    if (!season) {setActiveSeason(2019)};
    if (!poolidx) {setActivePoolIdx(0)};
    if (!type) {setActiveType('reg')};

    if (game && activeLeague && activeSeason && activePoolIdx) {
      loadActivePool(game, activeSeason, activeLeague, activePoolIdx, activeType);
      fetchTeamSchedules(game, activeSeason, activeType);
      fetchTeamDetails(game); // n/a
      fetchTeamResults(game, activeSeason, activeType); // n/a
      fetchPayments(game, activeSeason, activeLeague, activePoolIdx, activeType);
      fetchSeasonParams(game, activeSeason, activeType);
      fetchLeague('abb', activeLeague);
      getBonusOptions(game);
    }
  }, [game, leagueAbb, season, poolidx, type])

  useEffect(() => {
    if (activePool) {
      const activeEntriesStored = localStorage.getItem(ACTIVE_ENTRIES);
      const activeEntries = activeEntriesStored ? JSON.parse(activeEntriesStored) : null;
      if (!activeEntries || !activeEntries.filter(e => e.leagueSeasonId === activePool.id).length) {
        setActiveEntry({leagueSeasonId: activePool.id, entryNum: 1});
      } else {
        const activeEntry = activeEntries.filter(e => e.leagueSeasonId === activePool.id)[0];
        const { leagueSeasonId, entryNum } = activeEntry;
        localStorage.setItem(ACTIVE_ENTRIES, JSON.stringify([
          ...activeEntries.filter(e => e.leagueSeasonId !== leagueSeasonId),
          {leagueSeasonId, entryNum}
        ]));
        setActiveEntry({leagueSeasonId, entryNum});
      }
    }
  }, [activePool])

  if (auth !== null && activePool !== null && deadlines !== null && gameEntrants !== null && league !== null && allLeagues !== null && activeGameWeek !== null)  {
    const userPoolEntries = gameEntrants?.filter(e => e.user_id === auth[0]?.id)
      .sort((a, b) => a.entry_num - b.entry_num);

    const bannerOptions = userPoolEntries?.map((e) => {
      const concatenatedUser = `${auth[0].username} ${e.entry_num > 1 ? `[${e.entry_num}] ` : ''}`
      return {
        key: concatenatedUser,
        text: concatenatedUser,
        value: e.entry_num
      }
    });

    return (
      <div>
        <img src={game === 'nba' ? lillard : harper} className="home-bg" alt={game}></img>
        <Header />
        <div className="master-container">
          <div className="game-susp">
            <Icon name="warning sign"/>
            {activePool.league_abb === 'alevelabove' ?
              <> You're viewing the homepage for the global ALA {activePool.game.toUpperCase()} Survivor Pool, which is open to the public. </>
            : <>
                You're viewing the homepage for {league.info.name}'s {activePool.season} {activePool.game.toUpperCase()} Survivor Pool. To navigate to ALA's global {activePool.season} {activePool.game.toUpperCase()} Survivor Pool, <a href={`/${activePool.game}/alevelabove/${activePool.season}/${activePool.season_type}/${activePool.pool_idx}`}>click here</a>.  </> 
            }
            <Icon name="warning sign"/>
          </div>
          {userPoolEntries.length > 1 && <div className="active-entry-container">
              <div className="active-entry-label">Active Entry:</div>  
              <Dropdown
                value={activeEntries.find(e => e.leagueSeasonId === activePool.id).entryNum}
                selection
                options={bannerOptions}
                onChange={(e, {value}) => setActiveEntry({leagueSeasonId: activePool.id, entryNum: value})}
              />
            </div>}
          <div className="nba-banner">
            <img src={activePool.game === 'nba' ? lillardWp : yankeeWp} alt='dameWp' className={`${activePool.game}-wp`}/>
            <div className="banner-text">{activePool.game.toUpperCase()} {activeType === 'reg' ? 'SURVIVOR' : '[PRE] SURVIVOR'} {activePool.season}</div>
          </div>
          <Switch>
            <Tab 
              renderActiveOnly={false} 
              activeIndex={-1} 
              panes={
                MenuPanes({
                  activeLeague,
                  activePool,
                  activePoolIdx,
                  activeSeason,
                  activeType,
                  auth,
                  game,
                  gameEntrants,
                  league,
                  prizes
                })  
              } 
              className="nba-menu" 
            />
          </Switch>
        </div>
      </div>
    )
  } else {
    return (
      <div className="master-container">
        <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>
      </div>
    )
  }
}

function mapStateToProps ({ auth, activeEntries, activePool, deadlines, gameEntrants, prizes, league, allLeagues, activeGameWeek }) {
  return { auth, activeEntries, activePool, deadlines, gameEntrants, prizes, league, allLeagues, activeGameWeek }
}

export default connect(mapStateToProps, {
  fetchTeamSchedules,
  loadActivePool,
  fetchTeamDetails,
  fetchTeamResults,
  fetchPayments,
  fetchSeasonParams,
  fetchLeague,
  getBonusOptions,
  setActiveEntry
} )(Pool);
