import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Header, Checkbox, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { registerForGame, setJoinModalVis } from '../../actions';
import { formatDollars } from '../../utils';
import NBABonusExample from '../howtoplay/nbaBonusExample';
import MLBBonusExample from '../howtoplay/mlbBonusExample';
import { INSURANCE_OPTION_TEXT } from '../pool/Home/constants';

function JoinPoolModal ({ auth, activePool, league, joinModalVis, registerForGame, setJoinModalVis, userPoolEntries }) {
  const [userBonus, setUserBonus] = useState(false);
  const [userInsurance, setUserInsurance] = useState(false);

  function registerUser (g) {
    setJoinModalVis(false);
    const info = {
      user_id: auth[0].id,
      username: auth[0].username,
      bonus: userBonus,
      insurance: userInsurance ? activePool.insurance : 0,
      insurance_purchased: userInsurance,
      admin: JSON.parse(league.info.admins).includes(auth[0].username),
      league_name: league.info.name,
      real_name: `${auth[0].nameFirst} ${auth[0].nameLast}`,
      email: auth[0].email,
      photo_url: auth[0].photo_url,
      activePool,
      existingEntries: userPoolEntries || 0
    };
    registerForGame(info);
  }

  return (
    <Modal size="small" centered open={joinModalVis} onClose={() => setJoinModalVis(false)} className="game-reg-modal">
    <Header as='h2' icon className="confirm-header">
      <div className='modal-header-bar'> - Confirm Registration - </div>
      <b>{league.info.name}{league.info.name.slice(-1) === 's' ? "'" : "'s"}</b> {`${activePool.season}`} {activePool.game.toUpperCase()} Survivor Pool
      <div className="modal-header-meta">
        {activePool.season_type === 'pre' ?
          <><span className="header-meta">PRESEASON POOL </span> | </>
         : null}
        <span className="header-meta">BUY-IN: ${activePool.buyin} </span> |
        <span className="header-meta">LIVES: {activePool.rebuys + 1} </span> |
        <span className="header-meta">RE-BUYS: {activePool.rebuys > 0 ? `$${activePool.rebuy_price}` : 'n/a'} </span> |
        <span className="header-meta">BONUS POOL: {activePool.bonus_active ? "Active" : "n/a"} </span></div>
    </Header>
      <Modal.Content>
        {activePool.bonus_active ?
          <div style={activePool.insurance ? {marginBottom: 10, paddingBottom: 10, borderBottom: 'dashed 1px #e5e0f6'} : null}>
            <h3> Opt-in to Performance Bonus Pool? </h3>
            <div className="modal-meta"> This pool features an optional bonus pool component. See this pool's <b><Link onClick={() => setJoinModalVis(false)} to={`/${activePool.game}/${league.info.abb}/${activePool.season}/${activePool.season_type}/${activePool.pool_idx}/howtoplay`}>How to Play</Link></b> page for more details.</div>
            <p><b>Performance Bonus Pools</b> award prizes to participating entrants for outstanding team or player achievements from their weekly Survivor selection, and are collectively paid out by the other entrants who have opted-in to the Peformance Bonus Pool.</p><p> {activePool.game === 'nba' ? <NBABonusExample/> : <MLBBonusExample />} </p>
            <p>For complete details, potential bonuses, terms, and conditions with respect to this particular pool's Bonus Pool, please see this pool's <b><Link onClick={() => setJoinModalVis(false)} to={`/${activePool.game}/${league.info.abb}/${activePool.season}/${activePool.season_type}/${activePool.pool_idx}/howtoplay`}>How to Play</Link></b> page.</p>
            <Checkbox
              className="modal-check"
              label='Opt-in to Performance Bonus Pool (you can also decide later)'
              onClick={() => setUserBonus(!userBonus)}
              checked={userBonus}
            />
          </div>
        : null}
        {activePool.insurance ?
          <>
            <h3> Purchase Insurance? </h3>
            <div className="modal-meta"> This pool offers entrants the option to purchase insurance in case they forget to make a pick.</div>
            <p>An <b>insurance policy</b> is a one-time cost and covers an entrant for the remainder of a season after it is purchased. If an entrant purchases an insurance policy and forgets to make a pick, that entrant will be assigned {INSURANCE_OPTION_TEXT[activePool.insurance_option]} pick from their pool that week that they still have available for selection. Proceeds from insurance policies are included in a pool's prize totals.</p>
            <Checkbox
              className="modal-check"
              label={`Purchase Insurance for ${formatDollars(activePool.insurance, 2)}? (you can also decide later; insurance cannot be refunded once purchased)`}
              onClick={() => setUserInsurance(!userInsurance)}
              checked={userInsurance}
            />
          </>
        : null}
        {!activePool.insurance && !activePool.bonus_active ?
          <div style={{fontSize: 18}}> Please confirm your intention to register{userPoolEntries > 0 ? " an additional entry" : ''} for this pool. </div>
        : null}
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => setJoinModalVis(false)}>
          Back
        </Button>
        <Button primary onClick={() => registerUser()}>
          <Icon name='check circle' /> REGISTER for {activePool.game.toUpperCase()} SURVIVOR
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function mapStateToProps ({ joinModalVis }) {
  return ({ joinModalVis })
}

export default connect (mapStateToProps, {registerForGame, setJoinModalVis}) (JoinPoolModal);
