export const PAY_TYPES = [
  {key: 'payment', text: 'payment from', value: 'payment'},
  {key: 'prize', text: 'prize paid to', value: 'prize'}
]

export const FORM_ERRORS = {
  NO_USERNAME: "You didn't include a username.",
  NO_AMOUNT: "You didn't include an amount.",
  NO_TRANSACTION_TYPE: "You didn't include a transaction type."
}

export const FORM_INPUTS = {
  PLACEHOLDERS: {
    PAY_TYPE: 'payment from/prize paid to',
    NOTES: 'payment notes/memo'
  },
  LABELS: {
    PAY_TYPE: 'transaction type',
    NOTES: 'notes (optional, visible to user)'
  }
}