import React from 'react';
import { Table, Image, Popup, Grid, Label, Icon } from 'semantic-ui-react';
import MLBBonusBox from './MLBBonusBox';
import logos from '../../modules/logos';
import "../styles/selections.scss";
import "../styles/team-schedules.scss";
import "../styles/diamond.scss";

const style = {
  fontSize: 14,
  width: 350,
  padding: 0
}

const RenderWeekSchedule = (props) => {
  if (props.team.length > 1 && props.team !== 'xxx') {
    if (props.activeGame === 'mlb') {
      // console.log('props.team is ', props.team, ' and props.week is ', props.week, 'and props.schedule is', props.schedule)
      return (
        props.schedule[(props.week)-1].map((dayArray, idx) => {
          if (dayArray.length === 1) {
            const game = dayArray[0];
            if (game.status !== 'inactive' && game.status && game.stats) {
              let vColor = props.teamDetails[game.vAbb] ? props.teamDetails[game.vAbb]['color_one'] : null;
              let hColor = props.teamDetails[game.hAbb] ? props.teamDetails[game.hAbb]['color_one'] : null;
              return <MLBBonusBox
                key={`${dayArray[0].id}_${idx}`} idx={idx} style={style} props={props} game={game}
                vColor={vColor} hColor={hColor}
                />
            } else {
              return (
                <Table.Cell key={`${dayArray[0].id}_${idx}`} className={`schedGridTeam ${props.activeGame}_cell ${dayArray[0].statusColor}`}>
                  <b>{dayArray[0].matchup}</b>
                  <Image src={logos[`${props.activeGame}_${dayArray[0].matchup.split(" ")[1]}`]} />
                  {dayArray[0].status !== 'inactive' ? <div className="result-text"> <a href={`https://www.mlb.com/gameday/${dayArray[0].gid}`} target="_blank" rel="noopener noreferrer"> {dayArray[0].result} </a> <div className="sched-game-status">{dayArray[0].status !== 'Final' ? dayArray[0].status : dayArray[0].status}</div></div> : null}
                </Table.Cell>
              )
            }
          } else if (dayArray.length === 2) {
            const gameOne = dayArray[0];
            const gameTwo = dayArray[1];
            const vColorGameOne = props.teamDetails[gameOne.vAbb] ? props.teamDetails[gameOne.vAbb]['color_one'] : null;
            const hColorGameOne = props.teamDetails[gameOne.hAbb] ? props.teamDetails[gameOne.hAbb]['color_one'] : null;
            const vColorGameTwo = props.teamDetails[gameTwo.vAbb] ? props.teamDetails[gameTwo.vAbb]['color_one'] : null;
            const hColorGameTwo = props.teamDetails[gameTwo.hAbb] ? props.teamDetails[gameTwo.hAbb]['color_one'] : null;
            return (
              <Table.Cell key={`${dayArray[0].id}_${idx}_${props.team}`} className={`schedGridTeam ${props.activeGame}_cell two-games`}>
                <div className="two-game-container">
                  <div className={`game-one ${gameOne.statusColor}`} key={`${dayArray[0].id}_idx_01`}>
                    {gameOne.status !== 'inactive' && gameOne.status && gameOne.stats ?
                    <MLBBonusBox
                      key={`${idx}_01`} style={style} props={props} game={gameOne}
                      vColor={vColorGameOne} hColor={hColorGameOne}
                      />
                      :
                      <>
                        <b>{gameOne.matchup}</b>
                        <Image src={logos[`${props.activeGame}_${gameOne.matchup.split(" ")[1]}`]} />
                        {gameOne.status !== 'inactive' ? <div className="result-text"> <a href={`https://www.mlb.com/gameday/${gameOne.gid}`} target="_blank" rel="noopener noreferrer"> {gameOne.result} </a> <div className="sched-game-status">{gameOne.status !== 'Final' ? gameOne.status : gameOne.status}</div></div> : null}
                      </>
                    }
                  </div>
                  <div className={`game-two ${gameTwo.statusColor}`} key={`${dayArray[0].id}_idx_02`}>
                    {gameTwo.status !== 'inactive' && gameTwo.status && gameTwo.stats ?
                    <MLBBonusBox
                      key={`${idx}_02`} style={style} props={props} game={gameTwo}
                      vColor={vColorGameTwo} hColor={hColorGameTwo}
                      />
                      :
                      <>
                        <b>{gameTwo.matchup}</b>
                        <Image src={logos[`${props.activeGame}_${gameTwo.matchup.split(" ")[1]}`]} />
                        {gameTwo.status !== 'inactive' ? <div className="result-text"> <a href={`https://www.mlb.com/gameday/${gameTwo.gid}`} target="_blank" rel="noopener noreferrer"> {gameTwo.result} </a> <div className="sched-game-status">{gameTwo.status !== 'Final' ? gameTwo.status : gameTwo.status}</div></div> : null}
                      </>
                    }
                  </div>
                </div>
              </Table.Cell>
            )
          } else {
            return (<Table.Cell key={idx}></Table.Cell>)
          }
        })
      )
    } else if (props.activeGame === 'nba') {
      return (
        props.schedule[(props.week)-1].map((game, idx) => {
          if (game.matchup) {
            if (game.status !== 'inactive' && game.status !== 'PPD' && game.status) {
              let vColor = props.teamDetails[game.vAbb] ? props.teamDetails[game.vAbb]['color_one'] : null;
              let hColor = props.teamDetails[game.hAbb] ? props.teamDetails[game.hAbb]['color_one'] : null;
              return (
                <Popup key={`${idx}_${game.id}`} flowing hoverable style={style} className="bonus-box"
                  trigger = {
                    <Table.Cell key={`${game.id}_${idx}`} className={`schedGridTeam ${props.activeGame}_cell ${game.statusColor}`}>
                      <b>{game.matchup}</b>
                      <Image src={logos[`${props.activeGame}_${game.matchup.split(" ")[1]}`]}/>
                      {game.status !== 'inactive' ? <div className="result-text"> <a href={`https://nba.com/game/${game.vAbb}-vs-${game.hAbb}-00${game.gid}/box-score`} target="_blank" rel="noopener noreferrer"> {game.result} </a> <div className="sched-game-status">{game.status !== 'inactive' ? <span>{game.status} {(props.team === game.vAbb && game.stats.vBonus) || (props.team === game.hAbb && game.stats.hBonus) ? <Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label> : null}</span> : <div></div>}</div></div> : null}
                    </Table.Cell>
                  }
                >
                <Popup.Content>
                  <Grid celled='internally'>
                    <Grid.Row style={{backgroundColor: '#EFF2F4'}}>
                      <Grid.Column width={16} className="bonus-box-head">
                        <span style={{color: vColor}}>BONUS</span> <span style={{color: hColor}}>BOX</span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{backgroundColor: '#EFF2F4'}}>
                      <Grid.Column width={5} textAlign='center' style={{backgroundColor: vColor, color: 'white'}}>
                        <Image className='popup-logo' src={logos[`${props.activeGame}_${game.vAbb}`]} /> <b>{game.vAbb}</b>
                      </Grid.Column>
                      <Grid.Column width={6} textAlign='center' style={{fontSize: 22, fontWeight: 600, paddingTop: 10, backgroundColor: '#F9FAFB', verticalAlign: 'center'}}>
                        <div style={{marginTop: 5}}><span style={{color: vColor}}>{game.stats.vScore}</span> - <span style={{color: hColor}}>{game.stats.hScore}</span></div> <div style={{fontSize: 8}}> {game.status} </div>
                      </Grid.Column>
                      <Grid.Column width={5} textAlign='center' style={{backgroundColor: hColor, color: 'white'}}>
                        <Image className='popup-logo' src={logos[`${props.activeGame}_${game.hAbb}`]} /> <b>{game.hAbb}</b>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} textAlign='center'>
                        <span>{game.stats.vHighPts.total >= 50 ? <Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label> : null}{game.stats.vHighPts.name} <span style={{color: vColor}}><b>{game.stats.vHighPts.total}</b></span></span>
                      </Grid.Column>
                      <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                        POINTS
                      </Grid.Column>
                      <Grid.Column width={5} textAlign='center'>
                        <span>{game.stats.hHighPts.total >= 50 ? <Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label> : null}{game.stats.hHighPts.name} <span style={{color: hColor}}><b>{game.stats.hHighPts.total}</b></span></span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.vHighReb.name} <span style={{color: vColor}}><b>{game.stats.vHighReb.total}</b></span>
                      </Grid.Column>
                      <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                        REBOUNDS
                      </Grid.Column>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.hHighReb.name} <span style={{color: hColor}}><b>{game.stats.hHighReb.total}</b></span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.vHighAst.name} <span style={{color: vColor}}><b>{game.stats.vHighAst.total}</b></span>
                      </Grid.Column>
                      <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                        ASSISTS
                      </Grid.Column>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.hHighAst.name} <span style={{color: hColor}}><b>{game.stats.hHighAst.total}</b></span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.vHighStl.name} <span style={{color: vColor}}><b>{game.stats.vHighStl.total}</b></span>
                      </Grid.Column>
                      <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                        STEALS
                      </Grid.Column>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.hHighStl.name} <span style={{color: hColor}}><b>{game.stats.hHighStl.total}</b></span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.vHighBlk.name} <span style={{color: vColor}}><b>{game.stats.vHighBlk.total}</b></span>
                      </Grid.Column>
                      <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                        BLOCKS
                      </Grid.Column>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.hHighBlk.name} <span style={{color: hColor}}><b>{game.stats.hHighBlk.total}</b></span>
                      </Grid.Column>
                    </Grid.Row>
                    {/* <Grid.Row>
                      <Grid.Column width={5} textAlign='center'>
                        <span style={{color: vColor}}><b>{game.stats.vLongRun}</b></span> - 0
                      </Grid.Column>
                      <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                        BEST RUN
                      </Grid.Column>
                      <Grid.Column width={5} textAlign='center'>
                        <span style={{color: hColor}}>{game.stats.hLongRun >= 20 ? <Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label>: null}<b>{game.stats.hLongRun}</b></span> - 0
                      </Grid.Column>
                    </Grid.Row> */}
                    <Grid.Row>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.vTripDub.length > 0 ?
                          <div><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label>{game.stats.vTripDub[0].name.split(" ")[1]}<div style={{color: vColor}}><b> {Object.values(game.stats.vTripDub[0].tripleStats[0])}{Object.keys(game.stats.vTripDub[0].tripleStats[0]).toString().slice(0, 1).toUpperCase()}-{Object.values(game.stats.vTripDub[0].tripleStats[1])}{Object.keys(game.stats.vTripDub[0].tripleStats[1]).toString().slice(0, 1).toUpperCase()}-{Object.values(game.stats.vTripDub[0].tripleStats[2])}{Object.keys(game.stats.vTripDub[0].tripleStats[2]).toString().slice(0, 1).toUpperCase()}</b></div></div>
                          : null}
                      </Grid.Column>
                      <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                        TRIPLE-DOUBLE?
                      </Grid.Column>
                      <Grid.Column width={5} textAlign='center'>
                      {game.stats.hTripDub.length > 0 ?
                        <div><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label>{game.stats.hTripDub[0].name.split(" ")[1]}<div style={{color: hColor}}><b> {Object.values(game.stats.hTripDub[0].tripleStats[0])}{Object.keys(game.stats.hTripDub[0].tripleStats[0]).toString().slice(0, 1).toUpperCase()}-{Object.values(game.stats.hTripDub[0].tripleStats[1])}{Object.keys(game.stats.hTripDub[0].tripleStats[1]).toString().slice(0, 1).toUpperCase()}-{Object.values(game.stats.hTripDub[0].tripleStats[2])}{Object.keys(game.stats.hTripDub[0].tripleStats[2]).toString().slice(0, 1).toUpperCase()}</b></div></div>
                        : null}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.vTwentys.length > 0 ?
                          <div><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label>{game.stats.vTwentys[0].name.split(" ")[1]}<div style={{color: vColor}}><b> {Object.values(game.stats.vTwentys[0].twentyStats[0])}{Object.keys(game.stats.vTwentys[0].twentyStats[0]).toString().slice(0, 1).toUpperCase()}-{Object.values(game.stats.vTwentys[0].twentyStats[1])}{Object.keys(game.stats.vTwentys[0].twentyStats[1]).toString().slice(0, 1).toUpperCase()}</b></div></div>
                          : null}
                      </Grid.Column>
                      <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                        20-20?
                      </Grid.Column>
                      <Grid.Column width={5} textAlign='center'>
                      {game.stats.hTwentys.length > 0 ?
                        <div><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label>{game.stats.hTwentys[0].name.split(" ")[1]}<div style={{color: hColor}}><b> {Object.values(game.stats.hTwentys[0].twentyStats[0])}{Object.keys(game.stats.hTwentys[0].twentyStats[0]).toString().slice(0, 1).toUpperCase()}-{Object.values(game.stats.hTwentys[0].twentyStats[1])}{Object.keys(game.stats.hTwentys[0].twentyStats[1]).toString().slice(0, 1).toUpperCase()}</b></div></div>
                        : null}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.vLongRun >= 20 ? <span style={{color: vColor}}><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label><b>20+ PT RUN!</b></span>: null}
                        {game.stats.vScore >= 140 && game.stats.periods < 5 ? <span style={{color: vColor}}><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label><b>140+ PTS!</b></span>: null}
                        {game.stats.vScore - game.stats.hScore >= 30 ? <span style={{color: vColor}}><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label><b>30+ PT WIN!</b></span>: null}
                        {(game.stats.vScore > game.stats.hScore) && game.buzzerBeater ? <span style={{color: vColor}}><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label><b>BUZZER-BEATER!</b></span>: null}
                        {(game.stats.vScore > game.stats.hScore) && game.periods > 5 ? <span style={{color: vColor}}><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label><b>2-OT+ WIN!</b></span>: null}
                      </Grid.Column>
                      <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                        TEAM BONUSES?
                      </Grid.Column>
                      <Grid.Column width={5} textAlign='center'>
                        {game.stats.hLongRun >= 20 ? <span style={{color: hColor}}><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label><b>20+ PT RUN!</b></span>: null}
                        {game.stats.hScore >= 140 && game.stats.periods < 5 ? <span style={{color: hColor}}><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label><b>140+ PTS!</b></span>: null}
                        {(game.stats.hScore - game.stats.vScore >= 30) && game.status === 'Final' ? <span style={{color: hColor}}><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label><b>30+ PT WIN!</b></span>: null}
                        {(game.stats.hScore > game.stats.vScore) && game.buzzerBeater ? <span style={{color: hColor}}><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label><b>BUZZER-BEATER!</b></span>: null}
                        {(game.stats.hScore > game.stats.vScore) && game.periods > 5 && game.status === 'Final' ? <span style={{color: hColor}}><Label circular className="sched-bonus-label" color='orange'><Icon name='rocket' className='bonus-icon'/></Label><b>2-OT+ WIN!</b></span>: null}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Popup.Content>
                </Popup>
              )
            } else {
              return (
                <Table.Cell key={`${game.id}_${idx}`} className={`schedGridTeam ${props.activeGame}_cell ${game.statusColor}`}>
                  <b>{game.matchup}</b>
                  <Image src={logos[`${props.activeGame}_${game.matchup.split(" ")[1]}`]} />
                  {game.status !== 'inactive' ? <div className="result-text"> <a href={`https://nba.com/game/${game.vAbb}-vs-${game.hAbb}-00${game.gid}/box-score`} target="_blank" rel="noopener noreferrer"> {game.result} </a> <div className="sched-game-status">{game.status !== 'Final' ? game.status : game.status}</div></div> : null}
                </Table.Cell>
              )
            }
          } else {
            return (<Table.Cell className={props.className} key={idx}></Table.Cell>)
          }
        })
      )
    }
  } else {
    let row = [];
    for (let i=0; i<7; i++) {
      row.push(<Table.Cell key={i}></Table.Cell>);
    };
    return row;
  }
}

export default RenderWeekSchedule;
