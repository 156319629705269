import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { connect } from 'react-redux';
import { Table, Icon, Button, Header, Transition, Modal, Popup, TextArea, Form, Dropdown, Checkbox, Grid, Label, Loader, Input } from 'semantic-ui-react';
import { SETTING_KEY, SETTING_NOTES } from './constants';
import { GAME } from '../../constants';
import { loadActivePool } from '../../actions';
import ContextPopup from '../other_helpers/ContextPopup';
import { toastSuccess, toastError } from '../Toast';
import { 
  BONUS_POOL_ACTIVE,
  BONUS_POOL_CONTEXT_HEADER,
  BONUS_POOL_CADENCE_CONTEXT_BODY,
  BONUS_POOL_ENTER_ANYTIME,
  BONUS_POOL_SHUTDOWN_CONTEXT_BODY,
  BONUS_POOL_DISABLED,
  BONUS_POOL_SUBHEADER,
  INSURANCE_CONTEXT_HEADER,
  INSURANCE_CONTEXT_BODY,
  INSURANCE_OPTIONS,
  OPT_IN_OPTIONS,
  SHOOT_MOON_ACTIVE,
  SHOOT_MOON_DISABLED
} from '../leagues/CreatePool.constants';
import './../styles/commish-tools.scss';

function PoolSettings ({ activePool, loadActivePool }) {
  const [poolSettings, setPoolSettings] = useState({
    [SETTING_KEY.BUYIN]: activePool.buyin,
    [SETTING_KEY.BUYIN_APPLY_RETRO]: true,
    [SETTING_KEY.MAX_ENTRIES]: activePool.max_entries,
    [SETTING_KEY.REBUYS]: activePool.rebuys,
    [SETTING_KEY.REBUY_PRICE]: activePool.rebuy_price,
    [SETTING_KEY.INSURANCE_OPTION]: activePool.insurance_option,
    [SETTING_KEY.INSURANCE_PRICE]: activePool.insurance,
    [SETTING_KEY.SHOOT_MOON]: activePool.shoot_moon,
    [SETTING_KEY.BONUS_POOL_ACTIVE]: activePool.bonus_active,
    [SETTING_KEY.BONUSES_DISABLED]: activePool.bonus_disabled,
    [SETTING_KEY.BONUS_CADENCE]: activePool.bonus_cadence,
    [SETTING_KEY.BONUS_SHUTDOWN]: activePool.bonus_shutdown,
    [SETTING_KEY.BONUS_OPTIONS]: JSON.parse(activePool.bonus_options)
  });
  const [settingsChanged, setSettingsChanged] = useState(false);

  const updatePoolSettings = async () => {
    const { game, season, league_abb, pool_idx, start_week, deadlines } = activePool;
    try {
      await axios.post('/admin/updatePoolSettings', {
        leagueSeasonId: activePool.id,
        poolSettings: {
          ...poolSettings,
          start_week,
          deadlines
        }
      });
      toastSuccess('Settings updated successfully', 'Your Pool settings have been updated successfully.');
      loadActivePool(game, season, league_abb, pool_idx);
      setSettingsChanged(false);
    } catch (err) {
      toastError('Error updating settings', 'There was an error updating your pool settings. Please try again or contact ALA support.');
    }
  }

  const handleSettingChange = (settingKey, value) => {
    setPoolSettings({...poolSettings, [settingKey]: value});
    setSettingsChanged(true);
  };

  const shootMoonStatus = poolSettings[SETTING_KEY.SHOOT_MOON] ? SHOOT_MOON_ACTIVE : SHOOT_MOON_DISABLED;
  const bonusPoolStatus = poolSettings[SETTING_KEY.BONUS_POOL_ACTIVE] ? BONUS_POOL_ACTIVE : BONUS_POOL_DISABLED;

  const buyInChanged = poolSettings.buyin !== activePool.buyin;
  const poolHasStarted = moment(activePool.start).diff(moment()) < 0;

  function setBonusActive (code) {
    const newOptions = { ...poolSettings[SETTING_KEY.BONUS_OPTIONS] };
    newOptions[code].active = !newOptions[code].active;
    setPoolSettings({...poolSettings, [SETTING_KEY.BONUS_OPTIONS]: newOptions});
    setSettingsChanged(true);
  }

  function setBonusPrize (code, value) {
    const newOptions = { ...poolSettings[SETTING_KEY.BONUS_OPTIONS] };
    newOptions[code].prize = parseInt(value);
    setPoolSettings({...poolSettings, [SETTING_KEY.BONUS_OPTIONS]: newOptions});
    setSettingsChanged(true);
  }

  const renderBonusOptions = () => {
    return Object.keys(poolSettings[SETTING_KEY.BONUS_OPTIONS]).map(b => {
      return (
        <Table.Row key={b} className={poolSettings[SETTING_KEY.BONUS_OPTIONS][b].active ? null : 'inactive-bonus'}>
          <Table.Cell> 
            <Checkbox toggle checked={poolSettings[SETTING_KEY.BONUS_OPTIONS][b].active ? true : false} onChange={() => setBonusActive(b)} /> 
          </Table.Cell>
          <Table.Cell> 
            <Input disabled={!poolSettings[SETTING_KEY.BONUS_OPTIONS][b].active} 
            label='$'
            value={poolSettings[SETTING_KEY.BONUS_OPTIONS][b].prize}
            onChange={(e, {value}) => setBonusPrize(b, value)}
            className='bonus-option'/>
          </Table.Cell>
          <Table.Cell> {poolSettings[SETTING_KEY.BONUS_OPTIONS][b].bonus_long_name} </Table.Cell>
        </Table.Row>
      )
    })
  }

  const saveButton = () => 
    <Button color='green' icon labelPosition='left' disabled={!settingsChanged} size='large' onClick={() => updatePoolSettings()}>
      <Icon name='save' />
      SAVE SETTINGS
    </Button>

  return (
    <Grid className='commish-tools-cont'>
      <Grid.Row columns={16}>
        <Grid.Column width={13}>
          <div className="user-pg-header">League Settings</div>
          <Header size='medium'>Use this page to change your league's settings.</Header>
        </Grid.Column>
        <Grid.Column width={3}>
          {saveButton()}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Table style={{width: '98%', marginLeft: '1%'}}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>League Setting</Table.HeaderCell>
              <Table.HeaderCell width={5}>Your League</Table.HeaderCell>
              <Table.HeaderCell width={8}>Notes</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell><b>Initial Buy-in</b></Table.Cell>
              <Table.Cell>
                <Input 
                  label='$'
                  value={poolSettings.buyin}
                  onChange={(e, {value}) => handleSettingChange(SETTING_KEY.BUYIN, value)}
                />
                {buyInChanged &&
                  <Checkbox 
                    label='Apply retroactively'
                    checked={poolSettings.buyin_apply_retro}
                    onChange={(e, {checked}) => handleSettingChange(SETTING_KEY.BUYIN_APPLY_RETRO, checked)}
                    style={{marginLeft: '4%'}}
                  />
                }
              </Table.Cell>
              <Table.Cell>{buyInChanged ? SETTING_NOTES[SETTING_KEY.BUYIN] : null} </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><b>Extra Lives/Rebuys</b></Table.Cell>
              <Table.Cell>
                <Input 
                  value={poolSettings.rebuys}
                  onChange={(e, {value}) => handleSettingChange(SETTING_KEY.REBUYS, value)}
                  disabled={poolHasStarted}
                />
              </Table.Cell>
              <Table.Cell>{SETTING_NOTES[SETTING_KEY.REBUYS]}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><b>Rebuy Cost</b></Table.Cell>
              <Table.Cell>
                  <Input type="number"
                    value={poolSettings.rebuy_price}
                    onChange={(e, {value}) => handleSettingChange(SETTING_KEY.REBUY_PRICE, value)}
                    />  
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><b>Max Entries</b></Table.Cell>
              <Table.Cell>
                <Input type="number"
                  value={poolSettings.max_entries} 
                  onChange={(e, {value}) => handleSettingChange(SETTING_KEY.MAX_ENTRIES, value)}/>
              </Table.Cell>
              <Table.Cell>
                Changing Max Entries will not affect entrants who have already signed up for your pool. To deactivate existing entries, use the Manage Entrants page.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><div style={{position: 'relative'}}>
                  <b>Insurance</b>               
                  <ContextPopup
                  header={INSURANCE_CONTEXT_HEADER}
                  content={INSURANCE_CONTEXT_BODY}
                  trigger={<Icon className="context-icon purple-text" name='question circle' />}
                  style={{marginLeft: 2, marginTop: -2}}
                /></div>                  
              </Table.Cell>
              <Table.Cell>
                <Dropdown
                  style={{marginTop: 10}}
                  fluid
                  selection
                  options={INSURANCE_OPTIONS}
                  value={poolSettings.insurance_option}
                  onChange={(e, {value}) => handleSettingChange(SETTING_KEY.INSURANCE_OPTION, value)}
                />
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            {poolSettings.insurance_option > 0 && 
              <Table.Row>
                <Table.Cell><b>Insurance Cost</b></Table.Cell>
                <Table.Cell>
                  <Input 
                    label='$'
                    value={poolSettings[SETTING_KEY.INSURANCE_PRICE]}
                    onChange={(e, {value}) => handleSettingChange(SETTING_KEY.INSURANCE_PRICE, value)}
                  />
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            }
            {activePool.game === GAME.MLB && <Table.Row>
              <Table.Cell><b>Shoot the Moon?</b></Table.Cell>
              <Table.Cell>
                <Checkbox 
                  className="lg-toggle" 
                  toggle 
                  label={shootMoonStatus}
                  checked={poolSettings[SETTING_KEY.SHOOT_MOON]} 
                  onChange={(e, {checked}) => {handleSettingChange(SETTING_KEY.SHOOT_MOON, checked)}} />
              </Table.Cell>
              <Table.Cell>
                If Shoot the Moon is active and an entrant's selection loses every game they play that week (0-6 or worse), not only will they not lose a life, they will *gain* an additional life.
              </Table.Cell>
            </Table.Row>
            }
            {
            <Table.Row>
              <Table.Cell><Icon name='rocket' /><b>Active Bonus Pool?</b></Table.Cell>
              <Table.Cell>
                <Checkbox 
                  className="lg-toggle" 
                  toggle 
                  label={bonusPoolStatus} 
                  checked={poolSettings[SETTING_KEY.BONUS_POOL_ACTIVE]} 
                  onChange={(e, {checked}) => {handleSettingChange(SETTING_KEY.BONUS_POOL_ACTIVE, checked)}} />
              </Table.Cell>
              <Table.Cell>
                {BONUS_POOL_SUBHEADER()}
              </Table.Cell>
            </Table.Row>
            }
            {/* {poolSettings[SETTING_KEY.BONUS_POOL_ACTIVE] &&
              <Table.Row>
                <Table.Cell><b>Bonuses Disabled?</b></Table.Cell>
                <Table.Cell>
                  <Checkbox 
                    className="lg-toggle" 
                    toggle 
                    label={poolSettings[SETTING_KEY.BONUSES_DISABLED] ? 'Bonuses Disabled' : 'Bonuses Enabled'} 
                    checked={poolSettings[SETTING_KEY.BONUSES_DISABLED]} 
                    onChange={(e, {checked}) => {handleSettingChange(SETTING_KEY.BONUSES_DISABLED, checked)}} />
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
              </Table.Row>
            } */}
            {poolSettings[SETTING_KEY.BONUS_POOL_ACTIVE] &&
              <Table.Row>
                <Table.Cell>
                  <div style={{position: 'relative'}}>
                  <Icon name='rocket' />
                    <b>Bonus Pool Cadence</b>
                    <ContextPopup
                    header={BONUS_POOL_CONTEXT_HEADER}
                    content={BONUS_POOL_CADENCE_CONTEXT_BODY}
                    trigger={<Icon style={{marginLeft: 2, marginTop: -2}} className="context-icon purple-text" name='question circle' />}
                    />
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    fluid
                    selection
                    options={OPT_IN_OPTIONS}
                    value={poolSettings[SETTING_KEY.BONUS_CADENCE]}
                    onChange={(e, {value}) => handleSettingChange(SETTING_KEY.BONUS_CADENCE, value)}
                  />
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
              </Table.Row>
            }
            {poolSettings[SETTING_KEY.BONUS_POOL_ACTIVE] &&
              <Table.Row>
                <Table.Cell>
                  <div style={{position: 'relative'}}>
                  <Icon name='rocket' />
                    <b>Bonus Pool Shutdown #</b>
                    <ContextPopup
                    header={BONUS_POOL_CONTEXT_HEADER}
                    content={BONUS_POOL_SHUTDOWN_CONTEXT_BODY}
                    trigger={<Icon style={{marginLeft: 2, marginTop: -2}} className="context-icon purple-text" name='question circle' />}
                    />
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <Input 
                    type="number"
                    value={poolSettings[SETTING_KEY.BONUS_SHUTDOWN]}
                    onChange={(e, {value}) => handleSettingChange(SETTING_KEY.BONUS_SHUTDOWN, value)}
                  />
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
              </Table.Row>
            }
            {poolSettings[SETTING_KEY.BONUS_POOL_ACTIVE] && 
              <Table.Row>
                <Table.Cell><Icon name='rocket' /><b>Bonus Options</b></Table.Cell>
                <Table.Cell width={8}>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Active</Table.HeaderCell>
                        <Table.HeaderCell>Prize</Table.HeaderCell>
                        <Table.HeaderCell>Bonus</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {renderBonusOptions()}
                    </Table.Body>
                  </Table>
                </Table.Cell>
              </Table.Row>
            } 
          </Table.Body>
        </Table>
      </Grid.Row>
      <Grid.Row columns={1} textAlign='center'>
        <Grid.Column width={16} textAlign='center'>
          {saveButton()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default connect (null, {loadActivePool}) (PoolSettings)