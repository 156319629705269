import React from "react";
import { Route, NavLink } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import Home from './Home';
import CommishTools from '../CommishTools';
import HowToPlay from '../../HowToPlay';
import MakePicks from '../MakePicks';
import Standings from '../Standings/Standings';
import Scoreboard from '../Scoreboard';
import PerfBonus from '../../PerfBonus';
import Finances from '../Finances';

export const MenuPanes = ({ 
  activeLeague,
  activePool,
  activePoolIdx,
  activeSeason,
  activeType,
  auth,
  game,
  gameEntrants,
  league,
  prizes,
}) => {
  const panes = [
    {
      menuItem: {
        as: NavLink,
        id: "tab1",
        content: "Home",
        to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}`,
        exact: true,
        key: "home"
      },
      pane: (
        <Route
          key="game:home"
          exact
          path="/:game/:leagueAbb/:season/:type/:poolidx"
          render={() => (
            <Tab.Pane className="nba-content">
              <Home/>
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: "tab2",
        content: "How to Play",
        to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/howtoplay`,
        exact: true,
        key: "howtoplay"
      },
      pane: (
        <Route
          path="/:game/:leagueAbb/:season/:type/:poolidx/howtoplay"
          key="game:howtoplay"
          render={() => (
            <Tab.Pane className="nba-content how-to-play-tab">
              <HowToPlay activePool={activePool} auth={auth} league={league}/>
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: "tab3",
        content: "Make Picks",
        to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/makepicks`,
        exact: true,
        key: "makepicks"
      },
      pane: (
        <Route
          path="/:game/:leagueAbb/:season/:type/:poolidx/makepicks"
          key="game:makepicks"
          render={() => (
            <Tab.Pane className="nba-content">
              <MakePicks/>
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: "tab4",
        content: "Scoreboard",
        to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/scoreboard`,
        exact: true,
        key: "scoreboard"
      },
      pane: (
        <Route
          path="/:game/:leagueAbb/:season/:type/:poolidx/scoreboard"
          key="game:scoreboard"
          render={() => (
            <Tab.Pane className="nba-content">
              <Scoreboard game={game}/>
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: "tab5",
        content: "Standings",
        to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/standings`,
        exact: true,
        key: "standings"
      },
      pane: (
        <Route
          path="/:game/:leagueAbb/:season/:type/:poolidx/standings"
          key="game:standings"
          render={() => (
            <Tab.Pane className="nba-content">
              <Standings game={game}/>
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: "tab6",
        content: "Bonuses",
        to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/bonuses`,
        exact: true,
        key: "bonuses"
      },
      pane: (
        <Route
          path="/:game/:leagueAbb/:season/:type/:poolidx/bonuses"
          key="game:bonuses"
          render={() => (
            <Tab.Pane className="nba-content">
              <PerfBonus />
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: "tab7",
        content: "Finances",
        to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/finances`,
        exact: true,
        key: "finances"
      },
      pane: (
        <Route
          path="/:game/:leagueAbb/:season/:type/:poolidx/finances"
          key="game:finances"
          render={() => (
            <Tab.Pane className="nba-content">
              <Finances />
            </Tab.Pane>
          )}
        />
      )
    }
  ];

  if (auth && JSON.parse(league.info.admins).includes(auth[0].username)) {
    panes.push(
      {
        menuItem: {
          as: NavLink,
          id: "tab8",
          content: "Commish Tools",
          to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/commishtools`,
          exact: true,
          key: "commishtools"
        },
        pane: (
          <Route
            path="/:game/:leagueAbb/:season/:type/:poolidx/commishtools"
            key="game:commishtools"
            exact
            render={() => (
              <Tab.Pane className="nba-content">
                <CommishTools umbrella={'pool'} activePool={activePool} gameEntrants={gameEntrants} prizes={prizes}/>
              </Tab.Pane>
            )}
          />
        )
      }
    )
  }

  return panes;
}