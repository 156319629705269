import React, { useState } from 'react';
import { Grid, Header, Divider, Menu, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CreateAPool from './CreateAPool';
import JoinAPool from './JoinAPool';

function StartHere () {
  document.documentElement.style.setProperty('--color-main', '#c73230');
  const [activeItem, setActiveItem] = useState('join');

  return (
    <div style={{width: '96%', marginLeft: '2%', marginTop: 40, marginBottom: 60}}>
      <Grid className='get-started-grid-2'>
        <Grid.Row centered>
          <Header as='h2' icon>
            Get in the Game.
            <Header.Subheader>
              Want to create your own ALA Survivor Pool?
              Throw down in one of ALA's global Survivor competitions? <br/>
              Trying to find a friend's pool?
              Whatever you're after ... use this page as your guide.
            </Header.Subheader>
          </Header>
        </Grid.Row>
        <Grid.Row centered style={{marginTop: -25}}>
          <Grid.Column width={12}>
            <Divider style={{backgroundColor: '#c73230'}}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered style={{marginTop: -40}}>
          <Grid.Column width={8}>
            <Divider style={{backgroundColor: '#c73230'}}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered style={{marginTop: -40}}>
          <Grid.Column width={4}>
            <Divider style={{backgroundColor: '#c73230'}}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="how-to-play-div">
        <div className="how-to-play-sec">
        <Menu fluid widths={2} attached='top' size='massive' className="start-here-menu">
          <Menu.Item
            name='JOIN A POOL'
            icon='group'
            active={activeItem === 'join'}
            onClick={() => setActiveItem('join')}
          />
          <Menu.Item
            name='CREATE A POOL'
            icon='lab'
            active={activeItem === 'create'}
            onClick={() => setActiveItem('create')}
          />
          </Menu>
        <Segment attached>
          {activeItem === 'create' ? 
            <CreateAPool />
          :
            <JoinAPool />
          }
        </Segment>
        </div>
      </div>
    </div>
  )
}

export default StartHere;
