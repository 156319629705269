import { toast } from 'react-semantic-toasts';

export const toastSuccess = (title, description) => toast({
  type: 'warning',
  icon: 'check circle',
  color: 'violet',
  title,
  description,
  animation: 'slide down',
  time: 3000
});

export const toastError = (title, description) => toast({
  type: 'error',
  icon: 'exclamation',
  color: 'red',
  title,
  description,
  animation: 'slide down',
  time: 3000
});