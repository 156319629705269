import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Image, Pagination, Transition, Modal, Header, Button, Icon, Loader, Grid } from 'semantic-ui-react';
import moment from 'moment';
import { makeUserPick, fetchActiveGameWeek } from '../../actions';
import logos from '../../modules/logos';
import RenderWeekSchedule from '../table_helpers/RenderWeekSchedule';
import '../styles/team-schedules.scss';
import { getActiveUserSeason } from '../../utils';

const MakePicks = ({ 
  activeGameWeek,
  activeEntries,
  activePool,
  auth,
  deadlines,
  fetchActiveGameWeek,
  league,
  liveWeek,
  makeUserPick,
  scheduleWeeks,
  teamDetails,
  teamSchedules,
}) => {
  const [visible, setVisible] = useState(false);
  const [activeTeam, setActiveTeam] = useState(null);
  const [userActionDesc, setUserActionDesc] = useState(null);
  const [userAction, setUserAction] = useState(null);
  const [render, setRender] = useState(false);
  const [weeks, setWeeks] = useState([]);
  const [schedWeek, setSchedWeek] = useState(activeGameWeek);
  const [locked, setLocked] = useState(false);
  const [userSeason, setUserSeason] = useState(getActiveUserSeason(auth, activePool, activeEntries));

  const close = () => setVisible(false);
  const pastWeeklyDeadline = moment().isAfter(moment(deadlines[schedWeek - 1]));

  useEffect(() => {
    if (!schedWeek) {
      fetchActiveGameWeek(activePool.game, activePool.season, activePool.season_type);
    }

    if (pastWeeklyDeadline) {
      setLocked(true);
    }

    const weeksArray = scheduleWeeks.map((week, idx) => idx + 1);
    setWeeks(weeksArray);
    setRender(true);

    const interval = setInterval(() => {
      if (pastWeeklyDeadline) {
        setLocked(true);
      } else {
        setLocked(false);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [schedWeek, activePool, deadlines, scheduleWeeks]);

  useEffect(() => {
    setUserSeason(getActiveUserSeason(auth, activePool, activeEntries));
  }, [auth, activePool, activeEntries]);

  const changeWeek = (e, { activePage }) => {
    setSchedWeek(activePage);
    if (moment().isAfter(moment(deadlines[activePage - 1]))) {
      setLocked(true);
    } else {
      setLocked(false);
    }
  };

  const setPickData = (activeTeam, userAction) => {
    let userActionDesc = null;
    if (userAction === 'confirm') {
      userActionDesc = `You are selecting the ${fullTeamName(activeTeam).toUpperCase()} for Week ${schedWeek}. You are free to change this pick at anytime up until the first game of Week ${schedWeek} begins.`;
    } else if (userAction === 'cancel') {
      userActionDesc = `You are cancelling your selection of the ${fullTeamName(activeTeam).toUpperCase()} for Week ${schedWeek}.`;
    }
    setActiveTeam(activeTeam);
    setUserAction(userAction);
    setUserActionDesc(userActionDesc);
    setVisible(true);
  };

  const fullTeamName = (abb) => `${teamDetails[abb].c.toUpperCase()} ${teamDetails[abb].name.toUpperCase()}`;

  const handleMakeUserPick = (action) => {
    const pick = {
      auth,
      userId: auth[0].id,
      username: auth[0].username,
      userSeasonId: userSeason.id,
      entryNum: userSeason.entry_num,
      team: activeTeam,
      week: schedWeek,
      activePool,
      league_name: league.info.name,
      action
    };

    if (pastWeeklyDeadline) {
      alert("Sorry, this week's pick deadline has passed. Consider begging the Commish for mercy?")
    } else {
      makeUserPick(pick);
    }
    setVisible(false);
    setActiveTeam(null);
  };

  const renderHeaders = (week = schedWeek) => {
    return scheduleWeeks[(week - 1)].map(day =>
      <Table.HeaderCell key={day} className="sched-date-head"> {moment(day).format("ddd M/D").toUpperCase()} </Table.HeaderCell>
    );
  };

  const renderPickBox = (team) => {
    if (!userSeason) {
      return (
        <Table.Cell className="regToPick">
          {auth ? 'REGISTER' : 'LOGIN'} TO PICK
        </Table.Cell>
      );
    }
  
    if (team === userSeason.selections[0][schedWeek - 1] && !pastWeeklyDeadline) {
      return (
        <Table.Cell selectable className="selectedTeam">
          <a onClick={() => setPickData(team, "cancel")}></a>
        </Table.Cell>
      );
    }
  
    if (userSeason.selections[0].indexOf(team) !== -1) {
      const gameWeekTeamUsed = userSeason.selections[0].indexOf(team) + 1;
      const className = gameWeekTeamUsed < activeGameWeek ? "used" : "unavailable";
      return (
        <Table.Cell className={className}>
          {`Week ${gameWeekTeamUsed}`}
        </Table.Cell>
      );
    }
  
    if (pastWeeklyDeadline) {
      return <Table.Cell style={{ backgroundColor: '#F0F1F6' }}>  </Table.Cell>;
    }
  
    if (userSeason.extinct) {
      return (
        <Table.Cell className="eliminated">
          ELIMINATED
        </Table.Cell>
      );
    }
  
    if (!userSeason.alive) {
      return (
        <Table.Cell className="rebuyToPick">
          REBUY TO PICK
        </Table.Cell>
      );
    }
  
    return (
      <Table.Cell selectable className="selectTeam">
        <a onClick={() => setPickData(team, "confirm")}>SELECT</a>
      </Table.Cell>
    );
  };

  const renderRows = () => {
    return (
      Object.keys(teamSchedules).map((team, idx) => {
        return (
          <Table.Row key={team}>
            <Table.Cell className={`schedGridTeam ${activePool.game}_cell`}>
              <span>{team}</span>
              <Image src={logos[`${activePool.game}_${team}`]} size='mini'/>
            </Table.Cell>
            {renderPickBox(team)}
            <RenderWeekSchedule
              key={`${team}_${idx}`}
              activeGame={activePool.game}
              schedule={teamSchedules[team]}
              team={team}
              week={schedWeek}
              teamDetails={teamDetails}
            />
          </Table.Row>
        );
      })
    );
  };

  const renderTable = () => {
    return (
      <Table celled compact className="scheduleTable" unstackable={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Your Pick: {
                userSeason && schedWeek ?
                  (userSeason.selections[0][schedWeek - 1].length > 1 ?
                  <Image src={logos[`${activePool.game}_${userSeason.selections[0][schedWeek - 1]}`]} className="user-pick-img"/> : <Icon className="no-pick-icon" name="question" />)
                :
                null
              }
            </Table.HeaderCell>
            <Table.HeaderCell className='click-select'>
              {!pastWeeklyDeadline ? <span>Click to Select! <Icon name='arrow down' /></span> :
                 <span style={{color: '#FFCF8E'}}>
                  Picks Locked <Icon name="lock"/>
                </span>
              }
            </Table.HeaderCell>
            {renderHeaders()}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderRows()}
        </Table.Body>
        <Transition visible={visible} animation="fade up" duration={1000}>
          <Modal size="small" centered open={visible} onClose={close} className="confirm-pick-modal">
          <Header as='h2'>
            {activeTeam ?
              (`${userAction === 'confirm' ? 'Confirm Pick' : 'Cancel Pick'} of ${fullTeamName(activeTeam)} for Week ${schedWeek}`)
              : null}
            <img src={logos[`${activePool.game}_${activeTeam}`]} alt='logo' className="modal-logo-img"></img>
          </Header>
            <Modal.Content>
              {userActionDesc} Please confirm below.
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={close}>
                Back
              </Button>
              <Button primary onClick={() => handleMakeUserPick(userAction)}>
                <Icon name='check circle' />
                  {userAction === 'confirm' ? 'MAKE PICK' : 'CANCEL PICK'}
              </Button>
            </Modal.Actions>
          </Modal>
        </Transition>
      </Table>
    );
  };

  const dataIsLoaded = render
    && deadlines !== null
    && weeks.length > 0
    && teamDetails !== null
    && teamSchedules !== null
    && activeGameWeek !== null
    && scheduleWeeks.length > 0
    && schedWeek !== null;

  if (dataIsLoaded) {
    return (
      <div>
        <Grid style={{marginBottom: 20, marginTop: 5}}>
          <Grid.Row className={`page-header ${activePool.game}`}>
            <Grid.Column width={16}>
              <Header as='h2'>
                <div style={{display: 'inline-block'}}>
                  <Icon name='clipboard list' circular inverted size='large'/>
                </div>
                <Header.Content>
                  Make Your Picks Here.
                  <Header.Subheader>
                    - Browse the {activePool.game.toUpperCase()} schedule and make your selections here. <br />
                    - You can submit picks for whichever weeks of the season you would like at anytime. <br />
                    {activePool.game  === 'nba' && activePool.season === 2020 ? <span className={`${activePool.game}-color`}>- The 2020 NBA season will be 21 periods long and conclude with the week of May 16th.</span> : null }
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {moment().isBefore(moment(deadlines[deadlines.length-1])) ?
          <div className="deadline">
            <Icon name="warning sign"/>
            WEEK {liveWeek + 1} PICK DEADLINE: {moment(deadlines[liveWeek])
              .local()
              .format("M/D/YY, h:mm a")}
            <Icon name="warning sign"/>
          </div>
        : null}
        <div className="make-picks-top-row">
          <span className="week-header">View Week: </span>
          <Pagination
            className="picks-pagination"
            siblingRange={5}
            totalPages={weeks.length}
            onPageChange={changeWeek}
            activePage={schedWeek}
          />
        </div>
        {renderTable()}
      </div>
    );
  } else {
    return <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>;
  }
}

function mapStateToProps ({
  activeGameWeek,
  activeEntries,
  activePool,
  auth,
  deadlines,
  league,
  liveWeek,
  scheduleWeeks,
  seasonParams,
  teamDetails,
  teamSchedules,
  userSelections
}) {
  return { 
    activeGameWeek,
    activeEntries,
    activePool,
    auth,
    deadlines,
    league,
    liveWeek,
    scheduleWeeks,
    seasonParams,
    teamDetails,
    teamSchedules,
    userSelections
  };
}

export default connect(mapStateToProps, { makeUserPick, fetchActiveGameWeek })(MakePicks);
