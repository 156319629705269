import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, Table, Image } from 'semantic-ui-react';
import { recalculateBonus } from '../../actions';
import { GAME } from '../../constants';
import { formatDollars } from '../../utils';
import logos from '../../modules/logos';
import { toastError, toastSuccess } from '../Toast';
import "../styles/usage-grid.scss";

const BonusRows = props => {
  const headshot = p => props.game === GAME.NBA ? `https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${p}.png` : `https://securea.mlb.com/mlb/images/players/head_shot/${p}.jpg`;
  
  const recalculateBonus = async (poolId, bonusId) => {
    console.log('poolId is ', poolId, ' and bonusId is ', bonusId);
    try {
      await axios.post('/api/bonus/recalculate', {poolId, bonusId});
      toastSuccess('Bonus updated', 'Bonus has been successfully recalculated');
    } catch (e) {
      toastError('Error updating bonus', `An error was encountered updating this bonus: ${e}. Please contact ALA support for help with this error.`);
      console.log('error updating user settings was ', e);
    }
  }

  
  return props.bonusEvents.map(event => {
    return (
      <Table.Row className="bonus-row" key={event.id}>
        <Table.Cell> {props.admin ? <>
          <Button primary onClick={() => recalculateBonus(props.poolId, event.bonus_id)}> Recalculate </Button>
        </> 
        :  <>{event.username}</> 
        }
        </Table.Cell>
        <Table.Cell> {event.week} </Table.Cell>
        <Table.Cell> {moment(props.admin ? event.bonus_date : event.updated_at).format('M.D.YY')}</Table.Cell>
        <Table.Cell> 
          <a href={props.game === GAME.NBA 
            ? `https://stats.nba.com/game/00${event.gid}` 
            : `https://www.mlb.com/gameday/${event.gid}`} target="_blank" rel='noopener noreferrer'
          >{event.game_summary}</a>
        </Table.Cell>
        <Table.Cell> {event.bonus_name} </Table.Cell>
        <Table.Cell> <Image size='mini' src={logos[`${props.game}_${event.team_abb}`]} /> </Table.Cell>
        <Table.Cell> {!event.team_bonus ? <Image style={{display:'inline'}} src={headshot(event.player)} rounded size='mini' /> : <Image style={{display:'inline'}} src={logos[`${props.game}_${event.team_abb}`]} rounded size='mini' />} <span style={{display:'inline'}}>{event.desc}</span> </Table.Cell>
        { !props.admin && props.dollars !== 'Prizes' ?
          <Table.Cell> {event.dollars_total < 0 ? event.bonus_winners[0].length : null} </Table.Cell>
          : null
        }
        { !props.admin && 
          <Table.Cell style={event.dollars_total < 0 ? {color: '#CF2E2E'} : {fontWeight: '800'}}> {formatDollars(event.dollars_total, 2)} </Table.Cell>
        }
      </Table.Row>
    )
  })
}

export default BonusRows;

