export const STANDINGS_VIEW_VALUES = {
  DAY: 'By Day',
  WEEK: 'Week',
  TEAM: 'Team',
  BONUS: 'Bonus Pool'
}

export const STANDINGS_VIEW_OPTIONS =
[
  { key: 'week', value: STANDINGS_VIEW_VALUES.WEEK, text: STANDINGS_VIEW_VALUES.WEEK, icon: 'calendar' },
  { key: 'team', value: STANDINGS_VIEW_VALUES.TEAM, text: STANDINGS_VIEW_VALUES.TEAM, icon: 'users' },
  { key: 'bonus', value: STANDINGS_VIEW_VALUES.BONUS, text: STANDINGS_VIEW_VALUES.BONUS, icon: 'rocket'}
]

export const STANDINGS_SORT_VALUES = {
  LIVES: 'Lives',
  USERNAME: 'Username',
  BONUS: 'Bonus $'
}

export const STANDINGS_SORT_OPTIONS = [
  { key: 'lives', value: STANDINGS_SORT_VALUES.LIVES, text: STANDINGS_SORT_VALUES.LIVES, icon: 'user outline' },
  { key: 'user', value: STANDINGS_SORT_VALUES.USERNAME, text: STANDINGS_SORT_VALUES.USERNAME, icon: 'sort alphabet down' },
  { key: 'bonus', value: STANDINGS_SORT_VALUES.BONUS, text: STANDINGS_SORT_VALUES.BONUS, icon: 'rocket'}
]