import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import activeEntries from './activeEntryReducer.js';
import activeGameWeek from './activeGameWeekReducer';
import activePool from './activePoolReducer';
import allLeagues from './allLeaguesReducer';
import allPools from './allPoolsReducer';
import allUsers from './allUsersReducer';
import alphTeams from './alphTeamsReducer';
import auth from './authReducer';
import authErrors from './authErrorReducer';
import bonusEvents from './bonusEventsReducer';
import bonusOptions from './bonusOptionsReducer';
import commishPosts from './commishPostsReducer';
import currentSeasons from './seasonCurrentReducer';
import deadlines from './deadlineReducer';
import feedEvents from './feedReducer';
import filteredEvents from './filterFeedReducer';
import gameBonusTotals from './gameBonusReducer';
import gameEntrants from './gameEntrantsReducer';
import joinModalVis from './joinModalVisReducer';
import league from './leagueReducer';
import leagueTransactions from './leagueTransactionsReducer.js';
import liveWeek from './liveWeekReducer';
import loading from './loadingReducer.js';
import payments from './paymentsReducer';
import pendingSeasonParams from './seasonParamsPendingReducer';
import poolBonuses from './bonusesMappedReducer';
import poolHealth from './poolHealthReducer';
import poolSelections from './poolSelectionsReducer';
import prizes from './prizeReducer';
import pwReset from './pwResetReducer';
import scheduleWeeks from './scheduleWeeksReducer';
import seasonLength from './seasonLengthReducer';
import seasonParams from './seasonParamsReducer';
import seasonParamsIndex from './seasonParamsIndexReducer';
import teamDetails from './teamDetailsReducer';
import teamResults from './teamResultsReducer';
import teamSchedules from './teamSchedulesReducer';
import weeklyBonusTotals from './weeklyBonusReducer';
import weeklyTotals from './weeklyTotalsReducer';

export default combineReducers({
  activeEntries,
  activeGameWeek,
  activePool,
  alphTeams,
  allLeagues,
  allPools,
  allUsers,
  auth,
  authErrors,
  bonusEvents,
  bonusOptions,
  commishPosts,
  currentSeasons,
  deadlines,
  feedEvents,
  filteredEvents,
  form: formReducer,
  gameBonusTotals,
  gameEntrants,
  joinModalVis,
  league,
  leagueTransactions,
  liveWeek,
  loading,
  payments,
  pendingSeasonParams,
  poolBonuses,
  poolHealth,
  poolSelections,
  prizes,
  pwReset,
  scheduleWeeks,
  seasonLength,
  seasonParams,
  seasonParamsIndex,
  teamDetails,
  teamResults,
  teamSchedules,
  weeklyBonusTotals,
  weeklyTotals
});
