import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, Statistic, Icon, Popup, Grid, Loader } from 'semantic-ui-react';
import { getActiveUserSeason } from '../../../utils';
import { 
  STANDINGS_SORT_OPTIONS,
  STANDINGS_SORT_VALUES,
  STANDINGS_VIEW_OPTIONS,
  STANDINGS_VIEW_VALUES,
} from './Standings.constants';
import ScrollableEntrantTable from '../../table_helpers/ScrollableEntrantTable';
import "../../styles/usage-grid.scss";

const Standings = ({ 
  activeEntries,
  activeGameWeek,
  activePool,
  alphTeams,
  auth,
  bonusEvents,
  gameBonusTotals,
  gameEntrants,
  league,
  liveWeek,
  prizes,
  scheduleWeeks,
  seasonParams,
  teamResults,
  teamSchedules,
  weeklyBonusTotals,
}) => {
  const [render, setRender] = useState(false);
  const [weeks, setWeeks] = useState(null);
  const [sortMethod, setSortMethod] = useState(STANDINGS_SORT_VALUES.LIVES);
  const [sortIcon, setSortIcon] = useState('user outline');
  const [sortOptions, setSortOptions] = useState(STANDINGS_SORT_OPTIONS);
  const [view, setView] = useState(STANDINGS_VIEW_VALUES.WEEK);
  const [viewIcon, setViewIcon] = useState('calendar');
  const [viewOptions, setViewOptions] = useState(STANDINGS_VIEW_OPTIONS);

  useEffect(() => {
    let weekVal = scheduleWeeks.length;
    if (JSON.parse(seasonParams.dashed_date_arrays).length - 1 > weekVal) {
      weekVal = JSON.parse(seasonParams.dashed_date_arrays).length - 1; // subtract 1 here to account for zero-index not being game week
    }
    setWeeks(weekVal);
    setRender(true);
  }, [scheduleWeeks, seasonParams]);

  useEffect(() => {
    if (activePool?.bonusActive === false) {
      setViewOptions(viewOptions.filter(option => option.value !== STANDINGS_VIEW_VALUES.BONUS));
      setSortOptions(sortOptions.filter(option => option.value !== STANDINGS_SORT_VALUES.BONUS));
    }
  }, [activePool]);

  const changeView = (e, { value }) => {
    let obj = viewOptions.find(option => option.value === value);
    setView(value);
    setViewIcon(obj.icon);
  };

  const changeSort = (e, { value }) => {
    let obj = sortOptions.find(option => option.value === value);
    setSortMethod(value);
    setSortIcon(obj.icon);

    if (value === STANDINGS_SORT_VALUES.LIVES) {
      gameEntrants.sort((a, b) => {
        if (b.lives > a.lives) return 1;
        if (b.lives < a.lives) return -1;
        if (b.weeks_alive > a.weeks_alive) return 1;
        if (b.weeks_alive < a.weeks_alive) return -1;
        return 0;
      });
    } else if (value === STANDINGS_SORT_VALUES.USERNAME) {
      gameEntrants.sort((a, b) => a.username.localeCompare(b.username));
    } else if (value === STANDINGS_SORT_VALUES.BONUS) {
      gameEntrants.sort((a, b) => gameBonusTotals[`${b.username}_${b.id}`] - gameBonusTotals[`${a.username}_${a.id}`]);
    }
  };

  const entrants = gameEntrants.filter(e => e.status !== 0).length;
  const alive = gameEntrants.filter(e => !e.extinct).length;
  const bonus = gameEntrants.filter(e => e.bonus).length;
  const activeUserSeason = getActiveUserSeason(auth, activePool, activeEntries);

  if (render && gameBonusTotals !== null && activePool !== null && gameEntrants !== null && bonusEvents !== null) {
    const bonusDoll = bonusEvents.filter(e => e.dollars_total > 0).reduce((sum, e) => sum + e.dollars_total, 0);
    return (
      <div>
        <Grid style={{marginBottom: 20, marginTop: 5}}>
          <Grid.Row className={`page-header ${activePool.game}`}>
            <Grid.Column width={7}>
              <Statistic.Group className="flex-center">
                <Statistic color='red'>
                  <Statistic.Value>{entrants} <Icon name='users'/></Statistic.Value>
                  <Statistic.Label>Entrants</Statistic.Label>
                </Statistic>
                <Statistic color='orange'>
                  <Statistic.Value>{alive} <Icon name='heartbeat'/></Statistic.Value>
                  <Statistic.Label>Still Alive</Statistic.Label>
                </Statistic>
                <Statistic color='yellow'>
                  <Statistic.Value>{bonus} <Icon name='rocket'/></Statistic.Value>
                  <Statistic.Label>In Bonus Pool</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Column>
            <Grid.Column width={2} className="flex-center by-numbers">
              <Icon name='angle double left' className='pointer-arrow'/>
              <span>SURVIVAL SUMMARY</span>
              <Icon name='angle double right' className='pointer-arrow'/>
            </Grid.Column>
            <Grid.Column width={7}>
              <Statistic.Group className="flex-center">
                <Statistic color='olive'>
                  <Statistic.Value>{prizes.rebuys}</Statistic.Value>
                  <Statistic.Label>Re-buys</Statistic.Label>
                </Statistic>
                <Statistic color='brown'>
                  <Statistic.Value>${prizes.prize_pool}</Statistic.Value>
                  {league.info.abb === 'alevelabove' ? <Popup content="5% is taken from prize pool to cover server and website-related costs."
                    trigger={<Statistic.Label>Main Prize Pool*</Statistic.Label>} /> : <Statistic.Label>Main Prize Pool</Statistic.Label>}
                </Statistic>
                <Statistic color='grey'>
                  <Statistic.Value>${bonusDoll}</Statistic.Value>
                  <Statistic.Label>Bonuses Earned</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Column>
          </Grid.Row>
          {activePool.season === 2019 && activePool.game === 'nba' ?
            <Grid.Row className="page-subheader">
              <div className="game-susp">
                The 2019 NBA Survivol Pool was adapted for the bubble + playoffs and <Link to='/nba19/offline'>finished offline</Link> on account of the pandemic.
              </div>
            </Grid.Row>
          :
            null
          }
        </Grid>
        <div className="drop-wrapper">
        <Dropdown
          text={`View By:  ${view}`}
          onChange={changeView}
          options={viewOptions}
          icon={viewIcon}
          floating
          labeled
          button
          className='icon view-filter'
        >
        </Dropdown>
        </div>
        <div className="drop-wrapper">
        <Dropdown
          text={`Sort By:  ${sortMethod}`}
          onChange={changeSort}
          options={sortOptions}
          icon={sortIcon}
          floating
          labeled
          button
          className='icon view-filter-alt'
        >
        </Dropdown>
        </div>
        <div className="header-note">
          Picks and data will appear in the table below when selections lock each week.
        </div>
        {gameEntrants.length ?
          <ScrollableEntrantTable
            view={view}
            weeks={weeks}
            schedule={teamSchedules}
            entrants={gameEntrants.filter(e => e.status !== 0)}
            teams={alphTeams}
            liveWeek={liveWeek}
            activeWeek={activeGameWeek}
            bonusTotals={gameBonusTotals}
            teamResults={teamResults}
            weeklyBonusTotals={weeklyBonusTotals}
            alphTeams={alphTeams}
            activeUser={auth ? auth[0].username : null}
            activeUserSeason={activeUserSeason}
            activeGame={activePool.game}
            seasonParams={seasonParams}
            bonusActive={activePool.bonus_active}
          />
        :
          <div className="picks-hidden">
            <Icon name='sun' size='massive' />
            <div className="picks-hidden">Standings will show up here once this pool contains active users.</div>
          </div>
        }
      </div>
    )
  } else {
    return <Loader className="loader-down" active inline='centered' size='massive'> LOADING STANDINGS ... </Loader>
  }
}

function mapStateToProps({ 
  activeEntries,
  activeGameWeek,
  activePool,
  alphTeams,
  auth,
  bonusEvents,
  gameBonusTotals,
  gameEntrants,
  league,
  liveWeek,
  prizes,
  scheduleWeeks,
  seasonParams,
  teamResults,
  teamSchedules,
  weeklyBonusTotals,
 }) {
  return {
    activeEntries, 
    activeGameWeek,
    activePool,
    alphTeams,
    auth,
    bonusEvents,
    gameBonusTotals,
    gameEntrants,
    league,
    liveWeek,
    prizes,
    scheduleWeeks,
    seasonParams,
    teamResults,
    teamSchedules,
    weeklyBonusTotals
  };
}

export default connect(mapStateToProps)(Standings);
