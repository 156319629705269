import { BONUS_POOL_ACTIVE } from "../leagues/CreatePool.constants"

export const COMMISH_MENU = {
  BONUSES: 'bonuses',
  COMMISH_DESK: 'commishDesk',
  EDIT_PICKS: 'editPicks',
  EMAIL_POOL: 'emailPool',
  FINANCES: 'finances',
  MANAGE_ENTRANTS: 'manageEntrants',
  POOL_SETTINGS: 'poolSettings',
  REQ_TO_JOIN: 'reqToJoin',
  SPLIT_PRIZES: 'splitPrizes'
}

export const SETTING_KEY = {
  BUYIN: 'buyin',
  BUYIN_APPLY_RETRO: 'buyin_apply_retro',
  MAX_ENTRIES: 'max_entries',
  REBUYS: 'rebuys',
  REBUY_PRICE: 'rebuy_price',
  INSURANCE_OPTION: 'insurance_option',
  INSURANCE_PRICE: 'insurance',
  SHOOT_MOON: 'shoot_moon',
  BONUS_POOL_ACTIVE: 'bonus_active',
  BONUSES_DISABLED: 'bonus_disabled',
  BONUS_CADENCE: 'bonus_cadence',
  BONUS_SHUTDOWN: 'bonus_shutdown',
  BONUS_OPTIONS: 'bonus_options'
}


export const SETTING_NOTES = {
  [SETTING_KEY.BUYIN]: 'Leave the "Apply Retroactively" checkbox checked if you would like to update the buy-in amounts for users that have already signed up for your pool. Uncheck the box if you would like your new buy-in amount to only apply new entrants.',
  [SETTING_KEY.REBUYS]: "This setting cannot be changed after a pool has started. However, you can always manually edit users' lives through the Manage Entrants page.",
}

