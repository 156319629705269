import React from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Icon, List, Table, Popup, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import NBABonusExample from './howtoplay/nbaBonusExample';
import MLBBonusExample from './howtoplay/mlbBonusExample';
import BonusPoolFaq from './howtoplay/bonusPoolFaq';
import { GAME } from '../constants';
import { formatDollars } from '../utils';
import './styles/how-to-play.scss';

const cadenceVals = ['n/a', 'Anytime', 'Monthly', 'Preseason'];
const cadenceDesc = [
  'Your pool does not have an active Bonus Pool.',
  "Entrants are free to enter or leave the Bonus Pool whenever they wish. Actions to enter/leave the Bonus Pool always become effective beginning with the following week's games (e.g. you cannot enter/leave the Bonus Pool mid-week)",
  "Entrants can enter or leave the Bonus Pool once per month. Your pool homepage will indicate the next week that the Bonus Pool opens up for entry/exit.",
  "Entrants can enter or leave the Bonus Pool only before the season begins, at which point they are locked in the pool until it shuts down."
]

function HowToPlay ({ activePool, auth, league, bonusOptions }) {
  const {
    game,
    bonus_options,
    buyin,
    bonus_active,
    bonus_disabled,
    rebuys,
    bonus_cadence,
    rebuy_price,
    bonus_shutdown,
    insurance,
    insurance_option,
    season,
    season_type,
    pool_idx,
    shoot_moon
  } = activePool;
  const poolBonuses = JSON.parse(bonus_options);
  const activeOptions = poolBonuses 
  ? Object.entries(poolBonuses)
      .filter(([key, value]) => value.active)
      .map(([key, value]) => ({
        [key]: value
      }))
  : [];

  function renderPoolBonuses () {
    return activeOptions
    .map((option, idx) => {
      const key = Object.keys(option)[0];
      console.log('key', key);
      return (
        <Table.Row key={activeOptions[idx][key]}>
          <Table.Cell> {formatDollars(activeOptions[idx][key].prize, 0)}</Table.Cell>
          <Table.Cell> {activeOptions[idx][key].bonus_long_name} </Table.Cell>
        </Table.Row>
      )
    })
  }

  if (bonusOptions !== null) {
    return (
      <>
      <Grid style={{marginBottom: 20, marginTop: 5}}>
        <Grid.Row className={`page-header ${game}`}>
          <Grid.Column width={16}>
            <Header as='h2'>
              <div style={{display: 'inline-block'}}>
                <Icon name='sitemap' circular size='large' inverted/>
              </div>
              <Header.Content>
                Learn the Ropes.
                <Header.Subheader>
                  - This pool uses a combination of A Level Above's default settings and those configured by your league's Commissioner. <br />
                  - Insurance and Bonus Pool settings can be modified while a pool is in progress. Ask your Commissioner for details.
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="how-to-play-div">
        <div className="full-theme-banner">
          THE BASICS
        </div>
        <div className="how-to-play-sec">
          <h1> Your primary objective is simple: <span className='game-color'>stay alive</span>. </h1>
          <ul>
            <li><span style={{fontSize: '1.2em'}}><b>Pick one {game.toUpperCase()} team per week.</b></span></li>
            <li>Your team must not lose more games than they win <b>(e.g. they must go .500 or better)</b> for you to advance. If your team finishes with a sub-.500 record, <b>you lose a life</b>.</li>
            <li>Once you select a team, you cannot use them again for the remainder of the season.</li>
            <li>If you lose all of your lives, you are eliminated.</li>
            {
              game === GAME.MLB && shoot_moon &&
              <li><span style={{color: '#28A5DC'}}><b>CAN YOU SHOOT THE MOON?</b></span> A unique component of the MLB Survivor Pool: if you select a team that goes <b>winless</b> (0-6 or worse) over the course of an entire week, you will <b>gain</b> a life!</li>
            }
          </ul>
        </div>
        <div className="full-theme-banner">
          THIS POOL'S CUSTOM SETTINGS
        </div>
        <div className="how-to-play-sec-2">
          <Grid>
            <Grid.Row>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='dollar sign' circular /> </h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>BUY-IN: <span className='how-to-val'>{formatDollars(buyin, 0)}</span></h1>
                <div className='how-to-exp'> Send your Commish your initial buy-in before the season gets underway. </div>
              </Grid.Column>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='rocket' circular /></h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>BONUS POOL: {bonus_active ?
                  <span className='how-to-val'> ACTIVE </span>
                  :
                  <span className='error-color'> DISABLED </span>}
                </h1>
                <div className='how-to-exp'> {bonus_active ?
                  <> This pool has an active Bonus Pool! This is an <b>optional</b> game component. Scroll down for more details. </>
                  :
                  <> This Pool's Commissioner has not activated the Bonus Pool, which is an optional game component that awards entrants for player and team achievements. <Link to={{
                    pathname: '/getstarted',
                    state: {
                      activeItem: 'howtoplay'
                    }
                  }}>Learn more here</Link>. </>} </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='user outline' circular /> </h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>LIVES: <span className='how-to-val'>{1 + rebuys}</span></h1>
                <div className='how-to-exp'>
                  {rebuys > 0 ?
                    `${rebuys + 1} strikes and you're out.`
                  :
                    'Your Commissioner has allocated your pool entrants just one life for the season. Good luck!'
                  }
                </div>
              </Grid.Column>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='exchange' circular /> </h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>IN/OUT CADENCE: {bonus_disabled ?
                  <i>n/a</i> :
                    <span className='how-to-val'>
                      {cadenceVals[bonus_cadence]}
                    </span>
                }</h1>
                <div className='how-to-exp'> {bonus_disabled ?
                  'This pool does not have an active Bonus Pool.' :
                    <>
                      {cadenceDesc[bonus_cadence]}
                    </>
                } </div>
                <span className='how-to-bonus-label'><Icon name='rocket' /> BONUS POOL</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='life ring' circular /> </h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>RE-BUY COST: <span className='how-to-val'>{formatDollars(rebuy_price, 0, true)}</span></h1>
                <div className='how-to-exp'> {rebuys > 0 ? <>When you lose a life, you will need to rebuy through your pool homepage -- unless you're savvy enough to have Auto-Rebuys turned on. In which case you should go help yourself to a beer.</> : <>There are no rebuys available in this pool.</>} </div>
              </Grid.Column>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='level down alternate' circular /></h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1 style={{position: 'relative'}}>SHUTDOWN:
                  {!bonus_active ?
                    <i> n/a</i> :
                      <>
                        {bonus_shutdown > 0 ?
                          <>
                            <span className='how-to-val'> {bonus_shutdown}
                              {bonus_shutdown === 1 ? ' ENTRANT' : ' ENTRANTS'}
                            </span>
                          </>
                        :
                          <span className='error-color'> DISABLED </span>
                        }
                      </>
                  }
                </h1>
                <span className='how-to-bonus-label'><Icon name='rocket' /> BONUS POOL</span>
                <div className='how-to-exp'> {!bonus_active ?
                  'This pool does not have an active Bonus Pool.' :
                    <>
                      {bonus_shutdown > 0 ?
                        <>
                          This pool's Bonus Pool will shut down when {bonus_shutdown} or less entrants remain in it at the beginning of a week. Bonus Pool penalties can become expensive as the Bonus Pool shrinks in size. A pool's Commissioner can always manually shut down or reactivate a Bonus Pool.
                        </>
                      :
                        "This pool's Bonus Pool is set to run all season."
                      }
                    </>
                } </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='shield alternate' circular /> </h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>INSURANCE: {insurance_option > 0 ?
                  <span className='how-to-val'> AVAILABLE ({formatDollars(insurance, 0, true)})</span>
                  :
                  <span className='error-color'> DISABLED </span>}
                </h1>
                <div className='how-to-exp'> Insurance policies protect an entrant in the event that they forget to make a pick by allocating them the most popular choice in a pool that week that remains available to them. Insurance can be purchased through a pool's homepage, and proceeds from insurance purchases are automatically included in a pool's prize distribution. </div>
              </Grid.Column>
              {game === GAME.MLB && 
                <>
                  <Grid.Column className='how-to-icon-col' width={1}>
                    <h1> <Icon name='moon' circular /> </h1>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    <h1>SHOOT THE MOON: {shoot_moon ?
                      <span className='how-to-val'> ACTIVE</span>
                      :
                      <span className='error-color'> DISABLED </span>}
                    </h1>
                    <div className='how-to-exp'> For MLB Pools only. If active, entrants that select a team that goes <b>winless</b> (0-6 or worse) over the course of an entire week will <b>GAIN</b> a life! </div>
                  </Grid.Column>
                </>
              }
            </Grid.Row>
          </Grid>
        </div>
      </div>
      <div className="how-to-play-div">
        {bonus_active ?
          <>
            <div className="full-theme-banner">
              <Icon name='rocket' style={{color: 'white'}} /> WHAT'S THIS ABOUT A BONUS POOL?
            </div>
            <div className="how-to-play-sec-2">
            <Grid>
              <Grid.Row>
                <Grid.Column width={10}>
                  <List size='large' bulleted id="how-to-bonus-list" >
                    <List.Item style={{lineHeight: 1.4}}>
                      The Performance Bonus Pool awards prizes to participating entrants for outstanding team or player achievements from their weekly Survivor selection, which are collectively paid out by the other entrants who have opted-in to the Peformance Bonus Pool. If active, participation in a Bonus Pool is <b>optional</b> for a pool's entrants.
                    </List.Item>
                    <List.Item style={{lineHeight: 1.4}}>
                      View this pool's active Performance Bonuses in the table to the right.
                    </List.Item>
                    <List.Item style={{lineHeight: 1.4}}>
                      {game === GAME.NBA ? <NBABonusExample /> : <MLBBonusExample />}
                    </List.Item>
                    <List.Item style={{lineHeight: 1.4}}>
                      Bonus Pool achievements will show up both within the box scores on a pool's <Link to={`/${game}/${league.info.abb}/${season}/${season_type}/${pool_idx}/scoreboard`}>Scoreboard</Link> and in the News Feed on a pool's <Link to={`/${game}/${league.info.abb}/${season}/${season_type}/${pool_idx}`}>homepage</Link>. Bonus Pool prizes and payouts are automatically tracked and calculted by the Survivor software, and can be viewed in detail within a pool's <Link to={`/${game}/${league.info.abb}/${season}/${season_type}/${pool_idx}/bonuses`}>Bonuses</Link> page.
                    </List.Item>
                    <List.Item style={{lineHeight: 1.4}}>
                      For some more information, take a look at the <Popup content={<BonusPoolFaq />} wide='very' trigger={<b><u>Bonus Pool FAQ</u></b>} />. If you'd like to get a better sense of the monetary scale of participating in a Bonus Pool, go check out the Bonus Pool balances on the <a href='/nba/alevelabove/2019/reg/0/finances'>Finances</a> page from NBA Survivor's first season, or dig into the <a href='/nba/alevelabove/2019/reg/0/bonuses'>cornucopia of bonuses</a> that were awarded in the pool's inaugural year.
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={6} textAlign='center'>
                  <Header size='large'>This Pool's Active Bonuses </Header>
                    <div>
                      <Table celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Payout</Table.HeaderCell>
                            <Table.HeaderCell>Achievement</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {renderPoolBonuses()}
                        </Table.Body>
                      </Table>
                    </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            </div>
          </>
        :
          null
        }
      </div>
      </>
    )
  } else {
    return <Loader className="loader-down" active inline='centered' size='massive'> LOADING POOL </Loader>
  }

}

function mapStateToProps ({ bonusOptions }) {
  return { bonusOptions }
}

export default connect (mapStateToProps, {}) (HowToPlay);
