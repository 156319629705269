export const formatDollars = (val, dec, showZero = false) => {
  if (!val && !showZero) { 
    return null;
  }

  if (val === 0) { 
    return '$0';
  }

  return val >= 0 ? `$${(val).toFixed(dec)}` : `-$${(Math.abs(val)).toFixed(dec)}`
};