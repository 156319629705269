import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Grid, Card, Icon, Button, Transition } from 'semantic-ui-react';
import JoinPoolModal from '../../game/JoinPoolModal';
import AuthModal from '../../AuthModal';
import ContextPopup from '../../other_helpers/ContextPopup';
import { getNumberOrdinal } from '../../../utils';
import { INSURANCE_OPTION_DETAIL, POOL_CONCLUDED, POOL_IN_PROGRESS } from './PoolDetails.constants';
import { INSURANCE_USER_CONTEXT_BODY } from '../../../constants';

function renderSignUpSection (activePool, auth, gameEntrants, joinModalVis, league, userInLg, userSeason, setJoinModalVis) {
  if (!auth[0]) { return <div className='details-login-box'> <AuthModal /> <br/>to Sign Up</div> }
  const userPoolEntries = gameEntrants?.filter(e => e.user_id === auth[0].id).length;

  if (activePool.max_entries === 1 && userPoolEntries > 0) {
    return <div className='detail-info-msg'> <i>You are registered for this pool.</i> </div>
  } else {
    if (moment().isAfter(moment(activePool.start))) {
      return <div className='detail-info-msg'>
        <i> {activePool.final ? POOL_CONCLUDED : POOL_IN_PROGRESS}</i>
      </div>
    } 

    if (league.info.joinable || userInLg) {
      if (userPoolEntries < 1) {
        return <>
          <Button size='big' onClick={() => setJoinModalVis(true)}>SIGN UP!</Button>
          <Transition visible={joinModalVis} animation="fly down" duration={800}>
            <JoinPoolModal visible={joinModalVis} auth={auth} activePool={activePool} league={league} userPoolEntries={userPoolEntries}/>
          </Transition>
        </>
      } else if (userPoolEntries < activePool.max_entries) {
        return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div className='detail-info-msg'> <i>Your Entries: {userPoolEntries}</i> </div>
          <Button onClick={() => setJoinModalVis(true)}>ADD {getNumberOrdinal(userPoolEntries + 1)} ENTRY</Button>
          <Transition visible={joinModalVis} animation="fly down" duration={800}>
            <JoinPoolModal visible={joinModalVis} auth={auth} activePool={activePool} league={league} userPoolEntries={userPoolEntries}/>
          </Transition>
        </div>
      }

      return <div className='detail-info-msg'> <i>You have the maximum number of {activePool.max_entries} entries for this pool.</i> </div>
    } 

    return <div className='detail-info-msg-lg'>
      <i>Entry to this pool is restricted to its league's members. Head to the <Link to={`/league/${league.info.abb}`}>{league.info.name} league page</Link> to request access.</i>
    </div>
  }
}

export const PoolDetails = ({ 
  activePool,
  auth,
  entrants,
  gameEntrants,
  joinModalVis,
  league,
  prizes,
  setJoinModalVis,
  userInLg,
  userSeason, 
}) => {
  return <Card color={activePool.game === 'nba' ? 'orange' : 'blue'} className="details-card card-header-alt">
    <Card.Content>
      <Card.Header>
        <Grid columns={2}>
          <Grid.Column width={10}>
            <Link to={`/league/${league.info.abb}`} className="card-lg"> {league.info.name} {activePool.pool_idx > 0 ? `#${activePool.pool_idx + 1}` : null}</Link>
            <div> <Icon name='list alternate outline' /> Pool Details </div>
          </Grid.Column>
          <Grid.Column className='detail-button-col' width={6}>
            {renderSignUpSection(activePool, auth, gameEntrants, joinModalVis, league, userInLg, userSeason, setJoinModalVis)}
          </Grid.Column>
        </Grid>
      </Card.Header>
      <Grid className="detail-grid">
        <Grid.Row>
          <Grid.Column width={5} style={{color: 'black'}}>
            <Grid.Row> <i>BUY-IN:</i> </Grid.Row>
            <Grid.Row> <i>LIVES:</i> </Grid.Row>
            <Grid.Row> <i>REBUY COST:</i> </Grid.Row>
            <Grid.Row> <i>MAX ENTRIES:</i> </Grid.Row>
          </Grid.Column>
          <Grid.Column width={3} style={{marginLeft: -15}}>
            <Grid.Row> <b>${activePool.buyin}</b> </Grid.Row>
            <Grid.Row> <b>{activePool.rebuys + 1}</b> </Grid.Row>
            <Grid.Row> <b>${activePool.rebuy_price}</b> </Grid.Row>
            <Grid.Row> <b>{activePool.max_entries}</b> </Grid.Row>
          </Grid.Column>
          <Grid.Column width={5} style={{color: 'black'}}>
            <Grid.Row> <i>ENTRANTS:</i> </Grid.Row>
            <Grid.Row> <i>PRIZE POOL:</i> </Grid.Row>
            <Grid.Row> <i>BONUS POOL:</i> </Grid.Row>
          </Grid.Column>
          <Grid.Column width={3} style={{marginLeft: -15}}>
            <Grid.Row> <b>{entrants}</b> </Grid.Row>
            <Grid.Row> <b>${prizes.prize_pool}</b> </Grid.Row>
            <Grid.Row> {
              activePool.bonus_active 
              ? !activePool.bonus_disabled ? <b>ACTIVE</b> : <span style={{color: 'black'}}>Shutdown</span>
              : <span style={{color: 'black'}}>Disabled</span>
            } </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{marginTop: -5}}>
          <Grid.Column width={5} style={{position: 'relative', color: 'black'}}>
          <i>INSURANCE:</i>
              <ContextPopup
              header="What's Insurance?"
              content={INSURANCE_USER_CONTEXT_BODY(activePool.insurance_option)}
              trigger={<Icon style={{fontSize: 10}} className="context-icon" name='question circle' />}
              />
          </Grid.Column>
          <Grid.Column width={11} style={{marginLeft: -15}}>
            <b>
              {activePool.insurance_option > 0 
                ? `${INSURANCE_OPTION_DETAIL[activePool.insurance_option]} ($${activePool.insurance})`
                : <span style={{color: 'black'}}>n/a</span>}
            </b>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card.Content>
  </Card>
}