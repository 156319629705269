/**
 * Retrieves the active user season based on the provided authentication, active pool, and active entries.
 *
 * @param {Array} auth - The authentication array containing user information.
 * @param {Object} activePool - The active pool object containing the current pool information.
 * @param {Array} activeEntries - The array of active entries containing entry information.
 * @returns {Object|undefined} The active user season object if found, otherwise undefined.
 */
export const getActiveUserSeason = (auth, activePool, activeEntries) => {
  return auth?.[0]?.userSeasons?.find(us =>
    us.league_season === activePool.id
    && us.entry_num === activeEntries.find(e => e.leagueSeasonId === activePool.id).entryNum
  );
};