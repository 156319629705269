import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Menu, Icon, Responsive, Dropdown, Image, Transition } from 'semantic-ui-react';
import AuthModal from './AuthModal';
import alalogo from '../images/ALAlogo_transbg.png';
import './styles/header.scss';

class Header extends Component {
  renderAuthStatus() {
    switch (this.props.auth) {
      case null:
        return;
      case false:
        return (
          <AuthModal />
        )
      default:
        return (
          <>
            {this.props.auth[0].photo_url ?
              <Image src={this.props.auth[0].photo_url} rounded size='mini' className='header-user-img'/>
            : <Icon rounded size='large' name='user circle' className='header-user-icon' />}
            <Dropdown text={this.props.auth[0].username} simple item>
              <Menu>
                <Menu.Item
                 as={NavLink}
                 to={`/profile`}
                 style={{color: 'white !important'}}
                >
                  Profile
                </Menu.Item>
                <Menu.Item
                 href="/api/logout"
                 style={{color: 'white !important'}}
                >
                  Logout
                </Menu.Item>
              </Menu>
            </Dropdown>
          </>
        )
    }
  }

  renderPools(g) {
    const {auth, allLeagues} = this.props;
    const uniqueSeasons = new Set();
    if (auth !== null && allLeagues !== null) {
      if (!auth) {
        return (
          <Menu.Item>
            <div style={{fontSize: 14, paddingLeft: 5}}>
              <AuthModal /> to view leagues
            </div>
          </Menu.Item>
        )
      } else {
        if (auth[0].userSeasons.filter(us => us.game === g).length) {
          return auth[0].userSeasons
            .filter(us => us.game === g)
            .sort((a, b) => parseInt(b.season) - parseInt(a.season))
            .filter(us => {
              if (uniqueSeasons.has(us.league_season)) {
                return false;
              } else {
                uniqueSeasons.add(us.league_season);
                return true;
              }
            })
            .map((pool, idx) => {
              return (
                <Menu.Item
                  key={idx}
                  href={`/${g}/${pool.league_abb}/${pool.season}/${pool.season_type}/${pool.pool_idx}`}
                >
                  <Image style={{maxWidth: 41}} src={allLeagues.find(l => l.id === pool.league_id)?.logo || 'https://alasurvivor.s3-us-west-2.amazonaws.com/surv-logo.png'} size='tiny' circular />
                  {allLeagues.find(l => l.id === pool.league_id)?.name} {pool?.season} {pool?.pool_idx > 0 ? `#${pool?.pool_idx + 1}` : null}
                </Menu.Item>
              )
            })
        } else {
          return (
            <Menu.Item>
              <div style={{fontSize: 14, paddingLeft: 5, verticalAlign: 'middle'}}>
                <i> (no pools found) </i>
              </div>
            </Menu.Item>
          )
        }
      }
    }
  }

  renderLeagues() {
    if (this.props.auth !== null) {
      if (!this.props.auth) {
        return (
          <Menu.Item>
            <div style={{fontSize: 14, paddingLeft: 5}}>
              <AuthModal /> to view leagues
            </div>
          </Menu.Item>
        )
      } else {
        if (this.props.auth[0].leagues.length) {
          return this.props.auth[0].leagues.map((league, idx) => {
            return (
              <Menu.Item
                key={idx}
                as={NavLink}
                to={`/league/${league.league_abb}`}
              >
                <Image style={{maxWidth: 41}} src={league.logo || 'https://alasurvivor.s3-us-west-2.amazonaws.com/surv-logo.png'} size='tiny' circular />
                {league.league_name}
              </Menu.Item>
            )
          })
        } else {
          return (
            <Menu.Item>
              <div style={{fontSize: 14, paddingLeft: 5, verticalAlign: 'middle'}}>
                <i> (no leagues found) </i>
              </div>
            </Menu.Item>
          )
        }
      }
    }
  }

  render () {
    const {currentSeasons, allLeagues} = this.props;
    if (currentSeasons && allLeagues) {
      const activeGlobalMlbYear = Math.max.apply(Math, allLeagues
        .find(l => l.abb === 'alevelabove')
        .seasons
        .filter(s => s.game === 'mlb')
        .map(p => p.season));

      const activeGlobalNbaYear = Math.max.apply(Math, allLeagues
        .find(l => l.abb === 'alevelabove')
        .seasons
        .filter(s => s.game === 'nba')
        .map(p => p.season));


      return (
        <div className="header-container">
          <Responsive as={Menu} secondary pointing>
            <Menu.Item href="/">
              A Level Above <span className="logo-right"> SURVIVOR SERIES </span>
            </Menu.Item>
            <Menu.Item>
              <Icon name='ellipsis vertical'></Icon>
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              name='getstarted'
              to={{
                pathname: '/getstarted',
                state: {
                  activeItem: 'starthere'
                }
              }}
              style={{fontSize: '1.2em'}}
            >
              <b>JUMP IN</b>
            </Menu.Item>
            <Menu.Item>
              <Icon name='ellipsis vertical'></Icon>
            </Menu.Item>
            <Menu.Item
              name='NBA'
              className="menu-leagues"
              key='nba'
            >
              <Icon name='basketball ball' />
              <Dropdown text='NBA' simple item>
                <Menu>
                  <Menu.Item
                   key='nba_l'
                   href={`/nba/alevelabove/${activeGlobalNbaYear}/reg/0`}
                   className='global-header-link'
                  >
                    <Icon name='globe' style={{marginTop: '-5px !important'}}/>
                    <span>Global ALA Pool</span>
                  </Menu.Item>
                  <Menu.Item
                   as={NavLink}
                   to={`/leagues/allpools`}
                   style={{color: 'white !important'}}
                  >
                    All Pools
                  </Menu.Item>
                  <Menu.Item as={Dropdown} text='Your Pools' simple item>
                    <Menu>
                      {this.renderPools('nba')}
                    </Menu>
                  </Menu.Item>
                </Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item
              name='MLB'
              key='mlb'
              className="menu-leagues"
            >
              <Icon name='baseball ball' />
              <Dropdown text='MLB' simple item>
                <Menu>
                  <Menu.Item
                   key='mlb_l'
                   href={`/mlb/alevelabove/${activeGlobalMlbYear}/reg/0`}
                   className='global-header-link'
                  >
                    <Icon name='globe' style={{marginTop: '-5px !important'}}/>
                    <span>Global ALA Pool</span>
                  </Menu.Item>
                  <Menu.Item
                   as={NavLink}
                   to={`/leagues/allpools`}
                   style={{color: 'white !important'}}
                  >
                    All Pools
                  </Menu.Item>
                  <Menu.Item as={Dropdown} text='Your Pools' simple item>
                    <Menu>
                      {this.renderPools('mlb')}
                    </Menu>
                  </Menu.Item>
                </Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item>
              <Icon name='ellipsis vertical'></Icon>
            </Menu.Item>
            <Menu.Item
              name='leagues'
              className="menu-leagues"
            >
              <Icon name='group' />
              <Dropdown text='Leagues' simple item>
                <Menu>
                  <Transition.Group animation='slide down' duration={500}>
                    <Menu.Item as={Dropdown} text='Your Leagues' simple item>
                      <Menu>
                        {this.renderLeagues()}
                      </Menu>
                    </Menu.Item>
                    <Menu.Item
                     as={NavLink}
                     to={`/leagues/find`}
                     style={{color: 'white !important'}}
                    >
                      Find A League
                    </Menu.Item>
                    <Menu.Item
                     as={NavLink}
                     to={`/leagues/create`}
                     style={{color: 'white !important'}}
                    >
                      Create A League
                    </Menu.Item>
                  </Transition.Group>
                </Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item>
              <Icon name='ellipsis vertical'></Icon>
            </Menu.Item>
            <Menu.Item
              name='ala'
              className="menu-leagues ala"
            >
              <Image style={{maxWidth: 41, marginBottom: '-13px', marginRight: '-13px'}} src={alalogo} size='tiny'/>
              <Dropdown text='ALA' simple item>
                <Menu>
                  <Menu.Item
                    name='ALA Home'
                    href='http://www.alafantasysports.com'
                    target='blank'
                  >
                    <Image style={{maxWidth: 41}} src={'https://s3-us-west-2.amazonaws.com/mikeduin.com/ALA_big_white.png'} size='tiny' circular />
                    <b>ALA Home</b>
                  </Menu.Item>
                  <Menu.Item
                    name='Battle the Bookies'
                    href='http://www.alabattlethebookies.com'
                    target='blank'
                  >
                    <Image style={{maxWidth: 41}} src={'https://s3-us-west-2.amazonaws.com/mikeduin.com/ALA+_+Battle+the+Bookies+-+BIG.png'} size='tiny' circular />
                    <b>Battle the Bookies</b>
                  </Menu.Item>
                  <Menu.Item
                    name='All-Star Spectacular'
                    href='https://nba-all-star-spectacular.herokuapp.com/'
                    target='blank'
                  >
                    <Image style={{maxWidth: 41}} src={'https://s3-us-west-2.amazonaws.com/mikeduin.com/ALA+_+All-Star+Spectacular+-+BIG.png'} size='tiny' circular />
                    <b>All-Star Spectacular</b>
                  </Menu.Item>
                  <Menu.Item
                    name='Contact'
                    href='http://www.mikeduin.com/alevelabove/contact'
                    target='blank'
                  >
                    Contact
                  </Menu.Item>
                </Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item
              position='right'
              className='menu-leagues'
            >
              {this.renderAuthStatus()}
            </Menu.Item>
          </Responsive>
        </div>
      )
    }
  }
}

function mapStateToProps({ auth, allLeagues, currentSeasons }) {
  return { auth, allLeagues, currentSeasons }
}

export default connect(mapStateToProps, {})(Header);