import React from 'react';
import { Feed, Icon, Image } from 'semantic-ui-react';
import moment from 'moment';
import logos from '../modules/logos';

const EventFeed = props => {
  const renderLabel = (type, team=null, game) => {
    switch (type) {
      case 1:
        // user registers for game and bonus pool
        return <Icon name='star' />;
      case 2:
        // user registers for game, no bonus pool
        return <Icon name='bullhorn' />;
      case 3:
        // user opts in to bonus pool
        return <Icon name='money bill alternate' />;
      case 4:
        // user opts out of bonus pool
        return <Icon name='sign out alternate' />;
      case 5:
        // user makes selection -- use team logo here?
        return <Image className="pick-img" src={logos[`${game}_${team}`]} />;
      case 6:
        // user cancels selection
        return <Icon name='cancel' />;
      case 7:
        // bonus pool summary
        return <Icon name='rocket' />;
      case 8:
        // bonus pool credit
        return <Icon name='gift' />;
      case 9:
        // bonus pool debit
        return <Icon name='minus square outline' />;
      case 10:
        // user loses life, two lives left
        return <Icon name='first aid' />;
      case 11:
        // user loses life, one life left
        return <Icon name='ambulance' />;
      case 12:
        // user eliminated, loss of three lives
        return <Icon name='crosshairs' />;
      case 13:
        // user eliminated, failure to rebuy
        return <Icon name='crosshairs' />;
      case 14:
        // user has rebought
        return <Icon name='life ring' />;
      case 15:
        // user has turned on auto rebuys
        return <Icon name='sync' />;
      case 16:
        // user has turned off auto rebuys
        return <Icon name='dont' />;
      case 17:
        // thumbtack for commish alerts
        return <Icon name='thumbtack' />;
      case 18:
        // user shoots moon
        return <Icon name='moon' />;
      case 19:
        // user wins survivor
        return <Icon name='trophy' />;
      case 20:
        // commish modification
        return <Icon name='cogs' />;
      case 21:
        // prize pool split -- announcement
        return <Icon name='handshake outline' />;
      case 22:
        // prize pool split -- user share
        return <Icon name='handshake' />;
      case 23:
        // league pool is created
        return <Icon name='birthday cake' />;
      case 24:
        // entrant buys insurance
        return <Icon name='shield alternate' />;
      case 25:
        // user requests to join league
        return <Icon name='add user' />;
      case 26:
        // user request to join league approved/denied
        return <Icon name='gavel' />;
      case 27:
        // user request to join league decision reversed
        return <Icon name='redo' />;
      case 28:
        // user removed from league pool
        return <Icon name='remove user' />;
      case 29:
        // bonus pool shutdown
        return <Icon name='level down alternate' />;
      case 30:
        // pick awarded through insurance
        return <Icon name='shield alternate' />;
    }
  };

  const renderDesc = ev => {
    if (!ev.desc) return null;
    // Use descMod for any events for which you want the username noted at the beginning of the post
    const spaceCount = ev.username ? (ev.username.split(" ").length - 1) : 0;
    const descMod = ev.desc ? ev.desc.split(" ").slice((1+spaceCount)).join(" ") : ev.desc.substr(ev.desc.indexOf(" ") + 1);
    let descModTypes = [1, 2, 3, 4, 5, 6, 15, 16, 20, 22];
    if (descModTypes.includes(ev.type)) {
      return (<span> {descMod}</span>);
    } else {
      return (<span>{ev.desc}</span>);
    }
  };

  const renderEvents = events => {
    if (props.username) {
      let filtered = events.filter(ev => ev.username === props.username);
      if (filtered.length > 0) {
        return filtered.map(evn => {
          return (
            <Feed.Event key={evn.id}>
              <Feed.Label className="feed-label">
                {renderLabel(evn.type, evn.eff_team, evn.game)}
              </Feed.Label>
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>{evn.username}</Feed.User> {renderDesc(evn)}
                  <Feed.Date>{moment(evn.updated_at).fromNow()}</Feed.Date>
                </Feed.Summary>
              </Feed.Content>
            </Feed.Event>
          )
        })
      } else {
        return (
          <Feed.Event>
            <Feed.Content>
              <Feed.Summary>
                <span>You have no user activity.</span>
                <Feed.Date>{moment().fromNow()}</Feed.Date>
              </Feed.Summary>
            </Feed.Content>
          </Feed.Event>
        )
      }
    } if (props.commish) {
      if (events) {
        return events.map(ev => {
          return (
            <Feed.Event key={ev.id}>
              <Feed.Label className="feed-label">
                {renderLabel(ev.type, ev.eff_team, ev.game)}
              </Feed.Label>
              <Feed.Content>
                <Feed.Summary>
                  {ev.summary}
                  <Feed.Date>{moment(ev.submitted_at).fromNow()}</Feed.Date>
                </Feed.Summary>
                {ev.link.length > 5 ?
                  <Feed.Summary className="commish-link-text">
                  <a href={ev.link} target='blank'> {ev.link} </a>
                  </Feed.Summary>
                : null}
                <Feed.Extra text className="commish-feed-text">
                  {ev.post}
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>
          )
        })
      } else {
        return null;
      }
    } else {
      return events.map(ev => {
        return (
          <Feed.Event key={ev.id}>
            <Feed.Label className="feed-label">
              {renderLabel(ev.type, ev.eff_team, ev.game)}
            </Feed.Label>
            <Feed.Content>
              <Feed.Summary>
                <Feed.User>{ev.username ? ev.username: null}</Feed.User>{renderDesc(ev)}
                <Feed.Date>{moment(ev.updated_at).fromNow()}</Feed.Date>
              </Feed.Summary>
            </Feed.Content>
          </Feed.Event>
        )
      })
    }
  }

  return (
    <Feed>
      {renderEvents(props.events)}
    </Feed>
  )
}

export default EventFeed;
