import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, NavLink, Switch, Link, Redirect} from "react-router-dom";
import { Loader, Icon, Tab, Segment, Header, Button } from 'semantic-ui-react';
import { fetchLeague, requestLeagueAccess, clearLeague } from '../../actions';
import ALAHeader from '../Header';
import AuthModal from '../AuthModal';
import LeagueBanner from './LeagueBanner';
import LeagueHome from './LeagueHome';
import LeagueMembers from './LeagueMembers';
import LeagueInvite from './LeagueInvite';
import CreatePool from './CreatePool';
import LeagueRankings from './LeagueRankings';
import LeagueSettings from './LeagueSettings';
import CommishTools from '../pool/CommishTools';
import lillard from '../../images/lillard_game_winner.jpg';
import '../styles/leagues.scss';

const lgTheme = {
  "--color-main": "#6638f0"
};

function LeagueMain ({ match, league, auth, fetchLeague, requestLeagueAccess, clearLeague, invite, history, location, allLeagues }) {
  let params = match.params;
  let [accessReq, setAccessReq] = useState(false);

  function requestAccess() {
    requestLeagueAccess({
      league_id: league.info.id,
      league_abb: league.info.abb,
      league_name: league.info.name,
      joinable: league.info.joinable,
      user_id: auth[0].id,
      username: auth[0].username,
      email: auth[0].email,
      full_name: `${auth[0].nameFirst} ${auth[0].nameLast}`,
      source: 'blocked'
    });
    setAccessReq(true);
  }

  useEffect(() => {
    clearLeague();
    if (params.code) {
      fetchLeague('code', params.code);
    } else {
      if (params.abb !== 'invite') {
        fetchLeague('abb', params.abb);
      }
    }
    Object.keys(lgTheme).map(key => {
      const value = lgTheme[key];
      document.documentElement.style.setProperty(key, value);
    });
    return () => clearLeague();
  }, [params.abb, auth])

  if (!league || auth == null || allLeagues == null) {
    return <Loader className="loader-down" active inline='centered' size='massive'> LOADING LEAGUE </Loader>;
  } else {

      const memberIds = league.members.map(member => member.user_id);
      const memberRequests = league.requests.map(member => member.user_id);

      if (invite && (auth[0] && memberIds.includes(auth[0].id))) {
        return <Redirect to={{
          pathname: `/league/${league.info.abb}`,
          state: {index: 0}
        }} />
      } else {
        const panes = [
          {
            menuItem: {
              as: NavLink,
              id: "tab1",
              content: "League Home",
              to: `/league/${league.info.abb}`,
              exact: true,
              key: "home"
            },
            pane: (
              <Route
                key="league:home"
                exact
                path="/league/:abb"
                render={() => (
                  <Tab.Pane>
                    <LeagueHome />
                  </Tab.Pane>
                )}
              />
            )
          },
          {
            menuItem: {
              as: NavLink,
              id: "tab5",
              content: "Create Pool",
              to: `/league/${league.info.abb}/createpool`,
              exact: true,
              key: "poolsetup"
            },
            pane: (
              <Route
                path="/league/:abb/createpool"
                key="league:pools"
                render={() => (
                  <Tab.Pane>
                    <CreatePool />
                  </Tab.Pane>
                )}
              />
            )
          },
          {
            menuItem: {
              as: NavLink,
              id: "tab2",
              content: "Members",
              to: `/league/${league.info.abb}/members`,
              exact: true,
              key: "members"
            },
            pane: (
              <Route
                path="/league/:abb/members"
                key="league:members"
                render={() => (
                  <Tab.Pane>
                    <LeagueMembers />
                  </Tab.Pane>
                )}
              />
            )
          },
          {
            menuItem: {
              as: NavLink,
              id: "tab3",
              content: "Rankings",
              to: `/league/${league.info.abb}/rankings`,
              exact: true,
              key: "rankings"
            },
            pane: (
              <Route
                path="/league/:abb/rankings"
                key="league:rankings"
                render={() => (
                  <Tab.Pane>
                    <LeagueRankings />
                  </Tab.Pane>
                )}
              />
            )
          }
        ];

        if (auth && memberIds.includes(auth[0].id)) {
          panes.push(
            {
              menuItem: {
                as: NavLink,
                id: "tab4",
                content: "Settings",
                to: `/league/${league.info.abb}/settings`,
                exact: true,
                key: "settings"
              },
              pane: (
                <Route
                  path="/league/:abb/settings"
                  key="league:settings"
                  render={() => (
                    <Tab.Pane>
                      <LeagueSettings />
                    </Tab.Pane>
                  )}
                />
              )
            }
          )
        }

        if (auth && JSON.parse(league.info.admins).includes(auth[0].username)) {
          panes.push(
            {
              menuItem: {
                as: NavLink,
                id: "tab4",
                content: "Commish Tools",
                to: `/league/${league.info.abb}/commishtools`,
                exact: true,
                key: "commishtools"
              },
              pane: (
                <Route
                  path="/league/:abb/commishtools"
                  key="league:commishtools"
                  render={() => (
                    <Tab.Pane>
                      <CommishTools umbrella={'league'}/>
                    </Tab.Pane>
                  )}
                />
              )
            }
          )
        }

        return (
          <div>
            <ALAHeader />
            <div className="master-container">
              <img src={lillard} className="home-bg" alt='dame'></img>
              <LeagueBanner name={league.info.name.toUpperCase()} />
              {invite ?
                <LeagueInvite code={params.code}/>
              :
              league.info.hidden && (!auth || !memberIds.includes(auth[0].id)) ?
                <Segment placeholder>
                  <Header icon>
                    <Icon name='ban' color='red'/>
                    This is a private league that you are not a member of.
                  </Header>
                  <Segment.Inline style={{textAlign: 'center'}}>
                    <div style={{fontSize: 24}}> This league is run by <i>{league.info.name}</i> Commissioner: <b>{league.info.founder}</b> </div>
                    <div style={{marginTop: 20}}>
                      {!accessReq ?
                        auth[0] ?
                          !memberRequests.includes(auth[0].id) ?
                            <Button size={'large'} primary onClick={requestAccess}>Request Access</Button> :
                            <div>
                             You have already requested access to this league. Check your <Link to='/profile'>User Profile page</Link> to view the status of your request.
                            </div>
                           :
                           <><AuthModal /> to request access.</> :
                         <div>
                          Your request has been sent to this league's Commissioner.
                         </div>
                      }
                    </div>
                  </Segment.Inline>
                </Segment>
              :
                <Switch>
                  <Tab renderActiveOnly={false} activeIndex={-1} panes={panes} className="league-menu" />
                </Switch>
              }
            </div>
          </div>
        )
      }
  }
}

function mapStateToProps ({ auth, league, allLeagues}) {
  return ({ auth, league, allLeagues })
}

export default connect (mapStateToProps, {fetchLeague, requestLeagueAccess, clearLeague}) (LeagueMain);
