import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { pullLeagueTransactions } from '../../actions';
import { Grid, Header, Form, Button, Message, Table } from 'semantic-ui-react';
import { FORM_ERRORS, FORM_INPUTS, PAY_TYPES } from './Finances.constants';
import { toastSuccess, toastError } from '../Toast';
import { formatDollars } from '../../utils';

function Finances ({ activePool, gameEntrants, leagueTransactions, pullLeagueTransactions }) {
  const [payUser, setPayUser] = useState(null);
  const [payAmount, setPayAmount] = useState('');
  const [payType, setPayType] = useState(null);
  const [payNotes, setPayNotes] = useState('');
  const [formError, setFormError] = useState(false);
  const [alphEntrants, setAlphEntrants] = useState(
    [...gameEntrants]
    .sort((a, b) => a.username.localeCompare(b.username, undefined, {sensitivity: 'base'}))
    .map(e => {
      return {
        key: e.user_entry_concat,
        value: e.user_entry_concat,
        text: e.user_entry_concat,
        user_season_id: e.id
      }
    })
  )

  const addUserPayment = async () => {
    if (!payUser.entry) {
      setFormError(FORM_ERRORS.NO_USERNAME);
      setTimeout(() => setFormError(false), 3000);
    } else if (!payAmount) {
      setFormError(FORM_ERRORS.NO_AMOUNT);
      setTimeout(() => setFormError(false), 3000);
    } else if (!payType) {
      setFormError(FORM_ERRORS.NO_TRANSACTION_TYPE);
      setTimeout(() => setFormError(false), 3000);
    } else {
      const addPaymentData = {
        user_season_id: payUser.userSeasonId,
        notes: payNotes,
        amount: payAmount,
        type: payType,
      }

      try {
        await axios.post('/admin/addPayment', addPaymentData);
        pullLeagueTransactions(activePool.league_abb);
        toastSuccess('Finances updated', `You have successfully updated finances for ${payUser.entry}`);
        setPayAmount('');
        setPayNotes('');
        setPayType(null);  
      } catch (e) {
        toastError('Error updating finances', `An error was encountered updating this user's finances: ${e}. Please try again or contact ALA support for help with this error.`);
      } 
    }
  }

  const showUserPayments = payUser => {
    return leagueTransactions
      .filter(t => t.user_season_id === payUser.userSeasonId)
      .map(t => {
        return <Table.Row key={t.id}>
          <Table.Cell>{moment(t.updated_at).format("M/D/YY")}</Table.Cell>
          <Table.Cell>{formatDollars(t.amount, 2)}</Table.Cell>
          <Table.Cell>{t.notes}</Table.Cell>
        </Table.Row>
      }) 
  }

  if (leagueTransactions !== null) {
    return (
      <Grid className='commish-tools-cont'>
        <Grid.Row column={2}>
          <Grid.Column>
            <div className="user-pg-header">Finances</div>
            <Header size='medium'>Use this page to record payments from, or prizes paid to, your game entrants. <br />
            </Header>
            <Form error={formError} onSubmit={() => addUserPayment()}>
              <Message
                error
                content={formError}
              />
              <Grid relaxed>
                <Grid.Column width={6}>
                  <Grid.Row style={{paddingBottom: 10}}>
                    <Form.Select search label='user' options={alphEntrants} placeholder='user' value={payUser?.entry} onChange={(e, { value }) => {
                      const userSeasonId = parseInt(e.target.getAttribute('user_season_id'));
                      setPayUser({ entry: value, userSeasonId});
                    }}/>
                  </Grid.Row>
                  <Grid.Row style={{paddingBottom: 10}}>
                    <Form.Select 
                      label={FORM_INPUTS.LABELS.PAY_TYPE} 
                      options={PAY_TYPES} 
                      placeholder={FORM_INPUTS.PLACEHOLDERS.PAY_TYPE} 
                      value={payType} 
                      onChange={(e, {value}) => setPayType(value)}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Button primary> Submit </Button>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Grid.Row style={{paddingBottom: 10}}>
                    <Form.Input label='amount' placeholder='0.00' type='number' step='0.01' value={payAmount} onChange={e => setPayAmount(e.target.value)}/>
                  </Grid.Row>
                  <Grid.Row>
                    <Form.Input label={FORM_INPUTS.LABELS.NOTES} placeholder={FORM_INPUTS.PLACEHOLDERS.NOTES} onChange={e => setPayNotes(e.target.value)}/>
                  </Grid.Row>              
                </Grid.Column>
                <Grid.Column width={6}>
                  {payUser ?
                        <>
                        <div><b>{payUser.entry}</b>'s {`${activePool.game} ${activePool.season} payments`}</div> 
                        <Table>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Date Entered</Table.HeaderCell>
                              <Table.HeaderCell>Amount</Table.HeaderCell>
                              <Table.HeaderCell>Memo</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {showUserPayments(payUser)}
                          </Table.Body>
                        </Table>
                        </>
                      : null}
                </Grid.Column>
              </Grid>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  } else {
    return <div> Loading ... </div>
  } 
}

function mapStateToProps ({ leagueTransactions }) {
  return ({ leagueTransactions })
}

export default connect(mapStateToProps, {pullLeagueTransactions})(Finances);
