import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { filterFeed, fetchCommishPosts, fetchPoolHealth, setJoinModalVis, fetchFeed, toggleAutoRebuys, toggleBonusStatus, buyInsurance } from '../../../actions';
import logos from '../../../modules/logos';
import { Grid, Card, Loader, Item, Icon, Dropdown, Header, Button, Transition, Modal} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import RenderLives from '../../table_helpers/RenderLives';
import EventFeed from '../../Feed';
import RebuyModal from '../RebuyModal';
import PoolHealthReport from '../PoolHealthReport';
import LogoSlideshow from '../../other_helpers/LogoSlideshow';
import ContextPopup from '../../other_helpers/ContextPopup';
import logoBlack from '../../../images/logo_black.png';
import { PoolDetails } from './PoolDetails';
import { formatDollars, getActiveUserSeason } from '../../../utils';
import { BONUS_POOL_OPEN, GAME_ICONS, INSURANCE_OPTION_TEXT } from './constants';
import { GAME, INSURANCE_USER_CONTEXT_BODY } from '../../../constants';
import '../../styles/game-home.scss';

const Home = ({ 
  activeEntries,
  activeGameWeek,
  activePool,
  auth,
  buyInsurance,
  commishPosts,
  currentSeasons,
  feedEvents,
  fetchCommishPosts,
  fetchPoolHealth,
  filterFeed,
  filteredEvents,
  gameBonusTotals,
  gameEntrants,
  joinModalVis,
  league,
  liveWeek,
  poolHealth,
  prizes,
  seasonLength,
  seasonParams,
  seasonParamsIndex,
  setJoinModalVis,
  teamDetails,
  teamResults,
  toggleAutoRebuys,
  toggleBonusStatus,
  weeklyTotals
}) => {
  const [picksPage, setPicksPage] = useState(liveWeek && liveWeek > 0 ? Math.ceil(liveWeek / 5) : 1);
  const [poolPage, setPoolPage] = useState(liveWeek && liveWeek > 0 ? liveWeek : 1);
  const [poolPageAdj, setPoolPageAdj] = useState(false);
  const [picksPageAdj, setPicksPageAdj] = useState(false);
  const [render, setRender] = useState(false);
  const [filter, setFilter] = useState('Pool News');
  const [poolPageTouched, setPoolPageTouched] = useState(false);
  const [picksPageTouched, setPicksPageTouched] = useState(false);
  const [rebuyModalVis, setRebuyModalVis] = useState(false);
  const [rebuyBool, setRebuyBool] = useState(null);
  const [bonusModalVis, setBonusModalVis] = useState(false);
  const [bonusBool, setBonusBool] = useState(null);
  const [insuranceModalVis, setInsuranceModalVis] = useState(false);
  const [userInLg, setUserInLg] = useState(auth[0] ? league.members.map(e => e.username).includes(auth[0].username) : false);
  const [userSeason, setUserSeason] = useState(getActiveUserSeason(auth, activePool, activeEntries));
  const [bonusEntry, setBonusEntry] = useState(null);
  const [bonusNextWeek, setBonusNextWeek] = useState(null);
  const [bonusEntryToggle, setBonusEntryToggle] = useState(null);

  useEffect(() => {
    const dataFetched = teamResults ? true : false;

    filterFeed(feedEvents, 0, null, activePool.league_id, activePool.id);
    fetchCommishPosts(activePool.id);
    fetchPoolHealth(activePool.id);
    setRender(dataFetched);

    const poolEntrants = gameEntrants.map(e => e.username);
    if (auth[0]) {
      if (poolEntrants.includes(auth[0].username)) {
        setUserSeason(getActiveUserSeason(auth, activePool, activeEntries));
      } else {
        setUserSeason(false);
        setBonusEntryToggle(false);
      }
    } else {
      setUserSeason(false);
      setBonusEntryToggle(false);
    }

    if (activePool.game === GAME.NBA && activePool.season === 2019) {
      setPoolPage(21);
      setPicksPage(Math.ceil(21 / 5));
    }

    updateBonusConditions();
  }, [teamResults, feedEvents, activePool, activeEntries, gameEntrants, auth, fetchCommishPosts, fetchPoolHealth, filterFeed, userSeason]);

  useEffect(() => {
    if ((liveWeek !== poolPage) && !poolPageAdj && liveWeek !== 0) {
      setPoolPage(liveWeek);
    }
    if ((Math.ceil(liveWeek / 5) !== picksPage) && !picksPageAdj && liveWeek !== 0) {
      setPicksPage(Math.ceil(liveWeek / 5));
    }

    const leagueMembers = league.members.map(e => e.username);
    filterFeed(feedEvents, 0, null, activePool.league_id, activePool.id);

    if ((auth[0] && leagueMembers.includes(auth[0].username)) && !userInLg) {
      setUserInLg(true);
    }
  }, [liveWeek, gameEntrants, auth, league, activePool, filterFeed, feedEvents, poolPage, poolPageAdj, picksPage, picksPageAdj, userSeason, userInLg]);

  const updateBonusConditions = () => {
    if (activePool.bonus_active === false || activePool.bonus_disabled === true) {
      setBonusEntryToggle(false);
      setBonusNextWeek(false);
      setBonusEntry(false);
    } else {
      if (activePool.bonus_cadence === 1) {
        // Entrants can opt into/out-of bonus pool at any time
        setBonusEntry(BONUS_POOL_OPEN.ALWAYS);
        setBonusNextWeek(false);
        if (userSeason) {
          setBonusEntryToggle(true);
        }
      } else if (activePool.bonus_cadence === 2) {
        // Entrants can opt into/out-of bonus pool once per month
        setBonusEntry(BONUS_POOL_OPEN.MONTHLY);;
        const entryWks = JSON.parse(activePool.bonus_entry_weeks);
        if (!activePool.final && liveWeek < entryWks[entryWks.length - 1]) {
          const nextWk = entryWks.find(w => w > liveWeek);
          setBonusNextWeek(nextWk);
          if (liveWeek === nextWk - 1 && userSeason) {
            setBonusEntryToggle(true);
          } else {
            setBonusEntryToggle(false);
          }
        } else {
          setBonusEntryToggle(false);
          setBonusNextWeek(false);
        }
      } else {
        // Entrants can opt into/out-of bonus pool only at beginning of season
        setBonusEntry(BONUS_POOL_OPEN.BEG_OF_SEASON);
        setBonusNextWeek(false);
        if (liveWeek < 1 && userSeason) {
          setBonusEntryToggle(true);
        } else {
          setBonusEntryToggle(false);
        }
      }
    }
  };

  const filterGameFeed = (e, { value, text }) => {
    const username = auth ? auth[0].username : null;
    setFilter(text);
    filterFeed(feedEvents, value, username, activePool.league_id, activePool.league_season);
  };

  const turnOnRebuys = () => {
    if (userSeason.auto_rebuy !== true) {
      setRebuyBool(true);
      setRebuyModalVis(true);
    }
  };
  
  const turnOffRebuys = () => {
    if (userSeason.auto_rebuy === true) {
      setRebuyBool(false);
      setRebuyModalVis(true);
    }
  };

  const enterBonusPool = () => {
    if (userSeason.bonus !== true) {
      setBonusBool(true);
      setBonusModalVis(true);
    }
  };

  const leaveBonusPool = () => {
    if (userSeason.bonus === true) {
      setBonusBool(false);
      setBonusModalVis(true);
    }
  };

  const handleToggleAutoRebuys = () => {
    const data = {
      bool: rebuyBool,
      userSeason: userSeason,
      effWeek: liveWeek + 1
    };
    toggleAutoRebuys(data);
    setRebuyModalVis(false);
  };

  const handleToggleBonusStatus = () => {
    let data = {
      bool: bonusBool,
      userSeason: userSeason,
      effWeek: liveWeek + 1
    };
    toggleBonusStatus(data);
    setBonusModalVis(false);
  };

  const purchInsurance = () => {
    buyInsurance({ userSeason, cost: activePool.insurance, eff_week: liveWeek + 1 });
    setInsuranceModalVis(false);
    fetchFeed(activePool.league_id, activePool.id);
  };
  
  const renderBonusLeaders = () => {
    const bonusEntrants = [...gameEntrants].filter(entrant => gameBonusTotals[`${entrant.username}_${entrant.id}`] !== 0 || entrant.bonus);
    if (bonusEntrants.length) {
      return bonusEntrants
        .sort((a, b) => gameBonusTotals[`${b.username}_${b.id}`] - gameBonusTotals[`${a.username}_${a.id}`])
        .map((entrant, idx) => {
          return (
            <Grid.Row className="pick-item" key={idx}>
              <Grid.Column width={11} className="bonus-user"> {`${idx + 1}. ${entrant.username}${entrant.entry_num > 1 ? ` [${entrant.entry_num}]` : ''}`} </Grid.Column>
              <Grid.Column width={5} className="bonus-amt"> {formatDollars(gameBonusTotals[`${entrant.username}_${entrant.id}`], 2, true)} </Grid.Column>
            </Grid.Row>
          );
        });
    }
  };

  const handlePicksPage = (val) => {
    const maxLength = Math.ceil(seasonLength / 5);
    setPicksPageTouched(true);
  
    if ((val === 1 && picksPage < maxLength) || (val === -1 && picksPage > 1)) {
      setPicksPageAdj(true);
      setPicksPage(picksPage + val);
    }
  };
  
  const handlePoolPage = (val) => {
    setPoolPageTouched(true);
  
    if ((val === 1 && poolPage < seasonLength) || (val === -1 && poolPage > 1)) {
      setPoolPageAdj(true);
      setPoolPage(poolPage + val);
    }
  };
  
  const renderSymbol = (display, w, l) => {
    if (display < activeGameWeek || seasonParams.final) {
      if (w >= l && !(w === 0 && l === 0)) {
        return <Icon name='thumbs up' className="thumb-up" />;
      } else {
        return <Item.Image className="death-img" src={`/images/skull_bones.svg`} />;
      }
    } else {
      if (w >= l) {
        return <Icon name='smile outline' className="thumb-up" />;
      } else {
        return <Icon name='exclamation' />;
      }
    }
  };

  const randomSelections = () => {
    const weeks = [1, 2, 3, 4, 5];
    return weeks.map((week, idx) => {
      const displayWeek = idx + (5 * (picksPage - 1)) + 1;
      return (
        displayWeek <= seasonLength || displayWeek < 6 ?
          <Grid.Row key={idx} columns={4} className="pick-item">
            <Grid.Column className="pick-week" textAlign="center" width={4}>
              {picksPage > 1 ? `Week ${displayWeek}` : `Week ${idx + 1}`}
            </Grid.Column>
            <Grid.Column className={`pick-week logo-cont ${activePool.game}_cell`}>
              <LogoSlideshow game={activePool.game} />
              <span>?</span>
            </Grid.Column>
            <Grid.Column width={2}>
            </Grid.Column>
          </Grid.Row>
          :
          null
      );
    });
  };
  
  const userSelections = () => {
    const max = picksPage * 5;
    return userSeason.selections[0].slice((max - 5), max).map((selection, idx) => {
      const displayWeek = idx + (5 * (picksPage - 1)) + 1;
      let weekW = 0;
      let weekL = 0;
      if (selection !== 'xxx') {
        weekW = selection.length > 1 ? teamResults[selection][displayWeek - 1][0] : null;
        weekL = selection.length > 1 ? teamResults[selection][displayWeek - 1][1] : null;
      } else {
        weekL = 1;
      }
      return (
        <Grid.Row key={idx} columns={4} className="pick-item">
          <Grid.Column className="pick-week" textAlign="center" width={4}>
            {picksPage > 1 ? `Week ${displayWeek}` : `Week ${idx + 1}`}
          </Grid.Column>
          <Grid.Column className="pick-week">
            {selection.length > 1 ?
              <Item.Image className={`pick-img ${activePool.game}_cell_home`} src={logos[`${activePool.game}_${selection}`]} />
              :
              <Icon className="no-pick-icon" name="question" />
            }
          </Grid.Column>
          <Grid.Column className="pick-week">
            {selection.length > 1 && selection !== 'xxx' ?
              (liveWeek >= displayWeek ?
                `${weekW} - ${weekL}`
                : null)
              :
              null
            }
          </Grid.Column>
          <Grid.Column className="pick-week">
            {selection.length > 1 ?
              (liveWeek >= displayWeek ?
                renderSymbol(displayWeek, weekW, weekL)
                : null)
              :
              null}
          </Grid.Column>
        </Grid.Row>
      );
    });
  };

  const poolSelections = (week) => {
    if (weeklyTotals[week] && Object.keys(weeklyTotals[week].picks).length > 0) {
      return Object.keys(weeklyTotals[week].picks).map(team => {
        if (team !== 'xxx') {
          const weekW = teamResults[team][poolPage - 1][0];
          const weekL = teamResults[team][poolPage - 1][1];
          return (
            <Grid.Row key={team} columns={4} className="pick-item">
              <Grid.Column className="pick-week" width={4}>
                <Item.Image className={`pick-img ${activePool.game}_cell_home`} src={logos[`${activePool.game}_${team}`]} />
              </Grid.Column>
              <Grid.Column>
                <span className="pick-week">
                  {Math.round((weeklyTotals[week].picks[team] / weeklyTotals[week].total) * 100)}%
                </span>
              </Grid.Column>
              <Grid.Column className="pick-week">
                {weekW} - {weekL}
              </Grid.Column>
              <Grid.Column className="pick-week">
                {renderSymbol(poolPage, weekW, weekL)}
              </Grid.Column>
            </Grid.Row>
          );
        }
        return null;
      });
    } else {
      return (
        <div className="no-pool-picks">
          - NO PICKS THIS WEEK -
        </div>
      );
    }
  };
  
  const displayUserPicks = () => {
    const cardColor = activePool.game === GAME.NBA ? 'orange' : 'blue';
    const livesRemaining = userSeason ? userSeason.lives : 3;
  
    return (
      <Card color={cardColor} className="user-sel-card">
        <Card.Content>
          <Card.Header className="card-header">
            <Icon name="clipboard outline" />
            Your Selections
            <div className="page-movers">
              <Icon name="angle left" onClick={() => handlePicksPage(-1)} />
              <Icon name="angle right" onClick={() => handlePicksPage(1)} />
            </div>
          </Card.Header>
          <Card.Meta className="card-meta">Lives Remaining:
            <RenderLives lives={livesRemaining} className="rendered-lives" />
          </Card.Meta>
          <Grid className="home-user-picks">
            <Grid.Row columns={4} className="label-row">
              <Grid.Column></Grid.Column>
              <Grid.Column>PICK</Grid.Column>
              <Grid.Column>RECORD</Grid.Column>
              <Grid.Column>RESULT</Grid.Column>
            </Grid.Row>
            {userSeason ? userSelections() : randomSelections()}
          </Grid>
        </Card.Content>
      </Card>
    );
  };

  const displayCommishDesk = () => {
    const { game, league_abb, season, season_type, pool_idx } = activePool;
    return (
      <Card color={activePool.game === GAME.NBA ? 'orange' : 'blue'} className="commish-feed-card">
        <Card.Content>
          <Card.Header className="card-header">
            <Icon name="inbox" />
            The Commish's Desk
          </Card.Header>
          <Card.Meta className="card-meta">
            News, Notes, and Notifications
          </Card.Meta>
          {commishPosts && commishPosts.length ?
            <EventFeed
              events={commishPosts}
              commish={true}
            />
            :
            <div style={{ marginTop: 15 }}> Welcome to {league.info.name}'s {activePool.game_name} Pool! You'll see posts and news items from the league Commissioner here. Commissioners can add content here through the {auth[0] && JSON.parse(league.info.admins).includes(auth[0].username) ? <Link to={`/${game}/${league_abb}/${season}/${season_type}/${pool_idx}/commishtools`}>Commish Tools</Link> : <b>Commish Tools</b>}.</div>
          }
        </Card.Content>
      </Card>
    );
  };
  
  const displayBonusLeaders = () => {
    const { game, league_abb, season, season_type, pool_idx } = activePool;
    return (
      <Card color={activePool.game === 'nba' ? 'orange' : 'blue'} className="bonus-leaders-card">
        <Card.Content>
          <Card.Header className="card-header">
            <Icon name='rocket' /> Bonus Pool Leaderboard
          </Card.Header>
          <Card.Meta className="card-meta"> Head to the <b><Link to={`/${game}/${league_abb}/${season}/${season_type}/${pool_idx}/bonuses`}>Bonuses</Link></b> tab for more details </Card.Meta>
          <Grid className="bonus-leaderboard">
            <Grid.Row className="label-row">
              <Grid.Column width={11}>ENTRANT</Grid.Column>
              <Grid.Column width={5}>EARNINGS</Grid.Column>
            </Grid.Row>
            {renderBonusLeaders()}
          </Grid>
        </Card.Content>
      </Card>
    );
  };

  if (
    weeklyTotals !== null
    && feedEvents !== null
    && filteredEvents !== null
    && teamDetails !== null
    && render
    && liveWeek !== null
    && teamResults !== null
    && ((poolPage === liveWeek || poolPage === 1) || poolPageTouched)
    && gameEntrants !== null
    && gameBonusTotals !== null
    && activePool !== null
    && poolHealth !== null
    && seasonParams !== null
    && league !== null
    && userSeason !== null
    && bonusEntry !== null
    && bonusNextWeek !== null
  ) {
  const alive = gameEntrants.filter(e => !e.extinct).length;
  const entrants = gameEntrants.filter(e => e.status !==0).length;
  const currentSeasonShort = seasonParamsIndex.filter(sp => sp.game === activePool.game)[0].season;
  const currentSeasonFull = seasonParamsIndex.filter(sp => sp.game === activePool.game)[0].season_full;
    return (
      <div>
        <Grid style={{marginBottom: 20, marginTop: 5}}>
          <Grid.Row className={`page-header ${activePool.game}`}>
            <Grid.Column width={16}>
              <Header as='h2'>
                <div style={{display: 'inline-block'}}>
                  {league.info.abb === 'alevelabove' ?
                      <img src={logoBlack} style={{width: '85px'}}/>

                  :
                    <Icon name={activePool.game === 'nba' ? 'basketball ball' : 'baseball ball'} circular size='large' inverted  />
                  }
                </div>
                <Header.Content style={{width: '92%'}}>
                  {league.info.abb === 'alevelabove' ?
                    <>Welcome to A Level Above's global {activePool.game.toUpperCase()} Survivor homepage. </>
                  :
                    <>Welcome to <Link to={`/league/${league.info.abb}`}>{league.info.name}</Link>{league.info.name.slice(-1) === 's' ? "'" : "'s"} {activePool.game.toUpperCase()} Survivor homepage.</>
                  }
                  <Header.Subheader>
                    {!league.info.joinable ? 
                      <div className='detail-subtext'>
                        - This pool is <b>private</b>. Entrants can join by requesting to join <Link to={`/league/${league.info.abb}`}>{league.info.name}</Link>. Once approved, they will then be able to join the pool.
                      </div> 
                      : 
                      <div className='detail-subtext'>
                        - This pool is <b>public</b>. Anyone can join. Any entrant who signs up will become a member of <Link to={`/league/${league.info.abb}`}>{league.info.name}</Link> if not already on that league's roster.
                      </div>
                    }
                    {league.info.abb !== 'alevelabove' ?
                      <> - Want to join ALA's global pool? Head to the <a href={`/${activePool.game}/alevelabove/${activePool.season}/${activePool.season_type}/${activePool.pool_idx}`}>ALA global {activePool.game.toUpperCase()} Survivor homepage</a>. <br />
                      </>
                      :
                      <>
                        {activePool.season !== currentSeasons[activePool.game] ?
                          <>
                            - You are viewing the completed pool from <b>{activePool.season}</b>. Want to sign up for ALA's {currentSeasonFull} {activePool.game.toUpperCase()} Survivor Pool? Head to the <a href={`/${activePool.game}/alevelabove/${currentSeasonShort}/reg/0`}>{activePool.game.toUpperCase()} Survivor {currentSeasonShort} homepage.</a> <br/>
                          </>
                          :
                          <>
                            - This is the pool page for the <b>{currentSeasonFull}</b> {activePool.game.toUpperCase()} season. Want to see last year's results? Head to <a href={`/${activePool.game}/alevelabove/${activePool.season-1}/reg/0`}>{activePool.game.toUpperCase()} Survivor {activePool.season-1}.</a> <br/>
                          </>
                        }
                      </>
                    }
                    - Looking to create your own pool? <Link to='/leagues/create'>Create a league</Link> and have a new pool set up in seconds.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid className="game-home-grid">
          <Grid.Row>
            <Grid.Column style={{width: '33.33333%'}}>
              <Grid columns={1} className="card-grid">
                <Grid.Column>
                  <PoolDetails 
                    activePool={activePool}
                    auth={auth}
                    entrants={entrants}
                    joinModalVis={joinModalVis}
                    league={league}
                    prizes={prizes}
                    setJoinModalVis={setJoinModalVis}
                    userInLg={userInLg}
                    userSeason={userSeason}
                    gameEntrants={gameEntrants}
                  />
                </Grid.Column>
              </Grid>
              <Card color={activePool.game === 'nba' ? 'orange' : 'blue'} className="event-feed-card">
                <Card.Content>
                  <Card.Header className="card-header">
                    <Icon name="newspaper outline" />
                    News Feed
                      <Dropdown
                        text='Filter'
                        icon={GAME_ICONS[activePool.game]}
                        floating
                        labeled
                        button
                        className='icon feed-filter'
                      >
                        <Dropdown.Menu>
                          <Dropdown.Header icon='tags' content='Filter by News Type' />
                          <Dropdown.Divider />
                          <Dropdown.Item icon='newspaper outline' text='Pool News' value={0} onClick={filterGameFeed}/>
                          <Dropdown.Item icon='wizard' text='Your Activity' value={1} onClick={filterGameFeed}/>
                          <Dropdown.Item icon='rocket' text='Performance Bonuses' value={2} onClick={filterGameFeed}/>
                        </Dropdown.Menu>
                      </Dropdown>
                  </Card.Header>
                  <Card.Meta className="card-meta">
                    {filter}
                  </Card.Meta>
                  <EventFeed
                    events={filteredEvents}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column style={{width: '66.66666%'}}>
              { poolHealth.length > 0 && liveWeek > 2 ?
                <Card color={activePool.game === 'nba' ? 'orange' : 'blue'} className="commish-feed-cardS">
                  <Card.Content>
                    <Card.Header className="card-header">
                      <Icon name="area chart" />
                      <div style={{display: 'inline'}}>Pool Health Report </div>
                      <div style={{display: 'inline', float: 'right'}}>
                        <span style={{color: '#D0D0D0'}}>{entrants} ENTERED | </span>
                        {alive} {alive === 1 ? 'REMAINS' : 'REMAIN'}
                      </div>
                    </Card.Header>
                    <div style={{height: '200px'}}>
                      <PoolHealthReport
                        weekData={poolHealth}
                      />
                    </div>
                  </Card.Content>
                </Card>
                : null
              }
              <Grid columns={2} className="card-grid">
                <Grid.Column>
                  <Card color={activePool.game === 'nba' ? 'orange' : 'blue'} className="card-header-alt">
                    <Card.Content>
                      <Card.Header className="card-header manage-entry">
                        <Grid columns={2}>
                          <Grid.Column width={10}>
                            <Icon name="cogs" style={{marginRight: 5}}/>
                            Manage Your Entry
                          </Grid.Column>
                          <Grid.Column className='detail-button-col' width={6}>
                            {!userInLg || !userSeason ? <div className='detail-info-msg'> <i>Join this pool to use these features.</i> </div> : null}
                          </Grid.Column>
                        </Grid>
                      </Card.Header>
                      <Grid className="control-grid">
                        {userSeason && !userSeason.alive && !userSeason.extinct ?
                          <>
                            <Grid.Row>
                              <Grid.Column className='control-cat'>
                                <div style={{color: 'red'}}><Icon name='exclamation triangle' circular/>YOU LOST A LIFE!</div>
                              </Grid.Column>
                              <Grid.Column className='control-switch'>
                                <RebuyModal userSeason={userSeason}/>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className='control-detail'>
                              <Grid.Column style={{lineHeight: 1.3, color: 'red', fontWeight: 800}}>
                                You lost a life last week and will need to rebuy in order to remain in the game.
                              </Grid.Column>
                            </Grid.Row>
                          </>
                        : null}
                        <Grid.Row>
                          <Grid.Column className='control-cat'>
                            <div><Icon name='rocket' circular/>BONUS POOL</div>
                          </Grid.Column>
                          <Grid.Column className='control-switch'>
                            <Button.Group>
                              <Button
                                disabled={bonusEntryToggle === false}
                                className={userSeason?.bonus === true ? 'active-toggle-btn' : null}
                                primary={userSeason?.bonus === true}
                                onClick={() => enterBonusPool()}
                              >IN</Button>
                                <Button.Or text='<>'/>
                              <Button
                                disabled={bonusEntryToggle === false}
                                className={userSeason?.bonus !== true ? 'active-toggle-btn' : null}
                                negative={userSeason?.bonus !== true}
                                onClick={() => leaveBonusPool()}
                              >OUT</Button>
                            </Button.Group>
                          </Grid.Column>
                          <Transition visible={bonusModalVis} animation="fade up" duration={1000}>
                            <Modal size="small" centered open={bonusModalVis} onClose={() => setBonusModalVis(false)} className="game-reg-modal">
                            <Header as='h2'>
                              {bonusBool ? 'Join Bonus Pool?' : 'Leave Bonus Pool?'}
                            </Header>
                              <Modal.Content>
                                Do you want to {bonusBool ? 'join' : 'leave'} the Bonus Pool for this pool? This change will take effect for the next scoring period (<b>Week {liveWeek + 1}</b>). Please confirm below.
                              </Modal.Content>
                              <Modal.Actions>
                                <Button negative onClick={() => {
                                  setBonusModalVis(false);
                                  setBonusBool(null);
                                }}>
                                  Back
                                </Button>
                                <Button primary onClick={() => handleToggleBonusStatus()}>
                                  <Icon name='check circle' />
                                    {bonusBool ? 'Join Bonus Pool' : 'Leave Bonus Pool'}
                                </Button>
                              </Modal.Actions>
                            </Modal>
                          </Transition>
                        </Grid.Row>
                        <Grid.Row className='control-detail'>
                          <Grid.Column style={{lineHeight: 1.3}}>
                            {activePool.bonus_active ?
                              <>This league's <b>Bonus Pool cadence</b> allows for entry into or out of the Bonus Pool <b>{bonusEntry}</b>. {bonusNextWeek ? `Your next opportunity to ${userSeason && userSeason.bonus === true ? 'leave' : 'join'} the bonus pool will be in the week prior to Week ${bonusNextWeek}.`: null}
                              </>
                            :
                              <>This league does not have an active Bonus Pool.</>
                            }
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column className='control-cat'>
                            <div><Icon name='redo' circular/>
                              AUTO-REBUY
                              <ContextPopup
                                header="Why Auto-Rebuy?"
                                content="A Level Above's Survivor Pools allow each entrant to plan out and submit their picks for as many, and whichever, weeks of a season that they would like at any given time. However, if an entrant's pick loses, they still must come into the pool and declare their intention to rebuy to remain in the game -- unless they have Auto-Rebuys turned on. Turning on Auto-Rebuys will automatically keep an entrant active if a pick loses (provided that they still have lives remaining) and will eliminate the possibility of an entrant being eliminated solely because they forget to go into the pool and manually rebuy."
                                trigger={<Icon style={{fontSize: 14}} className="context-icon" name='question circle' />}
                              />
                            </div>
                          </Grid.Column>
                          <Grid.Column className='control-switch'>
                            <Button.Group>
                                <Button
                                  className={userSeason?.auto_rebuy === true ? 'active-toggle-btn' : null}
                                  primary={userSeason?.auto_rebuy === true}
                                  onClick={() => turnOnRebuys()}
                                  disabled={!userInLg || !userSeason}
                                >ON</Button>
                                <Button.Or text='<>'/>
                                <Button
                                  className={userSeason?.auto_rebuy !== true ? 'active-toggle-btn' : null}
                                  negative={userSeason?.auto_rebuy !== true}
                                  onClick={() => turnOffRebuys()}
                                  disabled={!userInLg || !userSeason}
                                >OFF</Button>
                            </Button.Group>
                          </Grid.Column>
                          <Transition visible={rebuyModalVis} animation="fade up" duration={1000}>
                            <Modal size="small" centered open={rebuyModalVis} onClose={() => setRebuyModalVis(false)} className="game-reg-modal">
                            <Header as='h2'>
                              {rebuyBool === true ? 'Turn on Auto-Rebuys?' : 'Turn off Auto-Rebuys?'}
                            </Header>
                              <Modal.Content>
                                Do you want to {rebuyBool === true ? 'turn on' : 'turn off'} auto-rebuys for this pool? This change will take effect for the next scoring period. <p><b>Auto-Rebuys automatically keep you active in the game in the event that your weekly pick loses, commiting you to a subsequent rebuy and guaranteeing that you are not eliminated from the pool until you run out of lives.</b></p> <p>Please confirm below.</p>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button negative onClick={() => {
                                  setRebuyModalVis(false);
                                  setRebuyBool(null);
                                }}>
                                  Back
                                </Button>
                                <Button primary onClick={() => handleToggleAutoRebuys()}>
                                  <Icon name='check circle' />
                                    {rebuyBool === true ? 'Turn On Auto-Rebuys' : 'Turn Off Auto-Rebuys'}
                                </Button>
                              </Modal.Actions>
                            </Modal>
                          </Transition>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column className='control-cat'>
                            <div><Icon name='shield alternate' circular/>
                              INSURANCE
                              <ContextPopup
                                header="What's Insurance?"
                                content={INSURANCE_USER_CONTEXT_BODY(activePool.insurance_option)}
                                trigger={<Icon style={{fontSize: 14}} className="context-icon" name='question circle' />}
                              />
                            </div>
                          </Grid.Column>
                          <Grid.Column className='control-switch'>
                            {activePool.insurance_option === 0 ?
                              <span style={{fontSize: 12, lineHeight: 1.1}}> This pool does not currently allow entrants to purchase Insurance. </span>
                            :
                              <>
                                {!userSeason?.insurance_purchased || !userInLg || !userSeason ?
                                  <Button
                                    className='buy-insurance'
                                    onClick={() => setInsuranceModalVis(true)}
                                    disabled={!userInLg || !userSeason}
                                    ><b>PURCHASE FOR {formatDollars(activePool.insurance, 0, true)}</b>
                                  </Button>
                                :
                                  <Button primary disabled> YOUR PICKS ARE INSURED </Button>
                                }
                              </>
                            }
                          </Grid.Column>
                          <Transition visible={insuranceModalVis} animation="fade up" duration={1000}>
                            <Modal size="small" centered open={insuranceModalVis} onClose={() => setInsuranceModalVis(false)} className="game-reg-modal">
                            <Header as='h2'>
                              Purchase Insurance?
                            </Header>
                              <Modal.Content>
                                Do you want to purchase Insurance for this pool for {formatDollars(activePool.insurance, 2, true)}? Insurance offers pick protection in the event that an entrant forgets to make a pick, and will automatically award an entrant {INSURANCE_OPTION_TEXT[activePool.insurance_option]} pick in their pool that week that an entrant still has available for selection.
                              </Modal.Content>
                              <Modal.Actions>
                                <Button negative onClick={() => setInsuranceModalVis(false)}>
                                  Back
                                </Button>
                                <Button primary onClick={() => purchInsurance()}>
                                  <Icon name='check circle' />
                                    Purchase Insurance
                                </Button>
                              </Modal.Actions>
                            </Modal>
                          </Transition>
                        </Grid.Row>
                      </Grid>
                    </Card.Content>
                  </Card>
                  {displayCommishDesk()}
                  {activePool.bonus_active ? displayBonusLeaders() : null}
                </Grid.Column>
                <Grid.Column>
                  <Card color={activePool.game === 'nba' ? 'orange' : 'blue'}>
                    <Card.Content>
                      <Card.Header className="card-header">
                      <Icon name="chart pie" />
                      Pool Selections
                      <div className="page-movers">
                        <Icon name="angle left" onClick={() => handlePoolPage(-1)}/>
                        <Icon name="angle right" onClick={() => handlePoolPage(1)}/>
                      </div>
                      </Card.Header>
                      <Card.Meta className="card-meta">Week {poolPage}</Card.Meta>
                      {
                        (liveWeek >= poolPage && liveWeek > 0) ?
                          <Grid className="home-user-picks">
                            <Grid.Row columns={4} className="label-row">
                              <Grid.Column></Grid.Column>
                              <Grid.Column>POOL %</Grid.Column>
                              <Grid.Column>RECORD</Grid.Column>
                              <Grid.Column>RESULT</Grid.Column>
                            </Grid.Row>
                          {poolSelections(poolPage-1)}
                        </Grid>
                        :
                        <div className="picks-hidden">
                          <Icon name='lock' size='massive' />
                          <div className="picks-hidden">Picks will become visible once selections lock for the week.</div>
                        </div>
                      }
                    </Card.Content>
                  </Card>
                  {displayUserPicks()}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  } else {
    return (
      <div className="master-container">
        <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>
      </div>
    )
  }
}

function mapStateToProps ({
  activeGameWeek,
  activeEntries,
  activePool,
  auth,
  calWeek,
  commishPosts,
  currentSeasons,
  feedEvents,
  filteredEvents,
  gameBonusTotals,
  gameEntrants,
  joinModalVis,
  league,
  liveWeek,
  poolHealth,
  prizes,
  seasonLength,
  seasonParams,
  seasonParamsIndex,
  teamDetails,
  teamResults,
  weeklyTotals
}) {
  return ({
    activeGameWeek,
    activeEntries,
    activePool,
    auth,
    calWeek,
    commishPosts,
    currentSeasons,
    feedEvents,
    filteredEvents,
    gameBonusTotals,
    gameEntrants,
    joinModalVis,
    league,
    liveWeek,
    poolHealth,
    prizes,
    seasonLength,
    seasonParams,
    seasonParamsIndex,
    teamDetails,
    teamResults,
    weeklyTotals
  })
}

export default connect(mapStateToProps, { filterFeed, fetchCommishPosts, fetchPoolHealth, setJoinModalVis, fetchFeed, toggleAutoRebuys, toggleBonusStatus, buyInsurance } )(Home);
