import React from 'react';
import { connect } from 'react-redux';
import { Table, Loader, Grid, Header, Icon } from 'semantic-ui-react';
import RenderLives from '../table_helpers/RenderLives';
import { formatDollars, getUserEntrySuffix } from '../../utils';
import "../styles/finances.scss";

const Finances = ({ gameEntrants, activePool, payments, gameBonusTotals }) => {
  const renderRows = () => {
    const champions = JSON.parse(activePool.champions);

    return [...gameEntrants.filter(e => e.status !== 0)]
      .sort((a, b) => a.username.localeCompare(b.username))
      .map(entrant => {
        const champ = champions ? champions.includes(entrant.username) : false;
        const concatenatedUser = `${entrant.username}_${entrant.id}`;
        entrant.buyin = activePool.buyin;
        entrant.rebuyD = activePool.rebuy_price * entrant.rebuys;
        entrant.paid = payments[entrant.user_entry_concat] ? payments[entrant.user_entry_concat]['payments'] : 0;
        entrant.prizesPaidTo = payments[entrant.user_entry_concat] ? payments[entrant.user_entry_concat]['prizesPaidTo'] : 0;
        entrant.profits = -entrant.buyin + -entrant.insurance + -entrant.rebuyD + gameBonusTotals[concatenatedUser] + entrant.prizes;
        entrant.total = -entrant.buyin + -entrant.insurance + -entrant.rebuyD + gameBonusTotals[concatenatedUser] + entrant.paid + entrant.prizes - entrant.prizesPaidTo;

        return (
          <Table.Row key={entrant.id}>
            <Table.Cell>{entrant.username} {entrant.entry_num > 1 ? `[${entrant.entry_num}]` : ''}</Table.Cell>
            <Table.Cell className={champ ? 'game-color' : null}>
              {champ ? <b>CHAMPION <Icon name='trophy' /></b> : entrant.extinct ? 'ELIMINATED' :
                <RenderLives lives={entrant.lives} />}
            </Table.Cell>
            <Table.Cell style={{ color: '#BF392F' }}>{formatDollars(-entrant.buyin, 2)}</Table.Cell>
            <Table.Cell style={entrant.rebuyD > 0 ? { color: '#BF392F' } : null}>{formatDollars(-entrant.rebuyD, 2)}</Table.Cell>
            <Table.Cell style={entrant.insurance > 0 ? { color: '#BF392F' } : null}>{formatDollars(-entrant.insurance, 2)}</Table.Cell>
            <Table.Cell style={gameBonusTotals[concatenatedUser] < 0 ? { color: '#BF392F' } : null}>{formatDollars(gameBonusTotals[concatenatedUser], 2)}</Table.Cell>
            <Table.Cell className="bord-r">{formatDollars(entrant.prizes, 2)}</Table.Cell>
            <Table.Cell>{formatDollars(entrant.paid, 2)}</Table.Cell>
            <Table.Cell className="bord-r">{formatDollars(entrant.prizesPaidTo, 2)}</Table.Cell>
            <Table.Cell style={entrant.total.toFixed(0) < 0 ? { color: '#BF392F' } : null}><b>{formatDollars(parseFloat(entrant.total.toFixed(2)), 2, true)}</b></Table.Cell>
          </Table.Row>
        );
      });
  };

  if (payments !== null && gameBonusTotals !== null && activePool !== null) {
    return (
      <div>
        <Grid style={{ marginBottom: 20, marginTop: 5, position: 'relative' }}>
          <Grid.Row className={`page-header ${activePool.game} finances`}>
            <Grid.Column width={16}>
              <Header as='h2'>
                <div style={{ display: 'inline-block' }}>
                  <Icon name='dollar sign' circular inverted size='large' />
                </div>
                <Header.Content>
                  Finances
                  <Header.Subheader>
                  - This page tracks rebuys, bonus pool balances, prizes, and anything else finance-related. <br />
                  - Your league's Commissioner can log payments made and prizes paid in the Commish Tools.
                </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Username</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Buy-in</Table.HeaderCell>
                    <Table.HeaderCell>Rebuys</Table.HeaderCell>
                    <Table.HeaderCell>Insurance</Table.HeaderCell>
                    <Table.HeaderCell>Bonus</Table.HeaderCell>
                    <Table.HeaderCell>Prizes</Table.HeaderCell>
                    <Table.HeaderCell>Paid</Table.HeaderCell>
                    <Table.HeaderCell>Prizes Paid To</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {renderRows()}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  } else {
    return <Loader className="loader-down" active inline='centered' size='massive'> LOADING FINANCES ... </Loader>;
  }
};

const mapStateToProps = (state) => ({
  gameEntrants: state.gameEntrants,
  activePool: state.activePool,
  payments: state.payments,
  gameBonusTotals: state.gameBonusTotals,
});

export default connect(mapStateToProps)(Finances);